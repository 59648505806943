import csvUtil from 'utils/csv.util';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';

export default function CsvFileExplorer(props) {
  const handleFileInputChange = async (event) => {
    const [file] = event.target.files;
    const buffer = Buffer.from(await file.arrayBuffer());
    const worksheet = await csvUtil.readFromBuffer(buffer);
    const csvFile = new File([buffer], file.name, {type: 'text/csv'});
    props.onChange({file: csvFile, worksheet});
    event.target.value = '';
  }

  return (
    <>
      <input
        className='p-d-none'
        id='explorer'
        type='file'
        accept='text/csv'
        onChange={handleFileInputChange}

      />
      <NeoButtonMain
        label='Agregar archivo .csv'
        icon="pi pi-plus"
        onClick={() => document.getElementById('explorer').click()}
      />
    </>
  );
}