import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import TEMPLATES from 'consts/templates.consts';
import useMessages from 'hooks/useMessages.hook';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import Icono from 'design/assets/img/cta/icons/icon-template.svg';
import TemplatesService from 'services/templates.service';

export default function TemplateDetailHeader(props) {
  const history = useHistory();
  const messages = useMessages();
  const lineId = props.lineId;
  const { name } = props.template;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const deleteTemplate = async () => {
    const response = await TemplatesService.deleteTemplate(lineId, name);
    return { success: response.success };
  }

  const handleDeleteDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        history.push(`${MENU.ADMIN.TEMPLATES.ROOT.PATH}?line=${lineId}`);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se eliminó la plantilla.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo eliminar la plantilla, inténtalo de nuevo.');
      }
    }
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          icon={Icono}
          title={`${props.template.name} (${props.template.language})`}
          subtitle='Detalle de plantilla'
        />
        <NeoButtonSection align='right'>
          {
            (props.template.status == 'REJECTED') &&
            <NeoButtonMain
              label='Crear copia'
              icon="pi pi-copy"
              onClick={() => (
                history.push({
                  pathname: MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH,
                  state: {
                    lineId,
                    generalData: { language: props.template.language, category: props.template.category },
                    ...props.template.structure
                  }
                })
              )}
            />
          }
          <NeoButtonMain
            label='Crear traducción'
            icon="pi pi-plus"
            onClick={() => (
              history.push({
                pathname: MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH,
                state: { lineId, name: props.template.name }
              })
            )}
          />
          <NeoButtonMain
            icon="pi pi-trash"
            label='Eliminar plantilla'
            onClick={() => setShowDeleteDialog(true)}
          />
        </NeoButtonSection>
      </NeoGridContainer>
      {
        <ActionDialog
          header='Eliminar plantilla'
          visible={showDeleteDialog}
          visibleSetter={setShowDeleteDialog}
          acceptMessage='Eliminando plantilla'
          onAccept={deleteTemplate}
          onHide={handleDeleteDialogHide}
        >
          Se eliminarán todas las traducciones de la plantilla y el nombre no se podrá utilizar durante 30 días. ¿Deseas proceder?
        </ActionDialog>
      }
    </>
  );
}
