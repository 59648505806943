import {useState} from 'react';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInputMask from 'design/design_components/neo/form/NeoInputMask.base';
import InfoTooltip from 'components/InfoTooltip.component';
import ActionDialog from 'components/ActionDialog.component';
import TestContactsService from 'services/testContacts.service';

export default function CreateTestContactDialog(props) {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const resetForm = () => {
    setPhone('');
    setName('');
    setPhoneError('');
  }

  const acceptDisabledValidation = () => {
    return phone.length == 0 
      || /^[+]?[0-9]+$/.test(phone) == false
      || name.length == 0;
  }

  const handleAccept = async () => {
    setPhoneError('');
    const response = await TestContactsService.registerTestContact(phone, name);
    if(response.success) {
      return {success: true, payload: {...response.payload, name}};
    }
    else if(response.error.code !== undefined) {
      if(response.error.code === 'INVALID_PHONE_NUMBER') {
        setPhoneError('Número de teléfono inválido.');
      }
      else if(response.error.code === 'ALREADY_EXIST') {
        setPhoneError('El número de teléfono ya se encuentra asociado a un contacto.');
      }
      return {success: false, internal: false};
    }
    return {success: false, internal: true};
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    resetForm();
  }

  const handlePhoneInputChange = (event) => {
    setPhone(event.value ?? event.target.value);
  }

  const handleNameInputChange = (event) => {
    setName(event.value ?? event.target.value);
  }

  return (
    <ActionDialog
      header='Agregar contacto de prueba'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Agregando contacto de prueba'
      acceptDisabled={acceptDisabledValidation()}
      onAccept={handleAccept}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      <NeoInputMask
        label='Número de teléfono'
        rightIcon={<InfoTooltip id='phone' body='El teléfono debe incluir el código de país.'/>}
        slotChar=''
        mask='+99999999999?99'
        value={phone}
        error={phoneError}
        onChange={handlePhoneInputChange}
        onFocus={() => setPhoneError('')}
      />
      <NeoInputText 
        label='Nombre'
        value={name}
        onChange={handleNameInputChange}
      />
    </ActionDialog>
  );
}