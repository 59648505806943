const units = ['B', 'KB', 'MB', 'GB'];

function getSizeString(size) {
  let index = 0;
  for(; index < units.length && size > 1024; ++index, size /= 1024);
  return `${Number.parseInt(size * 100) / 100} ${units[index]}`
}

export default {
  getSizeString
};