import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import useAsyncState from 'hooks/useAsyncState.hook';
import GeneralCampaignDetails from './components/GeneralCampaignDetails.component';
import CampaignMessagesStatusAndDetail from './components/CampaignMessagesStatusAndDetail.component';
import NeoFlexColumn from 'design/design_components/neo/layout/NeoFlexColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import ResourceNotFound from 'components/ResourceNotFound.component';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import DataFetchError from 'components/DataFetchError.component';
import TemplatePreview from 'components/TemplatePreview.component';
import CampaignDetailHeader from 'views/dashboard/components/CampaignDetailHeader.component';
import CampaignsService from 'services/campaigns.service';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';

export default function CampaignDetail() {
  const { id } = useParams();
  const asyncState = useAsyncState();
  const [campaign, setCampaign] = useState(null);
  const [results, setResults] = useState(null);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getCampaign()],
      { initialization: true }
    );
  }

  const getCampaign = async () => {
    const [lineId, name] = id.split('-');
    const response = await CampaignsService.getCampaign(lineId, name);
    if (response.success) {
      setCampaign(response.payload);
      return { success: true };
    }
    if (response.error.internal) {
      return { success: false };
    }
    return { success: true };
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (!asyncState.isLoading && !asyncState.isInitialized) &&
        <DataFetchError onRetry={initialize} />
      }
      {
        (!asyncState.isLoading && asyncState.isInitialized) &&
        <>
          {
            (!campaign) &&
            <ResourceNotFound
              title='La campaña no existe'
              redirection={{ label: 'Ir a dashboard de campañas', path: MENU.ADMIN.DASHBOARD.CAMPAIGNS.PATH }}
            />
          }
          {
            (campaign) &&
            <>
              <CampaignDetailHeader
                campaign={campaign}
                results={results}
                onChange={(value) => setCampaign(value)}
              />
              <NeoGridContainer>
                <NeoFlexColumn sm="12" md="5" lg="6">
                  <GeneralCampaignDetails
                    campaign={campaign}
                  />
                </NeoFlexColumn>
                <NeoFlexColumn sm="12" md="7" lg="6">
                  <NeoCard>
                    <TemplatePreview
                      template={campaign.template}
                    />
                  </NeoCard>
                </NeoFlexColumn>
              </NeoGridContainer>
              <CampaignMessagesStatusAndDetail
                campaign={campaign}
                onResultsChange={(event) => setResults(event)}
              />
            </>
          }
        </>
      }
    </>
  );
}