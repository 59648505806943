import backendRequestUtil from 'utils/backendRequest.util';

export default class TestContactsService {

  static async getTestContacts() {
    const {success, body, error} = await backendRequestUtil.get('/rest/testContacts');
    return success ? {success, payload: body.testContacts} : {success, error};
  }

  static async sendCampaignTest(lineId, phones, template) {
    const bodyRequest = {from: lineId, to: phones, template};
    const {success, error} = await backendRequestUtil.post('/api/campaignTest', bodyRequest);
    return success ? {success} : {success, error};
  }

  static async registerTestContact(phone, name) {
    const bodyRequest = {phone, name};
    const {success, body, error} = await backendRequestUtil.post('/rest/testContact', bodyRequest);
    if(!success && !error.internal) {
      if(/^número de teléfono inválido$/i.test(error.message)) {
        error.code = 'INVALID_PHONE_NUMBER';
      }
      if(/^el número de teléfono ya se encuentra asociado a un contacto$/i.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
    }
    return success ? {success, payload: body} : {success, error};
  }

}