import React from "react";

const NeoDataSecond = (props) => {
  return (<div className={`dato-secundario ${props.extra ? props.extra : ""}`} onClick={props?.onClick}>
    {
      props.fact || props.children
        ? <div className="dato">{props.fact}{props.children}</div>
        : null
    }
    {
      props.label
        ? <div className="label">{props.label}</div>
        : null
    }
  </div>);
};

export default NeoDataSecond;