import React, { useEffect, useState } from 'react';
import BaseInputText from '../../sisu/form/BaseInputText.sisu';

const NeoInputText = (props) => {
    const [style, setStyle] = useState("")

    useEffect(() => {
        let mount = true
        if (mount) {
            if (props.error) {
                setStyle(`${props.className} p-invalid p-mr-2`);
            } else {
                setStyle(props.className)
            }
        }
        return () => {
            mount = false
        }

    }, [props.error, props.className])

    return (<>
        <div className={`p-field p-col
                ${props.extra ? props.extra : ""}
                ${props.col ? `p-col-${props.col}` : ``}
                ${props.md ? `p-md-${props.md}` : ""}
                ${props.sm ? `p-sm-${props.sm}` : ""}`}>
            <span className={`p-float-label ${props.rightIcon ? 'p-input-icon-right' : ''}`}>
                {props.rightIcon}
                <BaseInputText {...props} className={style} />
                <label htmlFor={props.id}>{props.label}</label>
                {
                    props.error
                    && <small className="p-invalid p-d-block">
                        {props.error}
                    </small>
                }
                {props.children}
            </span>
        </div>
    </>);
};

export default NeoInputText;
