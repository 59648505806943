import { useState, useEffect } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import dateTimeUtil from 'utils/dateTime.util';
import LinesService from 'services/lines.service';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';

export default function GeneralCampaignDetails(props) {
  const asyncState = useAsyncState();
  const [campaign, setCampaign] = useState(props.campaign);
  const [line, setLine] = useState();

  useEffect(async () => await initialize(), []);
  useEffect(() => setCampaign(props.campaign), [props.campaign]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getLine()],
      { initialization: true }
    );
  }

  const getLine = async () => {
    const response = await LinesService.getLine(campaign.lineId);
    if (response.success) {
      setLine(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const getStatus = (status) => {
    return status == 'PENDING' ? 'Pendiente'
      : status == 'SENDING' ? 'Enviando'
        : status == 'FINISHED' ? 'Finalizada'
          : status == 'CANCELLED' ? 'Cancelada'
            : status == 'STOPPED' ? 'Detenida'
              : 'Desconocido';
  }

  return (
    <NeoCard>

      {
        (asyncState.isLoading) &&
        <InternalSpinner />
      }
      {
        (!asyncState.isLoading && !asyncState.isInitialized) &&
        <DataFetchError internal align='start' onRetry={initialize} />
      }
      {
        (!asyncState.isLoading && asyncState.isInitialized) &&
        <>
          <NeoInnerTitle extra="primero">Información general</NeoInnerTitle>
          <NeoGridContainer subExtra="mx-0" col="12">
            <NeoColumn col="12" sm="6" md="12" lg="7">

              <NeoDataThird
                extra="reverse"
                label='Plantilla usada'
                fact={campaign.template.name}
              />
              <NeoDataThird
                extra="reverse"
                label='Linea de envío'
                fact={`${line.name} | ${line.phone}`}
              />
              <NeoDataThird
                extra="reverse"
                label='Creación de campaña'
                fact={dateTimeUtil.getDateString(campaign.createdAt, { dateStyle: 'medium', timeStyle: 'short' })}
              />
              {
                ((campaign.status == 'PENDING' || campaign.status == 'CANCELLED') && campaign.scheduledAt) &&
                <NeoDataThird
                  extra="reverse"
                  label='Fecha programada'
                  fact={dateTimeUtil.getDateString(campaign.scheduledAt, { dateStyle: 'medium', timeStyle: 'short' })}
                />
              }
            </NeoColumn>


            <NeoColumn col="12" sm="6" md="12" lg="5">
              <NeoDataThird
                extra="reverse"
                label='Estatus de campaña'
                fact={getStatus(campaign.status)}
              />
              {
                (campaign.status != 'PENDING' && campaign.status != 'CANCELLED' && campaign.startedAt) &&
                <NeoDataThird
                  extra="reverse"
                  label='Fecha de envío'
                  fact={dateTimeUtil.getDateString(campaign.startedAt, { dateStyle: 'medium', timeStyle: 'short' })}
                />
              }
              {
                (campaign.status == 'FINISHED' && campaign.finishedAt) &&
                <NeoDataThird
                  extra="reverse"
                  label='Fecha de finalización'
                  fact={dateTimeUtil.getDateString(campaign.finishedAt, { dateStyle: 'medium', timeStyle: 'short' })}
                />
              }
              {
                (campaign.status == 'CANCELLED' && campaign.cancelledAt) &&
                <NeoDataThird
                  extra="reverse"
                  label='Fecha de cancelación'
                  fact={dateTimeUtil.getDateString(campaign.cancelledAt, { dateStyle: 'medium', timeStyle: 'short' })}
                />
              }
              {
                (campaign.status == 'STOPPED' && campaign.stoppedAt) &&
                <NeoDataThird
                  extra="reverse"
                  label='Fecha de detención'
                  fact={dateTimeUtil.getDateString(campaign.stoppedAt, { dateStyle: 'medium', timeStyle: 'short' })}
                />
              }
            </NeoColumn>
          </NeoGridContainer>
        </>
      }
    </NeoCard>

  );
}