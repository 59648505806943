import { useState } from 'react';
import ActionDialog from 'components/ActionDialog.component';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';

const MAX_SIZE = 5242880;

export default function LinePhotoExplorer(props) {
  const [showSizeErrorDialog, setShowSizeErrorDialog] = useState(false);
  const [showMaxDimensionErrorDialog, setShowMaxDimensionErrorDialog] = useState(false);
  const [showMinDimensionErrorDialog, setShowMinDimensionErrorDialog] = useState(false);
  const [showSquareDimensionErrorDialog, setShowSquareDimensionErrorDialog] = useState(false);

  const handleFileInputChange = async (event) => {
    const [file] = event.target.files;
    if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
      if (file.size <= MAX_SIZE) {
        const image = new Image();
        image.src = window.URL.createObjectURL(file);
        image.onload = () => {
          if (image.width != image.height) { setShowSquareDimensionErrorDialog(true); }
          else if (image.width < 192) { setShowMinDimensionErrorDialog(true); }
          else if (image.width > 640) { setShowMaxDimensionErrorDialog(true); }
          else { props.onChange(file); }
        }
      }
      else { setShowSizeErrorDialog(true); }
    }
    event.target.value = '';
  }

  return (
    <>
      <input
        className='p-d-none'
        id='explorer'
        type='file'
        accept='image/png,image/jpg,image/jpeg'
        onChange={handleFileInputChange}
      />
      <NeoButtonMain
        label='Cambiar foto'
        icon="pi pi-camera"
        onClick={() => document.getElementById('explorer').click()}
      />
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showSizeErrorDialog}
          visibleSetter={setShowSizeErrorDialog}
          cancel={false}
        >
          La foto debe tener un tamaño máximo de 5 MB.
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showSquareDimensionErrorDialog}
          visibleSetter={setShowSquareDimensionErrorDialog}
          cancel={false}
        >
          La foto debe ser cuadrada.
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showMinDimensionErrorDialog}
          visibleSetter={setShowMinDimensionErrorDialog}
          cancel={false}
        >
          La foto debe tener un ancho y altura mínima de 192 píxeles.
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showMaxDimensionErrorDialog}
          visibleSetter={setShowMaxDimensionErrorDialog}
          cancel={false}
        >
          La foto debe tener un ancho y altura máxima de 640 píxeles.
        </ActionDialog>
      }
    </>
  );
}