import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class TemplatesService {

  static async getTemplates(lineId, options = {}) {
    const params = {lineId, limit: '1000000', ...options};
    const {success, body, error} = await backendRequestUtil.get('/api/templates', {params});
    if(success) {
      const templates = body.templates.map((template) => {
        template.createdAt = dateTimeUtil.backendDateStringToDate(template.createdAt);
        return template;
      });
      return {success, payload: templates};
    }
    return {success, error};
  }

  static async createTemplate(lineId, template) {
    const params = {lineId};
    const bodyRequest = template;
    const {success, error} = await backendRequestUtil.post('/api/template', bodyRequest, {params});
    return success ? {success} : {success, error};
  }

  static async deleteTemplate(lineId, name) {
    const params = {lineId, name};
    const {success, error} = await backendRequestUtil.delete('/api/template', {params});
    return success ? {success} : {success, error};
  }

}