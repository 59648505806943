import {useState, useEffect} from 'react';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import ActionDialog from 'components/ActionDialog.component';
import InfoTooltip from 'components/InfoTooltip.component';
import templatesUtil from 'utils/templates.util';

const UNSELECTED_COLUMN = '';

export default function VariablesAndFieldsMappingDialog(props) {
  const [options, setOptions] = useState([]);
  const [phoneParam, setPhoneParam] = useState();
  const [headerParam, setHeaderParam] = useState();
  const [bodyParams, setBodyParams] = useState();
  const [buttonsParams, setButtonsParams] = useState();
  const [templateRequiredParams, setTemplateRequiredParams] = useState();
  const [multimediaReferenceOptions, setMultimediaReferenceOptions] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => initializeRequiredParams(), []);
  useEffect(() => rebootMapping(), [props.visible]);
  useEffect(() => rebootOptions(), [props.options]);

  const initializeRequiredParams = () => {
    const templateRequiredParams = templatesUtil.getTemplateRequiredParams(props.templateStructure);
    const {header, body, buttons} = templateRequiredParams;
    setTemplateRequiredParams(templateRequiredParams);
    setPhoneParam(UNSELECTED_COLUMN);
    if(header) {
      const headerParam = {type: header.type, params: []};
      if(header.type == 'TEXT') {
        headerParam.params.push({param: header.param, column: UNSELECTED_COLUMN, optional: false});
      }
      else {
        headerParam.params.push({param: 'url', column: UNSELECTED_COLUMN, optional: false});
        if(header.type == 'DOCUMENT') {
          headerParam.params.push({param: 'filename', column: UNSELECTED_COLUMN, optional: true});
        }
        setMultimediaReferenceOptions([
          {label: `Url de ${templatesUtil.getTemplateHeaderType(header.type, {lowerCase: true})}`, value: 'url'},
          {label: `Id de ${templatesUtil.getTemplateHeaderType(header.type, {lowerCase: true})}`, value: 'id'}
        ]);
      }
      if(props.mapping && !isInitialized) {
        const lowerCaseType = header.type.toLowerCase();
        headerParam.params = headerParam.params.map((param) => {
          let paramName =
            (header.type != 'TEXT')
              ? param.param
              : param.param.substring(2, header.param.length - 2);
          if(paramName == 'url') {
            if(props.mapping[`header.${lowerCaseType}.id`] !== undefined) {
              paramName = 'id';
              headerParam.params[0].param = 'id';
            }
          }
          const paramString = `header.${lowerCaseType}.${paramName}`;
          param.column = props.mapping[paramString] ?? UNSELECTED_COLUMN;
          return param;
        });
      }
      setHeaderParam(headerParam);
    }
    if(body?.length > 0) {
      const bodyParams = body.map((param) => {
        let column = UNSELECTED_COLUMN;
        if(props.mapping && !isInitialized) {
          const paramString = `body.${param.substring(2, param.length - 2)}`;
          column = props.mapping[paramString] ?? UNSELECTED_COLUMN;
        }
        return {param, column};
      });
      setBodyParams(bodyParams);
    }
    if(buttons?.length > 0) {
      const buttonsParams = buttons.map((button) => {
        let column = UNSELECTED_COLUMN;
        if(props.mapping && !isInitialized) {
          const paramString = `buttons.${button.index + 1}.url.${button.param.substring(2, button.param.length - 2)}`;
          column = props.mapping[paramString] ?? UNSELECTED_COLUMN;
        }
        return {...button, column};
      });
      setButtonsParams(buttonsParams);
    }
    setIsInitialized(true);
  }

  const rebootMapping = () => {
    if(props.visible === true && props.autoClear !== false) {
      initializeRequiredParams();
    }
  }

  const rebootOptions = () => {
    const dropdownOptions = props.options ?? [];
    setOptions(dropdownOptions.filter((option) => option.label?.trim().length > 0));
  }

  const acceptDisabledValidation = () => {
    if(props.type != 'contacts' && phoneParam === UNSELECTED_COLUMN) { return true; }
    if(headerParam) {
      const selectedHeaderColumns = headerParam.params.every((param) => param.optional === true || param.column !== UNSELECTED_COLUMN);
      if (!selectedHeaderColumns) { return true; }
    }
    if(bodyParams) {
      const selectedBodyColumns = bodyParams.every((bodyParam) => bodyParam.column !== UNSELECTED_COLUMN);
      if (!selectedBodyColumns) { return true; }
    }
    if(buttonsParams?.length > 0) {
      const selectedButtonsColumns = buttonsParams.every((buttonParam) => buttonParam.column !== UNSELECTED_COLUMN);
      if (!selectedButtonsColumns) { return true; }
    }
    return false;
  }

  const handlePhoneColumnDropdownChange = (event) => {
    const column = event.value ?? event.target.value;
    setPhoneParam(column);
  }

  const handleHeaderMultimediaReferenceChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedHeaderParams = [...headerParam.params];
    updatedHeaderParams[index].param = value;
    setHeaderParam({...headerParam, params: updatedHeaderParams});
  }

  const handleHeaderParamChange = (index, event) => {
    const column = event.value ?? event.target.value;
    const updatedHeaderParams = [...headerParam.params];
    updatedHeaderParams[index].column = column;
    setHeaderParam({...headerParam, params: updatedHeaderParams});
  }

  const handleBodyParamChange = (index, event) => {
    const column = event.value ?? event.target.value;
    const updatedBodyParams = [...bodyParams];
    updatedBodyParams[index].column = column;
    setBodyParams(updatedBodyParams);
  }

  const handleButtonParamChange = (index, event) => {
    const column = event.value ?? event.target.value;
    const updatedButtonsParams = [...buttonsParams];
    updatedButtonsParams[index].column = column;
    setButtonsParams(updatedButtonsParams);
  }

  const handleAccept = () => {
    const mapping = props.type == 'contacts' ? {} : {to: phoneParam};
    const {header, body, buttons} = templateRequiredParams;
    if(header) {
      const lowerCaseType = header.type.toLowerCase();
      headerParam.params.forEach((param) => {
        if(param.column != UNSELECTED_COLUMN) {
          const paramName =
            (header.type != 'TEXT')
              ? param.param
              : param.param.substring(2, header.param.length - 2);
          mapping[`header.${lowerCaseType}.${paramName}`] = param.column;
        }
      });
    }
    if(body?.length > 0) {
      bodyParams.forEach((bodyParam) => {
        const paramName = bodyParam.param.substring(2, bodyParam.param.length - 2);
        mapping[`body.${paramName}`] = bodyParam.column;
      });
    }
    if(buttons?.length > 0) {
      buttonsParams.forEach((buttonParam) => {
        if (buttonParam.type == 'URL') {
          const paramName = buttonParam.param.substring(2, buttonParam.param.length - 2);
          mapping[`buttons.${buttonParam.index + 1}.url.${paramName}`] = buttonParam.column;
        }
      });
    }
    props.onAccept(mapping);
  }

  const Elements = {
    OptionsDropdown: (internalProps) => (
      <NeoDropdown
        col='6'
        placeholder={`${props.type == 'contacts' ? 'Campo' : 'Columna'} a mapear`}
        value={internalProps.value}
        options={options}
        onChange={internalProps.onChange}
      />
    )
  };

  return (
    <ActionDialog
      maximizable
      header='Mapeo de variables'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      onAccept={handleAccept}
      onCancel={props.onCancel}
      cancel={props.cancel}
      acceptDisabled={acceptDisabledValidation()}
    >
      {
        (props.type != 'contacts') &&
        <NeoFlexContainer jc='between' ai='center'>
          <InfoTooltip 
            id='phone' 
            label='Número de teléfono' 
            body='El número de teléfono de los contactos debe contener el código de país si la línea que enviará la campaña pertenece a un país diferente.'
          />
          <Elements.OptionsDropdown
            value={phoneParam}
            onChange={handlePhoneColumnDropdownChange}
          />
        </NeoFlexContainer>
      }
      {
        (headerParam) &&
        <>
          <NeoInnerSubtitle>Encabezado</NeoInnerSubtitle>
          {
            headerParam.params.map((param, index) => {
              if(param.param == 'url' || param.param == 'id') {
                return (
                  <NeoFlexContainer key={index} jc='between' ai='center'>
                    <NeoDropdown
                      col='6'
                      value={param.param}
                      options={multimediaReferenceOptions}
                      onChange={(event) => handleHeaderMultimediaReferenceChange(index, event)}
                    />
                    <Elements.OptionsDropdown
                      value={param.column}
                      onChange={(event) => handleHeaderParamChange(index, event)}
                    />
                  </NeoFlexContainer>
                );
              }
              const name = param.param == 'filename' ? 'Nombre de documento' : param.param;
              return (
                <NeoFlexContainer key={index} jc='between' ai='center'>
                  {name}
                  <Elements.OptionsDropdown
                    value={param.column}
                    onChange={(event) => handleHeaderParamChange(index, event)}
                  />
                </NeoFlexContainer>
              );
            })
          }
        </>
      }
      {
        (bodyParams?.length > 0) &&
        <>
          <NeoInnerSubtitle>Cuerpo</NeoInnerSubtitle>
          {
            bodyParams.map((bodyParam, index) => (
              <NeoFlexContainer jc='between' ai='center'>
                {bodyParam.param}
                <Elements.OptionsDropdown
                  value={bodyParam.column}
                  onChange={(event) => handleBodyParamChange(index, event)}
                />
              </NeoFlexContainer>
            ))
          }
        </>
      }
      {
        (buttonsParams) &&
        buttonsParams.map((button, index) => (
          <>
            <NeoInnerSubtitle>Botón {button.index + 1}</NeoInnerSubtitle>
            <NeoFlexContainer jc='between' ai='center'>
              {button.param}
              <NeoDropdown
                col='6'
                placeholder='Columna a mapear'
                value={button.column}
                options={options}
                onChange={(event) => handleButtonParamChange(index, event)}
              />
            </NeoFlexContainer>
          </>
        ))
      }
      {
        (!headerParam && (!bodyParams || bodyParams.length == 0) && (!buttonsParams || buttonsParams.length == 0)) &&
        <>La plantilla no tiene variables.</>
      }
    </ActionDialog>
  );
}