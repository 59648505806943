import { useState, useEffect } from 'react';
import TemplatePreview from 'components/TemplatePreview.component';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';

export default function CampaignTemplatePreview(props) {
  const [template] = useState(props.template);
  const [testStructure, setTestStructure] = useState(props.template.structure);
  const [randomRowIndex, setRandomRowIndex] = useState(null);
  const exampleRows = props.examples ?? [];

  useEffect(() => reboot(), [exampleRows]);

  const reboot = () => {
    if (exampleRows.length > 0) {
      const randomIndex = getRandomRowIndex();
      const randomExampleRow = exampleRows[randomIndex];
      const updatedTestStructure = {
        ...testStructure,
        header: { ...template.structure.header },
        body: template.structure.body
      };
      Object.entries(randomExampleRow).forEach(([paramNotation, paramValue]) => {
        const paramParts = paramNotation.split('.');
        const section = paramParts[0];
        if (section == 'header') {
          const headerType = paramParts[1];
          const paramName = paramParts[2];
          if (headerType == 'text') {
            updatedTestStructure.header.text = updatedTestStructure.header.text.replace(`{{${paramName}}}`, paramValue);
          }
          else {
            const headerTypeParams = updatedTestStructure.header[headerType] ?? {};
            headerTypeParams[paramName] = paramValue;
            updatedTestStructure.header[headerType] = headerTypeParams;
          }
        }
        if (section == 'body') {
          const paramName = paramParts[1];
          updatedTestStructure.body = updatedTestStructure.body.replace(`{{${paramName}}}`, paramValue);
        }
      });
      setTestStructure(updatedTestStructure);
      props.onChange(randomExampleRow);
    }
  }

  const getRandomRowIndex = () => {
    let randomIndex = Math.floor(Math.random() * exampleRows.length);
    while (randomRowIndex === randomIndex) {
      randomIndex = Math.floor(Math.random() * exampleRows.length);
    }
    setRandomRowIndex(randomIndex);
    return randomIndex;
  }

  return (
    <NeoCard>

      <TemplatePreview template={{ ...template, structure: testStructure }} />
      <NeoButtonSection align="center">
        <NeoButtonMain
          label='Regenerar prueba con nuevos datos'
          icon='pi pi-fw pi-refresh'
          disabled={exampleRows.length <= 1}
          onClick={reboot}
        />
      </NeoButtonSection>
    </NeoCard>
  );
}