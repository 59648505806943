import backendRequestUtil from 'utils/backendRequest.util';

export default class ConsumptionService {

  static async getCompanyStatistics(linesId, fromDateString, toDateString) {
    const params = {
      linesId: linesId.join(','),
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/companyStatistics', {params});
    return success ? {success, payload: body} : {success, error};
  }

}