import React from "react";
import { useHistory } from "react-router-dom";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowAltCircleLeft} from '@fortawesome/free-regular-svg-icons';


const NeoTitleSecond = (props) => {
    const history = useHistory();

    return (<div {...props} className={`p-ai-center p-col header-titulo-secundario-pagina p-d-flex  
                            ${props.extra ? props.extra : ""}`} >


        {props.goback &&
            <div className="center-con icon-back" onClick={() => history.goBack()}>
                <div className="round">
                    <div id="cta">
                        <span className="arrow primera next "></span>
                        <span className="arrow segunda next "></span>
                    </div>
                </div>
            </div>
        }

        {
            props.icon
                ?
                <img width="45" src={props.icon} alt={props.alt} />
                :
                null
        }

        <div className="p-d-flex p-flex-column ">
            {
                props.title
                    ? <h1 className=" titulo-pagina-principal">{props.title}</h1>
                    : null
            }
            {
                props.subtitle
                    ? <h2 className="subtitulo-pagina-principal">{props.subtitle}</h2>
                    : null
            }
        </div>
    </div>);
};

NeoTitleSecond.defaultProps = {
    goback: true
}

export default NeoTitleSecond;