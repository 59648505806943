import {useState} from 'react';
import TemplatesListHeader from './components/TemplatesListHeader.component';
import TemplatesListTable from './components/TemplatesListTable.component';

export default function TemplatesList() {
  const [filters, setFilters] = useState(null);

  return (
    <>
      <TemplatesListHeader onChange={(event) => setFilters(event)}/>
      {
        (filters && filters.line) &&
        <TemplatesListTable filters={filters}/>
      }
    </>
  );
}