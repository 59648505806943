import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import InfoTooltip from 'components/InfoTooltip.component';
import LinesService from 'services/lines.service';
import TemplatesService from 'services/templates.service';
import TEMPLATES from 'consts/templates.consts';

export default function GeneralCampaignDataForm(props) {
  const asyncState = useAsyncState();
  const [state, setState] = useStateParams();
  const [name, setName] = useState('');
  const [lines, setLines] = useState([]);
  const [templates, setTemplates] = useState(null);
  const [selectedLine, setSelectedLine] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedTranslation, setSelectedTranslation] = useState();

  useEffect(async () => await initialize(), []);
  useEffect(() => handleChangeGeneralData(), [name, selectedLine, selectedTemplate, selectedTranslation]);

  const initialize = async () => {
    const allPromises = await asyncState.allPromises(
      [getLines()],
      {initialization: true}
    );
    if(allPromises.fulfilled && allPromises.successfully) {
      const lines = allPromises.results[0].payload;
      const name = getName();
      const selectedLine = getSelectedLine(lines);
      setName(name);
      await handleLineChange(selectedLine);
    }
  }

  const handleChangeGeneralData = () => {
    const event = {
      valid: true,
      payload: {
        name,
        line: selectedLine,
        template: {
          name: selectedTemplate?.name,
          ...selectedTranslation
        }
      }
    };
    const {payload} = event;
    event.valid = payload.name.length > 0
      && payload.line !== undefined
      && payload.template.name !== undefined
      && payload.template.language !== undefined
      && payload.template.structure !== undefined;
    setState(payload);
    props.onChange(event);
  }

  const handleLineChange = async (selectedLine) => {
    setSelectedTemplate();
    setSelectedTranslation();
    setSelectedLine(selectedLine);
    await asyncState.allPromises(
      [getTemplates(selectedLine)]
    );
  }

  const getName = () => {
    return state.name ?? '';
  }

  const getSelectedLine = (lines) => {
    return state.line ?? lines[0];
  }

  const getSelectedTemplate = (templates) => {
    const templateName = state.template?.name;
    if(templateName !== undefined) {
      for(const template of templates) {
        if(template.name == templateName) {
          return template;
        }
      }
    }
    return templates[0];
  }

  const getSelectedTranslation = (selectedTemplate) => {
    const templateLanguage = state.template?.language;
    if(templateLanguage !== undefined) {
      for(const translation of selectedTemplate.translations) {
        if(translation.language == templateLanguage) {
          return translation;
        }
      }
    }
    return selectedTemplate.translations[0];
  }

  const getLines = async () => {
    const response = await LinesService.getLines();
    if (response.success) {
      const lines = response.payload.map((line) => {
        return { id: line.id, name: line.name, phone: line.phone };
      });
      setLines(lines);
      return { success: true, payload: lines };
    }
    return { success: false };
  }

  const getTemplates = async (line) => {
    const response = await TemplatesService.getTemplates(line.id);
    if (response.success) {
      const approvedTemplates = response.payload.filter((template) => template.status == 'APPROVED');
      const templatesNames = [...new Set(approvedTemplates.map((template) => template.name))];
      const templates = templatesNames.map((templateName) => {
        const translations = response.payload
          .filter((template) => template.name == templateName)
          .map(({ language, structure }) => ({ language, structure }));
        return { name: templateName, translations };
      });
      const selectedTemplate = getSelectedTemplate(templates);
      const selectedTranslation = getSelectedTranslation(selectedTemplate);
      setTemplates(templates);
      setSelectedTemplate(selectedTemplate);
      setSelectedTranslation(selectedTranslation);
      return { success: true };
    }
    return { success: false };
  }

  const handleNameInputChange = (event) => {
    let name = event.value ?? event.target.value;
    if(name[0] != '_' && Number.isNaN(Number.parseInt(name[0]))) {
      name = name.trim();
      name = name.replace(/[^_a-zA-ZñÑ0-9]+/g, '_');
      setName(name);
    }
  }

  const handleLineDropdownChange = async (event) => {
    const selectedLine = event.value;
    await handleLineChange(selectedLine);
  }

  const handleTemplateDropdownChange = (event) => {
    const selectedTemplate = event.value;
    const selectedTranslation = selectedTemplate.translations[0];
    setSelectedTemplate(selectedTemplate);
    setSelectedTranslation(selectedTranslation);
  }

  const handleLanguageDropdownChange = (event) => {
    const selectedTranslation = event.value;
    setSelectedTranslation(selectedTranslation);
  }

  return (
    <>
      {
        (asyncState.isInitialized) &&
        <>
          <NeoCard>
            <NeoInputText md='6'
              label='Nombre de la campaña'
              rightIcon={<InfoTooltip id='name' body='El nombre de la campaña no contiene espacios ni caracteres especiales.'/>}
              value={name}
              maxlength='48'
              keyfilter={/^[_a-zA-ZñÑ0-9]+$/}
              onChange={handleNameInputChange}
            />
            <NeoDropdown md='6'
              label='Línea de teléfono'
              value={selectedLine}
              options={lines.map((line) => ({ label: `${line.name} | ${line.phone}`, value: line }))}
              onChange={handleLineDropdownChange}
            />
          </NeoCard>
          <NeoCard>
            {
              (asyncState.isLoading) &&
              <InternalSpinner/>
            }
            {
              (!asyncState.isLoading) &&
              <>
                {
                  (asyncState.isSuccessfully && templates) &&
                  <>
                    <NeoDropdown md='6'
                      label='Nombre de plantilla'
                      value={selectedTemplate}
                      options={templates.map((template) => ({ label: template.name, value: template }))}
                      onChange={handleTemplateDropdownChange}
                    />
                    <NeoDropdown md='6'
                      label='Idioma de plantilla'
                      value={selectedTranslation}
                      options={selectedTemplate.translations.map((translation) => ({label: TEMPLATES.LANGUAGES[translation.language], value: translation}))}
                      onChange={handleLanguageDropdownChange}
                    />
                  </>
                }
                {
                  (!asyncState.isSuccessfully) &&
                  <DataFetchError internal align='start' onRetry={() => handleLineChange(selectedLine)} />
                }
              </>
            }
          </NeoCard>
        </>
      }
      {
        (!asyncState.isInitialized) &&
        <>
          {
            (asyncState.isLoading) &&
            <NeoSpinner />
          }
          {
            (!asyncState.isLoading) &&
            <DataFetchError onRetry={initialize} />
          }
        </>
      }
    </>
  );
}