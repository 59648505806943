import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

export default function ExtraContactDataForm(props) {
  const [gender, setGender] = useState(props.contact?.gender ?? '');
  const [type, setType] = useState(props.contact?.type ?? '');
  const [company, setCompany] = useState(props.contact?.company ?? '');
  const [country, setCountry] = useState(props.contact?.country ?? '');
  const [address, setAddress] = useState(props.contact?.address ?? '');
  const [postalCode, setPostalCode] = useState(props.contact?.postalCode ?? '');
  const [email, setEmail] = useState(props.contact?.email ?? '');
  const [userId, setUserId] = useState(props.contact?.userId ?? '');

  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [gender, type, company, country, address, postalCode, email, userId]);

  const handleChange = () => {
    const event = { payload: { gender, type, company, country, address, postalCode, email, userId } };
    if (props.contact) {
      event.change = gender !== props.contact.gender
        || type !== props.contact.type
        || company !== props.contact.company
        || country !== props.contact.country
        || address !== props.contact.address
        || postalCode !== props.contact.postalCode
        || email !== props.contact.email
        || userId !== props.contact.userId;
    }
    props.onChange(event);
  }

  const handleInputChange = (setter, event) => {
    const value = event.value ?? event.target.value;
    setter(value);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Información adicional</NeoInnerTitle>
      <NeoInputText md='4' col="12"
        label='Correo electrónico'
        value={email}
        onChange={(event) => handleInputChange(setEmail, event)}
      />
      <NeoInputText md='4' col="12"
        label='ID usuario'
        value={userId}
        onChange={(event) => handleInputChange(setUserId, event)}
      />
      <NeoInputText md='4' col="12"
        label='Empresa'
        value={company}
        onChange={(event) => handleInputChange(setCompany, event)}
      />

      <NeoInputText md='6' col="12"
        label='Domicilio'
        value={address}
        onChange={(event) => handleInputChange(setAddress, event)}
      />
      <NeoInputText md='2' col="12"
        label='Código postal'
        value={postalCode}
        onChange={(event) => handleInputChange(setPostalCode, event)}
      />
      <NeoInputText md='4' col="12"
        label='País'
        value={country}
        onChange={(event) => handleInputChange(setCountry, event)}
      />
      <NeoInputText md='4' col="12"
        label='Género'
        value={gender}
        onChange={(event) => handleInputChange(setGender, event)}
      />
      <NeoInputText md='4' col="12"
        label='Tipo'
        value={type}
        onChange={(event) => handleInputChange(setType, event)}
      />
    </NeoCard>
  );
}