import React from "react";
import BaseButtonMain from "../../sisu/button/BaseButtonMain.sisu"


const NeoButtonWhatsapp = (props) => {
    const { subtitle, ...params } = props;

    return (<>
        <BaseButtonMain alternative="wa" extra="p-button-link" {...params} />
    </>);
};

export default NeoButtonWhatsapp;