import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import ContactsService from 'services/contacts.service';
import dateTimeUtil from 'utils/dateTime.util';

export default function ContactsList() {
  const tableRef = useRef();
  const history = useHistory();
  const [contacts, setContacts] = useState([]);
  const asyncState = useAsyncState({ isInitialized: true });

  useEffect(async () => await reboot(), []);

  const reboot = async () => {
    await asyncState.allPromises(
      [getContacts()]
    );
  }

  const getContacts = async () => {
    const response = await ContactsService.getContacts();
    if (response.success) {
      setContacts(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const handleTableRowSelect = (event) => {
    const { id } = event.data;
    history.push(`${MENU.ADMIN.CONTACTS.CONTACT_DETAIL.BASE}/${id}`);
  }

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre' />
    ),
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='phone' placeholder='Buscar por teléfono' />
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    createdAtColumnBody: (data) => (
      <>{dateTimeUtil.getDateString(data.createdAt, { dateStyle: 'medium', timeStyle: 'short' })}</>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner />
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                ref={tableRef}
                selectionMode='single'
                value={contacts}
                paginator
                rows={10}
                removableSort
                sortField='createdAt'
                sortOrder={-1}
                emptyMessage='No hay contactos'
                onRowSelect={handleTableRowSelect}

              >
                <NeoTableColumn
                  field='name'
                  header='Nombre'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.nameFilterInput}
                />
                <NeoTableColumn
                  field='phone'
                  header='Teléfono'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.phoneFilterInput}
                />
                <NeoTableColumn
                  field='createdAt'
                  header='Fecha de creación'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.createdAtFilterRange}
                  filterFunction={dateTimeUtil.filterDate}
                  body={elements.createdAtColumnBody}
                />
              </NeoTable>
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError internal align='start' onRetry={reboot}/>
            </NeoCard>
          }
        </>
      }
    </>
  );
}