import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class CampaignsService {

  static async isExistingCampaign(lineId, name) {
    const params = {lineId, name};
    const {success, error} = await backendRequestUtil.get('/api/campaign', {params});
    if(success) {
      return {success, payload: true};
    }
    if(!error.internal) {
      if(/^La campaña [^]+ no existe en la línea [^]+$/i.test(error.message)) {
        return {success: true, payload: false};
      }
    }
    return {success, error};
  }

  static async getCampaign(lineId, name) {
    const params = {lineId, name};
    const {success, body, error} = await backendRequestUtil.get('/api/campaign', {params});
    if(success) {
      body.createdAt = dateTimeUtil.backendDateStringToDate(body.createdAt);
      body.finishedAt = dateTimeUtil.backendDateStringToDate(body.finishedAt);
      body.scheduledAt = dateTimeUtil.backendDateStringToDate(body.scheduledAt);
      body.startedAt = dateTimeUtil.backendDateStringToDate(body.startedAt);
      body.cancelledAt = dateTimeUtil.backendDateStringToDate(body.cancelledAt);
      body.stoppedAt = dateTimeUtil.backendDateStringToDate(body.stoppedAt);
      body.failedAt = dateTimeUtil.backendDateStringToDate(body.failedAt);
      return {success, payload: body};
    }
    return {success, error};
  }

  static async getCampaigns(lineId) {
    const params = {linesId: lineId.toString(), limit: 1000000};
    const {success, body, error} = await backendRequestUtil.get('/api/campaigns', {params});
    if(success) {
      const campaigns = body.campaigns.map((campaign) => {
        campaign.createdAt = dateTimeUtil.backendDateStringToDate(campaign.createdAt);
        campaign.finishedAt = dateTimeUtil.backendDateStringToDate(campaign.finishedAt);
        campaign.scheduledAt = dateTimeUtil.backendDateStringToDate(campaign.scheduledAt);
        campaign.startedAt = dateTimeUtil.backendDateStringToDate(campaign.startedAt);
        campaign.cancelledAt = dateTimeUtil.backendDateStringToDate(campaign.cancelledAt);
        campaign.stoppedAt = dateTimeUtil.backendDateStringToDate(campaign.stoppedAt);
        campaign.failedAt = dateTimeUtil.backendDateStringToDate(campaign.failedAt);
        return campaign;
      });
      return {success, payload: campaigns};
    }
    return {success, error};
  }
  
  static async updateCampaignStatus(lineId, campaignName, status) {
    const params = {lineId, name: campaignName};
    const requestBody = {status};
    const {success, error} = await backendRequestUtil.put('/api/campaignStatus', requestBody, {params});
    return success ? {success} : {success, error};
  }

  static async getCampaignsStatistics(linesId, fromDateString, toDateString) {
    const params = {
      linesId: linesId.join(','),
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignsStatistics', {params});
    if(success) {
      body.campaigns = body.campaigns.map((campaign) => {
        campaign.createdAt = dateTimeUtil.backendDateStringToDate(campaign.createdAt);
        campaign.finishedAt = dateTimeUtil.backendDateStringToDate(campaign.finishedAt);
        campaign.scheduledAt = dateTimeUtil.backendDateStringToDate(campaign.scheduledAt);
        campaign.startedAt = dateTimeUtil.backendDateStringToDate(campaign.startedAt);
        campaign.cancelledAt = dateTimeUtil.backendDateStringToDate(campaign.cancelledAt);
        campaign.stoppedAt = dateTimeUtil.backendDateStringToDate(campaign.stoppedAt);
        campaign.failedAt = dateTimeUtil.backendDateStringToDate(campaign.failedAt);
        return campaign;
      });
      return {success, payload: body}
    }
    return {success, error};
  }

  static async getCampaignStatistics(lineId, name) {
    const params = {lineId, name};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignStatistics', {params});
    if(success) {
      const messagesDetail = body.messagesDetail.map((messageDetail) => {
        messageDetail.sentAt = dateTimeUtil.backendDateStringToDate(messageDetail.sentAt);
        messageDetail.deliveredAt = dateTimeUtil.backendDateStringToDate(messageDetail.deliveredAt);
        messageDetail.readAt = dateTimeUtil.backendDateStringToDate(messageDetail.readAt);
        return {message: messageDetail};
      });
      return {success, payload: {...body, messagesDetail}};
    }
    return {success, error};
  }

  static async createCampaign(campaign) {
    let requestBody = campaign;
    if(campaign.databaseType === 'FILES') {
      const multipart = new FormData();
      multipart.set('name', campaign.name);
      multipart.set('lineId', campaign.lineId);
      multipart.set('templateName', campaign.templateName);
      multipart.set('templateLanguage', campaign.templateLanguage);
      if(campaign.scheduledAt) { multipart.set('scheduledAt', campaign.scheduledAt); }
      campaign.files.forEach(({file, mapping}, index) => {
        const fileField = `file${index+1}`;
        const fileMappingField = `${fileField}.map`;
        multipart.set(fileField, file);
        multipart.set(fileMappingField, JSON.stringify(mapping));
      });
      requestBody = multipart;
    }
    const {success, error} = await backendRequestUtil.post('/api/campaign', requestBody);
    if(!success && !error.internal) {
      if(/^La plantilla [^]+ en idioma [^]+ no existe$/i.test(error.message)) {
        error.code = 'NON_EXISTENT_TEMPLATE';
      }
      else if(/^La plantilla [^]+ en idioma [^]+ no se encuentra aprobada$/i.test(error.message)) {
        error.code = 'UNAPPROVED_TEMPLATE';
      }
      else if(/^La campaña [^]+ ya existe en la línea [^]+$/i.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
      else if(/^El parámetro [to] debe ser mapeado en [^]+$/i.test(error.message)) {
        const file = error.message
          .replace(/El parámetro [to] debe ser mapeado en /i, '');
        error.code = 'PARAMETER_TO_IS_NOT_MAPPED';
        error.file = file.replace(/file/i, '');
      }
      else if(/^La cabezera [^]+ no existe en [^]+$/i.test(error.message)) {
        const [header, file] = error.message
          .replace(/La cabezera /i, '')
          .replace(/ no existe en /i, ';')
          .split(';');
        error.code = 'MISSING_HEADER_IN_FILE';
        error.header = header;
        error.file = file.replace(/file/i, '');
      }
      else if(/^El campo header contiene tipos diferentes$/i.test(error.message)) {
        error.code = 'DIFFERENT_PARAMETER_TYPE_IN_HEADER_CSV';
      }
      else if(/^[^]+ en [^]+$/i.test(error.message)) {
        const file = error.message
          .replace(/[^]+ en /i, '');
        error.code = 'TEMPLATE_STRUCTURE';
        error.file = file.replace(/file/i, '');
      }
    }
    return success ? {success} : {success, error};
  }

}