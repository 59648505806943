import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import DataFetchError from 'components/DataFetchError.component';
import GeneralTemplateDataForm from './components/GeneralTemplateDataForm.component';
import VariablesInfo from 'views/templates/components/VariablesInfo.component';
import TextFormatInfo from 'views/templates/components/TextFormatInfo.component';
import TemplateHeaderStructureForm from 'views/templates/components/TemplateHeaderStructureForm.component';
import TemplateBodyStructureForm from 'views/templates/components/TemplateBodyStructureForm.component';
import TemplateFooterStructureForm from 'views/templates/components/TemplateFooterStructureForm.component';
import TemplateButtonsStructureForm from 'views/templates/components/TemplateButtonsStructureForm.component';
import TemplatePreview from 'components/TemplatePreview.component';
import Icono from 'design/assets/img/cta/icons/icon-template.svg';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import TemplatesService from 'services/templates.service';
import LinesService from 'services/lines.service';
import NeoFlexColumn from 'design/design_components/neo/layout/NeoFlexColumn.base';

export default function CreateTemplate() {
  const history = useHistory();
  const asyncState = useAsyncState();
  const [state, setState] = useStateParams();
  const [line, setLine] = useState();
  const [templates, setTemplates] = useState([]);
  const [generalData, setGeneralData] = useState(null);
  const [headerStructure, setHeaderStructure] = useState(null);
  const [bodyStructure, setBodyStructure] = useState(null);
  const [footerStructure, setFooterStructure] = useState(null);
  const [buttonsStructure, setButtonsStructure] = useState(null);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    if (state.lineId) {
      await asyncState.allPromises(
        [getLine(), getTemplates()],
        { initialization: true }
      );
    }
    else {
      history.push(MENU.ADMIN.TEMPLATES.ROOT.PATH);
    }
  }

  const getLine = async () => {
    const lineId = state.lineId;
    const response = await LinesService.getLine(lineId);
    if (response.success) {
      setLine({ id: lineId, ...response.payload });
      return { success: true };
    }
    return { success: false };
  }

  const getTemplates = async () => {
    const lineId = state.lineId;
    const response = await TemplatesService.getTemplates(lineId);
    if (response.success) {
      const templatesNames = [...new Set(response.payload.map((template) => template.name))];
      const templates = templatesNames.map((templateName) => {
        const templatesByName = response.payload.filter((template) => template.name == templateName);
        const category = templatesByName[0].category;
        const translations = templatesByName.map(({ language }) => language);
        return { name: templateName, category, translations };
      });
      setTemplates(templates);
      return { success: true };
    }
    return { success: false };
  }

  const handleGeneralTemplateDataFormChange = (event) => {
    setGeneralData(event);
    setState({ ...state, generalData: event.generalData });
  }

  const handleTemplateHeaderStructureFormChange = (event) => {
    setHeaderStructure(event);
    setState({ ...state, header: event.header });
  }

  const handleTemplateBodyStructureFormChange = (event) => {
    setBodyStructure(event);
    setState({ ...state, body: event.body });
  }

  const handleTemplateFooterStructureFormChange = (event) => {
    setFooterStructure(event);
    setState({ ...state, footer: event.footer });
  }

  const handleTemplateButtonsStructureFormChange = (event) => {
    setButtonsStructure(event);
    setState({ ...state, buttons: event.buttons });
  }

  const handleNextButtonClick = () => {
    history.push({
      pathname: MENU.ADMIN.TEMPLATES.TEMPLATE_PREVIEW.PATH,
      state: {
        line: line,
        template: {
          ...generalData.generalData,
          structure: {
            header: headerStructure.header,
            body: bodyStructure.body,
            footer: footerStructure.footer,
            buttons: buttonsStructure.buttons
          }
        }
      }
    });
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              <NeoGridContainer>
                <NeoTitleSecond
                  icon={Icono}
                  title='Crear plantilla'
                  subtitle={`${line?.name} | ${line?.phone}`}
                />
              </NeoGridContainer>
              <NeoGridContainer>
                <NeoColumn col='12'>
                  <GeneralTemplateDataForm
                    templates={templates}
                    name={state.name}
                    onChange={handleGeneralTemplateDataFormChange}
                  />
                </NeoColumn>

                <NeoFlexColumn col="12" md="12" lg="6">

                  <VariablesInfo />
                </NeoFlexColumn>
                <NeoFlexColumn col="12" md="12" lg='6'>
                  <TextFormatInfo />
                </NeoFlexColumn>
                <NeoColumn lg='8' md='12' col="12">
                  <TemplateHeaderStructureForm onChange={handleTemplateHeaderStructureFormChange} />
                  <TemplateBodyStructureForm onChange={handleTemplateBodyStructureFormChange} />
                  <TemplateFooterStructureForm onChange={handleTemplateFooterStructureFormChange} />
                  <TemplateButtonsStructureForm onChange={handleTemplateButtonsStructureFormChange} />
                </NeoColumn>
                <NeoColumn lg='4' md='12' col="12">

                  <NeoCard extra="sticky">
                    <TemplatePreview
                      template={{
                        structure: {
                          header: headerStructure?.header,
                          body: bodyStructure?.body,
                          footer: footerStructure?.footer,
                          buttons: buttonsStructure?.buttons
                        }
                      }}
                    />
                  </NeoCard>
                </NeoColumn>
              </NeoGridContainer>
              <NeoButtonSection align='right'>
                <NeoButtonOutlined
                  label='Cancelar'
                  onClick={() => history.goBack()}
                />
                {
                  (generalData) &&
                  <NeoButtonMain
                    label='Siguiente'
                    disabled={!generalData?.valid || !headerStructure?.valid || !bodyStructure?.valid || !footerStructure?.valid || !buttonsStructure?.valid}
                    onClick={handleNextButtonClick}
                  />
                }
              </NeoButtonSection>
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize} />
          }
        </>
      }
    </>
  );
}