export default {
  STATUSES: {
    PENDING: 'Pendiente',
    SENDING: 'Enviando',
    CANCELLED: 'Cancelada',
    STOPPED: 'Detenida',
    FAILED: 'Fallida',
    FINISHED: 'Finalizada'
  },
  MESSAGE_ERRORS_TYPES: {
    INVALID_PHONE_NUMBER: 'Número de teléfono inválido',
    INVALID_TEMPLATE_STRUCTURE: 'Estructura de plantilla incorrecta',
    MESSAGING_ERROR: 'Error de mensajería',
    UNREGISTERED_CONTACT: 'Contacto no registrado',
    MISSING_CONTACT_FIELDS: 'Campos de contacto incompletos',
    CONTACT_QUERY_ERROR: 'Error de consulta de contacto',
    ALREADY_ANSWERED: 'Respuesta guardada a plantilla',
    INTERNAL_ERROR: 'Error interno'
  }
};