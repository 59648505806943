import NeoDialog from 'design/design_components/neo/overlay/NeoDialog.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';

export default function AlreadyExistMultimediaDialog(props) {
  const onAction = (action) => {
    props.onHide({action});
    props.visibleSetter(false);
  }

  const elements = {
    footer: (
      <>
        <NeoButtonOutlined 
          label='Cancelar' 
          onClick={() => onAction('cancel')}
        /> 
        <NeoButtonMain 
          label='Reemplazar' 
          onClick={() => onAction('replace')}
        />
      </>
    )
  };

  return (
    <NeoDialog
      header='Archivos existentes'
      footer={elements.footer}
      visible={props.visible}
      closable={false}
    >
      Hay archivos multimedia que ya existen. ¿Qué deseas hacer?
    </NeoDialog>
  );
}