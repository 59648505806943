import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';

export default function InitializationError(props) {
  const title = 'No se pudieron cargar los datos';
  const alignItems = props.align ?? 'center';

  return (
    <>
      {
        (props.internal !== true) &&
        <NeoTitleMain title={title} />
      }
      <NeoFlexContainer extra="p-flex-column p-col-12" ai="center">
        {
          (props.internal === true) &&
          <p><b>{title}</b></p>
        }
        {
          (props.onRetry && typeof props.onRetry == 'function') &&
          <NeoButtonOutlined label='Intentar de nuevo' onClick={() => props.onRetry()} />
        }
      </NeoFlexContainer>
    </>
  );
}