import {useState} from 'react';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import InfoTooltip from 'components/InfoTooltip.component';
import ActionDialog from 'components/ActionDialog.component';
import WebhookService from 'services/webhook.service';

export default function WebhookSettingsDialog(props) {
  const [urlBackup, setUrlBackup] = useState(props.url ?? '');
  const [url, setUrl] = useState(props.url ?? '');
  const [urlError, setUrlError] = useState('');

  const resetForm = () => {
    setUrlError('');
  }

  const handleAccept = async () => {
    const response = await WebhookService.updateWebhookUrl(url);
    if(response.success) {
      setUrlBackup(url);
      return {success: true};
    }
    else if(response.error.code === 'INVALID_URL') {
      setUrlError('Dirección url inválida.');
      return {success: false, internal: false};
    }
    setUrl(urlBackup);
    return {success: false, internal: true};
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    resetForm();
  }

  const handleUrlInputChange = (event) => {
    setUrl(event.value ?? event.target.value);
  }

  return (
    <ActionDialog
      header='Configurar webhook'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Actualizando webhook'
      acceptDisabled={url === urlBackup || url.length === 0}
      onAccept={handleAccept}
      onCancel={() => setUrl(urlBackup)}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      <NeoInputText
        label='Dirección url' 
        rightIcon={<InfoTooltip id='webhookUrl' body='Para comenzar a recibir notificaciones, ingresa la URL del webhook.'/>}
        keyfilter={/^[^ ]+$/}
        value={url} 
        error={urlError} 
        onChange={handleUrlInputChange}
      />
    </ActionDialog>
  );
}