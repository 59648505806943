import {Switch, Route} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import AUTHENTICATION from 'consts/authentication.consts';
import useAuthentication from 'hooks/useAuthentication.hook';
import RouterContainer from 'routers/components/RouterContainer.component';
import GeneralDashboard from 'views/dashboard/GeneralDashboard.view';
import CampaignsDashboard from 'views/dashboard/CampaignsDashboard.view';
import TemplatesList from 'views/templates/TemplatesList.view';
import CampaignDetail from 'views/dashboard/CampaignDetail.view';
import ApiSettings from 'views/apiSettings/ApiSettings.view';
import ApiKeyDetail from 'views/apiSettings/ApiKeyDetail.view';
import CreateTemplate from 'views/templates/CreateTemplate.view';
import TemplatePreview from 'views/templates/TemplatePreview.view';
import TemplateDetail from 'views/templates/TemplateDetail.view';
import Campaigns from 'views/campaigns/Campaigns.view';
import CreateCampaign from 'views/campaigns/CreateCampaign.view';
import CampaignFilesUpload from 'views/campaigns/CampaignFilesUpload.view';
import CampaignContactsSelection from 'views/campaigns/CampaignContactsSelection.view';
import CampaignPreviewAndTesting from 'views/campaigns/CampaignPreviewAndTesting.view';
import Settings from 'views/settings/Settings.view';
import Multimedia from 'views/multimedia/Multimedia.view';
import CreateMultimedia from 'views/multimedia/MultimediaFilesUpload.view';
import Contacts from 'views/contacts/Contacts.view';
import CreateContact from 'views/contacts/CreateContact.view';
import ContactDetail from 'views/contacts/ContactDetail.view';
import Lines from 'views/lines/Lines.view';
import LineProfile from 'views/lines/LineDetail.view';
import MultimediaDemo from 'views/demo/MultimediaDemo.view';

export default function AdminRouter() {
  const authentication = useAuthentication();

  const menu = [
    {
      label: MENU.ADMIN.DASHBOARD.ROOT.LABEL,
      items: [
        {
          label: MENU.ADMIN.DASHBOARD.GENERAL.LABEL,
          redirect: MENU.ADMIN.DASHBOARD.GENERAL.PATH
        },
        {
          label: MENU.ADMIN.DASHBOARD.CAMPAIGNS.LABEL,
          redirect: MENU.ADMIN.DASHBOARD.CAMPAIGNS.PATH
        }
      ]
    },
    {
      label: MENU.ADMIN.CONTACTS.ROOT.LABEL,
      redirect: MENU.ADMIN.CONTACTS.ROOT.PATH
    },
    {
      label: MENU.ADMIN.CAMPAIGNS.ROOT.LABEL,
      redirect: MENU.ADMIN.CAMPAIGNS.ROOT.PATH
    },
    {
      label: MENU.ADMIN.TEMPLATES.ROOT.LABEL,
      redirect: MENU.ADMIN.TEMPLATES.ROOT.PATH
    },
    {
      label: MENU.ADMIN.MULTIMEDIA.ROOT.LABEL,
      redirect: MENU.ADMIN.MULTIMEDIA.ROOT.PATH
    },
    {
      label: MENU.ADMIN.LINES.ROOT.LABEL,
      redirect: MENU.ADMIN.LINES.ROOT.PATH
    },
    {
      label: MENU.ADMIN.API.ROOT.LABEL,
      redirect: MENU.ADMIN.API.ROOT.PATH
    },
    {
      label: MENU.ADMIN.SETTINGS.ROOT.LABEL,
      redirect: MENU.ADMIN.SETTINGS.ROOT.PATH
    },
    {
      label: authentication.user.email,
      className: 'signout',
      command: async () => {
        await authentication.signOut();
        window.location = AUTHENTICATION.LOGOUT_URL;
      }
    }
  ];

  return (
    <RouterContainer menu={menu}>
      <Switch>
        <Route exact path={MENU.ADMIN.CAMPAIGNS.ROOT.PATH} component={Campaigns}/>
        <Route exact path={MENU.ADMIN.CAMPAIGNS.CREATE_CAMPAIGN.PATH} component={CreateCampaign}/>
        <Route exact path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_FILES_UPLOAD.PATH} component={CampaignFilesUpload}/>
        <Route exact path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS_SELECTION.PATH} component={CampaignContactsSelection}/>
        <Route exact path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_PREVIEW_AND_TESTING.PATH} component={CampaignPreviewAndTesting}/>
        <Route exact path={MENU.ADMIN.DASHBOARD.GENERAL.PATH} component={GeneralDashboard}/>
        <Route exact path={MENU.ADMIN.TEMPLATES.ROOT.PATH} component={TemplatesList}/>
        <Route exact path={MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH} component={CreateTemplate}/>
        <Route exact path={MENU.ADMIN.TEMPLATES.TEMPLATE_PREVIEW.PATH} component={TemplatePreview}/>
        <Route exact path={MENU.ADMIN.TEMPLATES.TEMPLATE_DETAIL.PATH} component={TemplateDetail}/>
        <Route exact path={MENU.ADMIN.DASHBOARD.CAMPAIGNS.PATH} component={CampaignsDashboard}/>
        <Route exact path={MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.PATH} component={CampaignDetail}/>
        <Route exact path={MENU.ADMIN.API.ROOT.PATH} component={ApiSettings}/>
        <Route exact path={MENU.ADMIN.API.API_KEY_DETAIL.PATH} component={ApiKeyDetail}/>
        <Route exact path={MENU.ADMIN.SETTINGS.ROOT.PATH} component={Settings}/>
        <Route exact path={MENU.ADMIN.MULTIMEDIA.ROOT.PATH} component={Multimedia}/>
        <Route exact path={MENU.ADMIN.MULTIMEDIA.CREATE_MULTIMEDIA.PATH} component={CreateMultimedia}/>
        <Route exact path={MENU.ADMIN.CONTACTS.ROOT.PATH} component={Contacts}/>
        <Route exact path={MENU.ADMIN.CONTACTS.CREATE_CONTACT.PATH} component={CreateContact}/>
        <Route exact path={MENU.ADMIN.CONTACTS.CONTACT_DETAIL.PATH} component={ContactDetail}/>
        <Route exact path={MENU.ADMIN.LINES.ROOT.PATH} component={Lines}/>
        <Route exact path={MENU.ADMIN.LINES.LINE_PROFILE.PATH} component={LineProfile}/>
        <Route exact path='/multimedia-demo' component={MultimediaDemo}/>
      </Switch>
    </RouterContainer>
  );
}