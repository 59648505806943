import React from 'react'
import BaseMultiSelect from '../../sisu/form/BaseMultiSelect.sisu';

const NeoMultiSelect = (props) => {
    return (


        <div className={`p-field p-col
                ${props.extra ? props.extra : ""}
                ${props.col ? `p-col-${props.col}` : ""}
               
                ${props.md ? `p-md-${props.md}` : ""}
                ${props.sm ? `p-sm-${props.sm}` : ""}`}>
            <span className="p-float-label">
                <BaseMultiSelect custom="" {...props} />
                <label htmlFor={props.id}>{props.label}</label>
                {
                    props.error
                        ? <small className="p-invalid p-d-block">
                            {props.error}
                        </small>
                        : null
                }
                {props.children}
            </span>
        </div>
    )
}

export default NeoMultiSelect;

