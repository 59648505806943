import logo from 'design/assets/img/cta/logo-bw.svg';
import NeoHeader from 'design/design_components/neo/layout/NeoHeader.base';
import NeoNavBar from 'design/design_components/neo/menu/NeoNavBar.base';
import NeoMainContainer from 'design/design_components/neo/layout/NeoMainContainer.base';

export default function RouterContainer(props) {
  return (
    <>
      <NeoHeader>
        <NeoNavBar items={props.menu} logo={logo} extra='navbar-principal blue-nav p-jc-between' />
      </NeoHeader>
      <NeoMainContainer>
        {props.children}
      </NeoMainContainer>
    </>
  );
}