import { useState, useEffect } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

export default function ContactExtraDateFieldsForm(props) {
  const [dates, setDates] = useState(Array(CONTACTS.DATA.EXTRA_DATE_FIELDS).fill(null).map((_, index) => {
    const field = `date${index + 1}`;
    if (props.contact && props.contact[field]?.length > 0) {
      const [day, month, year] = props.contact[field].split('/');
      return new Date(`${year}-${month}-${day} 00:00`);
    }
    return null;
  }));

  useEffect(() => handleChange(), [dates]);

  const handleChange = () => {
    const event = { payload: {} };
    const changes = dates.map((date, index) => {
      const field = `date${index + 1}`;
      const dateString = date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : ''
      event.payload[field] = dateString;
      return props.contact && dateString !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleDateInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedDates = [...dates];
    updatedDates[index] = value;
    setDates(updatedDates);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos de fecha</NeoInnerTitle>
      {
        dates.map((date, index) => (
          <NeoCalendar
            key={index}
            md='4'
            col="12"
            label={`Fecha ${index + 1}`}
            value={date}
            dateFormat='dd MM yy'
            monthNavigator={true}
            yearNavigator={true}
            yearRange='1970:2099'
            onChange={(event) => handleDateInputChange(index, event)}
          />
        ))
      }
    </NeoCard>
  );
}