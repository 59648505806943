import {useState, useEffect} from 'react';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import AutoReplySettingsBody from 'views/settings/components/AutoReplySettingsBody.component';
import BotService from 'services/bot.service';

export default function AutoReplySettings() {
  const messages = useMessages();
  const asyncState = useAsyncState();
  const [autoReply, setAutoReply] = useState();
  const [autoReplyTemporary, setAutoReplyTemporary] = useState();
  const [isEnabledAutoReply, setIsEnabledAutoReply] = useState();
  const [showEnableAutoReply, setShowEnableAutoReply] = useState(false);
  const [showUpdateAutoReply, setShowUpdateAutoReply] = useState(false);
  const [showDisableAutoReply, setShowDisableAutoReply] = useState(false);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getAutoReply()], 
      {initialization: true}
    );
  }

  const getAutoReply = async () => {
    const response = await BotService.getAutoReply();
    if(response.success) {
      setAutoReply(response.payload);
      setIsEnabledAutoReply(response.payload !== null);
      return {success: true};
    }
    return {success: false};
  }

  const updateAutoReply = async (autoReply) => {
    const response = await BotService.updateAutoReply(autoReply);
    return {success: response.success};
  }

  const handleStatusButtonClick = () => {
    if(isEnabledAutoReply) {
      setShowDisableAutoReply(true);
    }
    else {
      setAutoReply(autoReply === null ? '' : null);
    }
  }

  const handleSaveChangesButtonClick = () => {
    if(isEnabledAutoReply === true) {
      setShowUpdateAutoReply(true);
    }
    else {
      setShowEnableAutoReply(true);
    }
  }

  const handleDisableDialogHide = (event) => {
    if(event.action == 'accept') {
      const payload = event.payload;
      if(payload?.success === true) {
        setAutoReply(null);
        setIsEnabledAutoReply(false);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se desactivó la respuesta automática.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo desactivar la respuesta automática, inténtalo de nuevo.');
      }
    }
  }

  const handleEnableDialogHide = (event) => {
    if(event.action == 'accept') {
      const payload = event.payload;
      if(payload?.success === true) {
        setAutoReply(autoReplyTemporary.autoReply);
        setIsEnabledAutoReply(true);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se activó la respuesta automática.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo activar la respuesta automática, inténtalo de nuevo.');
      }
    }
  }

  const handleUpdateDialogHide = (event) => {
    if(event.action == 'accept') {
      const payload = event.payload;
      if(payload?.success === true) {
        setAutoReply(autoReplyTemporary.autoReply);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se actualizó la respuesta automática.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo actualizar la respuesta automática, inténtalo de nuevo.');
      }
    }
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title='Respuesta automática'/>
        <NeoButtonSection align='right'>
          {
            (!asyncState.isLoading && asyncState.isInitialized) &&
            <NeoButtonMain
              label={`${autoReply === null ? 'Activar' : 'Desactivar'} respuesta automática`}
              onClick={handleStatusButtonClick}
            />
          }
        </NeoButtonSection>
      </NeoGridContainer>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner/>
        </NeoCard> 
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              <AutoReplySettingsBody
                autoReply={autoReply}
                onChange={(event) => setAutoReplyTemporary(event)}
              />
              <NeoButtonSection align='right'>
                {
                  (autoReply !== null) &&
                  <NeoButtonMain
                    label='Guardar cambios'
                    disabled={!autoReplyTemporary || !autoReplyTemporary.valid || autoReplyTemporary.autoReply === autoReply}
                    onClick={handleSaveChangesButtonClick}
                  />
                }
              </NeoButtonSection>
              {
                <ActionDialog
                  header='Desactivar respuesta automática'
                  visible={showDisableAutoReply}
                  visibleSetter={setShowDisableAutoReply}
                  acceptMessage='Desactivando respuesta automática'
                  onAccept={async () => await updateAutoReply(null)}
                  onHide={handleDisableDialogHide}
                >
                  Se desactivará la respuesta automática. ¿Deseas proceder?
                </ActionDialog>
              }
              {
                <ActionDialog
                  header='Activar respuesta automática'
                  visible={showEnableAutoReply}
                  visibleSetter={setShowEnableAutoReply}
                  acceptMessage='Activando respuesta automática'
                  onAccept={async () => await updateAutoReply(autoReplyTemporary.autoReply)}
                  onHide={handleEnableDialogHide}
                >
                  Se activará la respuesta automática. ¿Deseas proceder?
                </ActionDialog>
              }
              {
                <ActionDialog
                  header='Actualizar respuesta automática'
                  visible={showUpdateAutoReply}
                  visibleSetter={setShowUpdateAutoReply}
                  acceptMessage='Actualizando respuesta automática'
                  onAccept={async () => await updateAutoReply(autoReplyTemporary.autoReply)}
                  onHide={handleUpdateDialogHide}
                >
                  Se actualizará la respuesta automática. ¿Deseas proceder?
                </ActionDialog>
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <NeoCard>
              <DataFetchError onRetry={initialize}/>
            </NeoCard>
          }
        </> 
      }
    </>
  );
}