import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';

export default function CampaignsResults(props) {
  const [sent, setSent] = useState();
  const [reached, setReached] = useState();
  const [responses, setResponses] = useState();

  useEffect(() => reboot(), [props.data]);

  const reboot = () => {
    if(props.data) {
      const {sent, reached, responses} = props.data;
      setSent(sent.toString());
      setReached(sent ? `${reached ? (reached / sent * 100).toPrecision(3) : 0}%` : '-');
      setResponses(sent ? `${responses && reached ? (responses / reached * 100).toPrecision(3) : 0}%` : '-');
    }
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Resultados de campañas realizadas</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>
              <NeoColumn col='4'>
                <NeoDataSecond
                  label='Plantillas enviadas'
                  fact={sent}
                />
              </NeoColumn>
              <NeoColumn col='4'>
                <NeoDataSecond
                  label='Clientes alcanzados'
                  fact={reached}
                />
              </NeoColumn>
              <NeoColumn col='4'>
                <NeoDataSecond
                  label='Respuesta de contacto'
                  fact={responses}
                />
              </NeoColumn>
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry}/>
          }
        </>
      }
    </NeoCard>
  );
}