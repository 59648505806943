import { useState, useEffect } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

export default function ContactExtraFileNameFieldsForm(props) {
  const [files, setFiles] = useState(Array(CONTACTS.DATA.EXTRA_FILENAME_FIELDS).fill('').map((_, index) => {
    const field = `fileName${index + 1}`;
    return props.contact ? (props.contact[field] ?? '') : '';
  }));

  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [files]);

  const handleChange = () => {
    const event = { payload: {} };
    const changes = files.map((file, index) => {
      const field = `fileName${index + 1}`;
      event.payload[field] = file;
      return props.contact && file !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleFileInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedFiles = [...files];
    updatedFiles[index] = value;
    setFiles(updatedFiles);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos de nombre de archivo</NeoInnerTitle>
      {
        files.map((file, index) => (
          <NeoInputText
            md='4'
            col="12"
            label={`Nombre de archivo ${index + 1}`}
            value={file}
            onChange={(event) => handleFileInputChange(index, event)}
          />
        ))
      }
    </NeoCard>
  );
}