import React from "react";
import { Button } from 'primereact/button';


const BaseButtonOutlined = (props) => {
    return (<>
        <Button className={` 
            ${props.custom ? props.custom : ""}
            ${props.extra ? props.extra : ""}`}
            {...props} >

        </Button>
    </>);
};

export default BaseButtonOutlined;