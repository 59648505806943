import { useState, useEffect, useRef } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import InfoTooltip from 'components/InfoTooltip.component';
import ContactsService from 'services/contacts.service';
import dateTimeUtil from 'utils/dateTime.util';
import NeoCellDataStatus from 'design/design_components/neo/data/NeoCellDataStatus.base';

export default function CampaignContactsTable(props) {
  const tableRef = useRef();
  const [state] = useStateParams();
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isSelectedContactsLoaded, setIsSelectedContactsLoaded] = useState(false);
  const asyncState = useAsyncState({ isInitialized: true });

  useEffect(async () => await reboot(), []);
  useEffect(() => handleChange(), [selectedContacts]);
  useEffect(() => handleRequiredFieldsChange(), [props.requiredFields]);

  const reboot = async () => {
    await asyncState.allPromises(
      [getContacts()]
    );
  }

  const handleChange = () => {
    props.onChange(selectedContacts);
  }

  const handleRequiredFieldsChange = () => {
    if (isSelectedContactsLoaded) {
      const updatedSelectedContacts = selectedContacts.filter((contact) => {
        return contactIncludesRequiredFields(contact)
      });
      setSelectedContacts(updatedSelectedContacts);
    }
  }

  const getContacts = async () => {
    const response = await ContactsService.getContacts();
    if (response.success) {
      getSelectedContacts(response.payload);
      setContacts(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const getSelectedContacts = (contacts) => {
    if (state.contacts?.length > 0) {
      const sortedContacts = contacts.sort((a, b) => Intl.Collator().compare(a.id, b.id));
      const updatedSelectedContacts = state.contacts.filter((contact) => {
        const index = searchContact(sortedContacts, contact.id);
        return index !== undefined && contactIncludesRequiredFields(sortedContacts[index]);
      });
      setSelectedContacts(updatedSelectedContacts)
    }
    setIsSelectedContactsLoaded(true);
    props.onSelectedContactsLoaded(true);
  }

  const searchContact = (contacts, contactId) => {
    let i = 0;
    let j = contacts.length - 1;
    while (i < j) {
      let k = Math.floor((i + j) / 2);
      const contact = contacts[k];
      const compare = Intl.Collator().compare(contactId, contact.id);
      if (compare == 0) { return k; }
      if (compare < 0) { j = k - 1; }
      else { i = k + 1; }
    }
    const contact = contacts[i];
    if (i == j && Intl.Collator().compare(contactId, contact.id) == 0) {
      return i;
    }
  }

  const contactIncludesRequiredFields = (contactData) => {
    return props.requiredFields?.every((requiredField) => contactData[requiredField]?.length > 0) === true;
  }

  const handleTableSelectionChange = (event) => {
    const updatedSelectedContacts = event.value;
    if (updatedSelectedContacts.length < selectedContacts.length) {
      setSelectedContacts(updatedSelectedContacts);
    }
  }

  const handleTableRowSelect = (event) => {
    const contactData = event.data;
    if (contactIncludesRequiredFields(contactData)) {
      setSelectedContacts([...selectedContacts, contactData]);
    }
  }

  const handleTableAllRowsSelect = () => {
    const updatedSelectedContacts = contacts.filter((contact) => contactIncludesRequiredFields(contact));
    setSelectedContacts(updatedSelectedContacts);
  }

  const handleRowClassName = (contactData) => {
    return {
      'contacto-no-seleccionable': !contactIncludesRequiredFields(contactData)
    }
  }

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre' />
    ),
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='phone' placeholder='Buscar por teléfono' />
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    createdAtColumnBody: (data) => (
      <>{dateTimeUtil.getDateString(data.createdAt, { dateStyle: 'medium', timeStyle: 'short' })}</>
    ),
    estatusColumnBody: (data) => (
      contactIncludesRequiredFields(data)
        ? <>
          <NeoCellDataStatus status="on" extra="no-circle" label="Completo" />
        </>
        : <>
          <NeoCellDataStatus status="off" extra="no-circle" label="Incompleto" />
          <InfoTooltip id={`contact-${data.id}`} body='Este contacto no cuenta con los campos necesarios.' />
        </>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <InternalSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                extra='with-footer p-mb-3'
                ref={tableRef}
                dataKey='id'
                selectionMode='single'
                value={contacts}
                selection={selectedContacts}
                paginator
                rows={10}
                removableSort
                sortField='createdAt'
                sortOrder={-1}
                emptyMessage='No hay contactos'
                rowClassName={handleRowClassName}
                onRowSelect={handleTableRowSelect}
                onAllRowsSelect={handleTableAllRowsSelect}
                onSelectionChange={handleTableSelectionChange}
                footer={`${selectedContacts.length} contacto${selectedContacts.length != 1 ? 's' : ''} seleccionado${selectedContacts.length != 1 ? 's' : ''}`}
              >
                <NeoTableColumn
                  selectionMode='multiple'
                  style={{ width: '3em' }}
                />
                <NeoTableColumn
                  field='name'
                  header='Nombre'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.nameFilterInput}
                />
                <NeoTableColumn
                  field='phone'
                  header='Teléfono'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.phoneFilterInput}
                />
                <NeoTableColumn
                  field='createdAt'
                  header='Fecha de creación'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.createdAtFilterRange}
                  filterFunction={dateTimeUtil.filterDate}
                  body={elements.createdAtColumnBody}
                />
                <NeoTableColumn
                  body={elements.estatusColumnBody}
                />
              </NeoTable>
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <DataFetchError internal align='start' onRetry={reboot} />
          }
        </>
      }
    </>
  );
}