import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class ApiKeysService {

  static async getApiKeys() {
    const params = {limit: 1000000};
    const {success, body, error} = await backendRequestUtil.get('/rest/companyCredentials', {params});
    if(success) {
      const credentials = body.credentials.map((credential) => {
        const id = credential.id;
        const name = credential.name;
        const createdAt = dateTimeUtil.backendDateStringToDate(credential.createdAt);
        const enabled = credential.enabled;
        return {id, name, createdAt, enabled};
      });
      return {success, payload: {credentials}};
    }
    return {success, error};
  }

  static async getApiKey(apiKeyId) {
    const params = {credentialId: apiKeyId};
    const {success, body, error} = await backendRequestUtil.get('/rest/companyCredential', {params});
    if(success) {
      body.createdAt = dateTimeUtil.backendDateStringToDate(body.createdAt);
      return {success, payload: body};
    }
    return {success, error};
  }

  static async createApiKey(apiKey) {
    const requestBody = {name: apiKey.name};
    const {success, error} = await backendRequestUtil.post('/rest/companyCredential', requestBody);
    if(!success && !error.internal) {
      if(/^[^]+ ya existe$/.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
    }
    return success ? {success} : {success, error};
  }

  static async updateApiKey(apiKeyId, apiKeyFields) {
    const params = {credentialId: apiKeyId};
    const {success, error} = await backendRequestUtil.put('/rest/companyCredential', apiKeyFields, {params});
    if(!success && !error.internal) {
      if(/^[^]+ ya existe$/.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
    }
    return success ? {success} : {success, error};
  }

  static async deleteApiKey(apiKeyId) {
    const params = {credentialId: apiKeyId};
    const {success, error} = await backendRequestUtil.delete('/rest/companyCredential', {params});
    return success ? {success} : {success, error};
  }

}