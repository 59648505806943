import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoInnerSubtitle from "design/design_components/neo/title/NeoInnerSubtitle.base";
import templatesUtil from 'utils/templates.util';

export default function TemplateStructureVariablesForm(props) {
  const [headerType, setHeaderType] = useState();
  const [headerParam, setHeaderParam] = useState();
  const [bodyParams, setBodyParams] = useState([]);

  useEffect(() => reboot(), [props.structure]);

  const reboot = () => {
    getHeaderParam();
    getBodyParams();
  }

  const getHeaderParam = () => {
    let paramObject = undefined;
    const type = props.structure?.header?.type;
    if (type == 'TEXT') {
      const [paramName] = props.structure.header.text.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
      if (paramName) {
        paramObject = { name: paramName, value: '' };
      }
    }
    else if (type) {
      paramObject = { name: 'url', value: '' };
    }
    setHeaderType(type);
    setHeaderParam(paramObject);
  }

  const getBodyParams = () => {
    const paramsNames = props.structure?.body?.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
    const paramsObjects = paramsNames.map((paramName) => ({ name: paramName, value: '' }));
    setBodyParams(paramsObjects);
  }

  const handleHeaderParamChange = (event) => {
    const updatedHeaderParam = { ...headerParam };
    const value = event.value ?? event.target.value;
    updatedHeaderParam.value = value;
    setHeaderParam(updatedHeaderParam);
    props.onChange({ header: updatedHeaderParam });
  }

  const handleBodyParamChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedBodyParams = [...bodyParams];
    updatedBodyParams[index].value = value;
    setBodyParams(updatedBodyParams);
    props.onChange({ body: updatedBodyParams });
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra="primero">Variables para previsualización</NeoInnerTitle>
      {
        (!headerParam && bodyParams.length == 0) &&
        <NeoInnerSubtitle>La plantilla no tiene variables.</NeoInnerSubtitle>
      }
      {
        (headerParam) &&
        <>
          <NeoInnerSubtitle>Encabezado</NeoInnerSubtitle>
          <NeoInputText
            label={headerType == 'TEXT' ? headerParam.name : `Url de ${templatesUtil.getTemplateHeaderType(headerType)}`}
            value={headerParam.value}
            onChange={handleHeaderParamChange}
          />
        </>
      }
      {
        (bodyParams.length > 0) &&
        <>
          <NeoInnerSubtitle>Cuerpo</NeoInnerSubtitle>
          {
            bodyParams.map((bodyParam, index) => (
              <NeoInputText
                col="12"
                key={index}
                label={bodyParam.name}
                value={bodyParam.value}
                onChange={(event) => handleBodyParamChange(index, event)}
              />
            ))
          }
        </>
      }
    </NeoCard>
  );
}