import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoPieChartCustom from 'design/design_components/neo/chart/NeoPieChartCustom.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';

const colors = {
  byName: ['green', 'blue', 'yellow', 'gray'],
  byCode: ['#49ad48', '#45ABEC', '#FDE432', '#BAC3C3']
};

export default function CampaignsMessagesStatus(props) {
  const [data, setData] = useState();
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(async () => await reboot(), [props.data]);

  const reboot = async () => {
    if (props.data) {
      const { pending, sent, delivered, read } = props.data;
      setIsEmpty(pending + sent + delivered + read === 0);
      setData([
        { name: 'Leídos', messages: read, messagestatus: 'read' },
        { name: 'Enviados y recibidos', messages: delivered, messagestatus: 'delivered' },
        { name: 'Enviados y no recibidos', messages: sent, messagestatus: 'sent' },
        { name: 'Por enviar', messages: pending, messagestatus: 'pending' }
      ]);
    }
  }

  return (
    <NeoCard extra='p-mb-0'>
      <NeoInnerTitle extra='primero'>Estatus de mensajes de campañas</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner />
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>
              {
                (isEmpty) &&
                <NeoFlexContainer>No hay datos</NeoFlexContainer>
              }
              {
                (!isEmpty) &&
                <NeoPieChartCustom
                  data={data}
                  colors={colors}
                  dataKey='messages'
                  dataName='name'
                  dataStatus='messagestatus'
                  colorsCharByKey='byCode'
                  colorsLabelByKey='byName'
                  formatNumber={true}
                  contractNumber={true}
                  noChartTitle={true}
                />
              }
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  );
}