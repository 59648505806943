import React from "react";
import { DataTable } from 'primereact/datatable';

const BaseTable = React.forwardRef((props, ref) => {
    return (
        <DataTable {...props} 
        className={` ${props.custom ? props.custom : ""}
                    ${props.extra ? props.extra : ""} `} 
        ref={ref} >
            {props.children}
        </DataTable>
    );
});

export default BaseTable;