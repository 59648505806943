import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInputMask from 'design/design_components/neo/form/NeoInputMask.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InfoTooltip from 'components/InfoTooltip.component';

const phonePattern = /^[+][0-9]{11,13}$/;

export default function BasicContactDataForm(props) {
  const [phone, setPhone] = useState(props.contact?.phone ?? '');
  const [firstName, setFirstName] = useState(props.contact?.firstName ?? props.contact?.name ?? '');
  const [lastName, setLastName] = useState(props.contact?.lastName ?? '');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [phone, firstName, lastName]);
  useEffect(() => handlePhoneErrorCodeChange(), [props.phoneErrorCode]);

  const handleChange = () => {
    const event = { valid: true, payload: { phone, firstName, lastName } };
    event.valid = phone.length > 0 && phonePattern.test(phone) && firstName.length > 0;
    if (props.contact) {
      event.change = (firstName !== props.contact.firstName && firstName !== props.contact.name)
        || lastName !== props.contact.lastName;
    }
    props.onChange(event);
  }

  const handlePhoneErrorCodeChange = () => {
    if (props.phoneErrorCode === null) {
      setPhoneError('');
    }
    else if (props.phoneErrorCode === 'INVALID_PHONE_NUMBER') {
      setPhoneError('Número de teléfono inválido.');
    }
    else if (props.phoneErrorCode === 'ALREADY_EXIST') {
      setPhoneError('El número de teléfono ya se encuentra asociado a un contacto.');
    }
  }

  const handlePhoneInputChange = (event) => {
    const value = event.value ?? event.target.value;
    setPhone(value);
  }

  const handleFirstNameInputChange = (event) => {
    const value = event.value ?? event.target.value;
    setFirstName(value);
  }

  const handleLastNameInputChange = (event) => {
    const value = event.value ?? event.target.value;
    setLastName(value);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Información básica</NeoInnerTitle>
      <NeoInputMask
        md='4' col="12"
        label='Número de teléfono'
        rightIcon={props.contact === undefined ? <InfoTooltip id='phone' body='El teléfono debe incluir el código de país.' /> : <></>}
        slotChar=''
        mask='+99999999999?99'
        value={phone}
        error={phoneError}
        disabled={props.contact !== undefined}
        onChange={handlePhoneInputChange}
        onFocus={() => setPhoneError('')}
      />
      <NeoInputText md='4' col="12"
        label='Nombre(s)'
        value={firstName}
        onChange={handleFirstNameInputChange}
      />
      <NeoInputText md='4' col="12"
        label='Apellido(s)'
        value={lastName}
        onChange={handleLastNameInputChange}
      />
    </NeoCard>
  );
}