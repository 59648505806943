import { useState, useEffect } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

export default function ContactExtraTokenFieldsForm(props) {
  const [tokens, setTokens] = useState(Array(CONTACTS.DATA.EXTRA_TOKEN_FIELDS).fill('').map((_, index) => {
    const field = `token${index + 1}`;
    return props.contact ? (props.contact[field] ?? '') : '';
  }));

  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [tokens]);

  const handleChange = () => {
    const event = { payload: {} };
    const changes = tokens.map((token, index) => {
      const field = `token${index + 1}`;
      event.payload[field] = token;
      return props.contact && token !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleTokenInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedTokens = [...tokens];
    updatedTokens[index] = value;
    setTokens(updatedTokens);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos de token</NeoInnerTitle>
      {
        tokens.map((token, index) => (
          <NeoInputText
            md='4'
            col="12"
            label={`Token ${index + 1}`}
            value={token}
            keyfilter={/^[^ ]+$/}
            onChange={(event) => handleTokenInputChange(index, event)}
          />
        ))
      }
    </NeoCard>
  );
}