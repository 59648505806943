import backendRequestUtil from 'utils/backendRequest.util';

export default class BotService {

  static async getAutoReply() {
    const {success, body, error} = await backendRequestUtil.get('/rest/autoReply');
    return success ? {success, payload: body.autoReply} : {success, error};
  }

  static async updateAutoReply(autoReply) {
    const requestBody = {autoReply};
    const {success, error} = await backendRequestUtil.put('/rest/autoReply', requestBody);
    return success ? {success} : {success, error};
  }

}