import BACKEND from 'consts/backend.consts';
import axios, {AxiosError} from 'axios';
import {auth} from 'config/firebase.config';

const errorHandler = (error) => {
  if(error instanceof AxiosError) {
    if(error.response?.status == 400 && error.response?.data?.message) {
      return {success: false, error: {internal: false, message: error.response?.data?.message}};
    }
  }
  return {success: false, error: {internal: true}};
}

async function getRequest(route, config = {}) {
  try {
    const authorization = await auth.currentUser.getIdToken();
    config.headers = {...config.headers, authorization};
    const {data, headers} = await axios.get(`${BACKEND.BASE_URL}${route}`, config);
    return {success: true, body: data, headers};
  }
  catch(error) {
    return errorHandler(error);
  }
}

async function postRequest(route, body = undefined, config = {}) {
  try {
    const authorization = await auth.currentUser.getIdToken();
    config.headers = {...config.headers, authorization};
    const {data, headers} = await axios.post(`${BACKEND.BASE_URL}${route}`, body, config);
    return {success: true, body: data, headers};
  }
  catch(error) {
    return errorHandler(error);
  }
}

async function putRequest(route, body = undefined, config = {}) {
  try {
    const authorization = await auth.currentUser.getIdToken();
    config.headers = {...config.headers, authorization};
    const {data, headers} = await axios.put(`${BACKEND.BASE_URL}${route}`, body, config);
    return {success: true, body: data, headers};
  }
  catch(error) {
    return errorHandler(error);
  }
}

async function deleteRequest(route, config = {}) {
  try {
    const authorization = await auth.currentUser.getIdToken();
    config.headers = {...config.headers, authorization};
    const {data, headers} = await axios.delete(`${BACKEND.BASE_URL}${route}`, config);
    return {success: true, body: data, headers};
  }
  catch(error) {
    return errorHandler(error);
  }
}

export default {
  get: getRequest, 
  post: postRequest, 
  put: putRequest, 
  delete: deleteRequest
};