import { useState, useEffect } from 'react';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import LINES from 'consts/lines.consts';
import TOAST from 'consts/toast.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import LoadingDialog from 'components/LoadingDialog.component';
import LinePhoto from 'views/lines/components/LinePhoto.component'
import LinesService from 'services/lines.service';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import InfoTooltip from 'components/InfoTooltip.component';

const verticalOptions =
  Object.entries(LINES.VERTICAL)
    .map(([value, label]) => [label, value])
    .sort(Intl.Collator().compare)
    .map(([label, value]) => ({ label, value }));

export default function LineProfileForm(props) {
  const messages = useMessages();
  const asyncState = useAsyncState({ isInitialized: true });
  const [photoUrl, setPhotoUrl] = useState();
  const [photoFile, setPhotoFile] = useState();
  const [photoUrlBackup, setPhotoUrlBackup] = useState();
  const [info, setInfo] = useState();
  const [profile, setProfile] = useState();
  const [name, setName] = useState(props.line.name);
  const [information, setInformation] = useState();
  const [vertical, setVertical] = useState();
  const [description, setDescription] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [website1, setWebsite1] = useState();
  const [website2, setWebsite2] = useState();
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNameChange, setIsNameChange] = useState(false);
  const [isInfoChange, setIsInfoChange] = useState(false);
  const [isInfoValid, setIsInfoValid] = useState(false);
  const [isProfileChange, setIsProfileChange] = useState(false);
  const [showUpdatingDialog, setShowUpdatingDialog] = useState(false);

  useEffect(async () => await reboot(), []);
  useEffect(() => handleNameChange(), [name, props.line]);
  useEffect(() => handleInfoChange(), [info, information]);
  useEffect(() => handleProfileChange(), [profile, vertical, description, address, email, website1, website2]);

  const reboot = async () => {
    await asyncState.allPromises(
      [
        getLinePhoto(),
        getLineInfo(),
        getLineProfile()
      ]
    );
  }

  const handleNameChange = () => {
    setIsNameValid(name?.length > 0);
    setIsNameChange(props.line.name !== name);
  }

  const handleInfoChange = () => {
    setIsInfoValid(information?.length > 0);
    setIsInfoChange(information !== info);
  }

  const handleProfileChange = () => {
    setIsProfileChange(
      (profile?.vertical !== vertical) ||
      (profile?.description ?? '') !== description ||
      (profile?.address ?? '') !== address ||
      (profile?.email ?? '') !== email ||
      (profile?.websites ? profile.websites[0] ?? '' : '') !== website1 ||
      (profile?.websites ? profile.websites[1] ?? '' : '') !== website2
    );
  }

  const getLinePhoto = async () => {
    const response = await LinesService.getLinePhoto(props.line.id);
    if (response.success) {
      const { mimeType, buffer } = response.payload;
      const blob = new Blob([buffer], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      setPhotoUrl(url);
      setPhotoUrlBackup(url);
    }
    return { success: true };
  }

  const getLineInfo = async () => {
    const response = await LinesService.getLineInfo(props.line.id);
    if (response.success) {
      setInfo(response.payload);
      setInformation(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const getLineProfile = async () => {
    const response = await LinesService.getLineProfile(props.line.id);
    if (response.success) {
      setProfile(response.payload);
      resetProfileForm(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const updateLine = async () => {
    const response = await LinesService.updateLine(props.line.id, { name });
    if (response.success) {
      props.onChange({ name });
    }
    return response.success;
  }

  const updateLineInfo = async () => {
    const response = await LinesService.updateLineInfo(props.line.id, information);
    if (response.success) {
      setInfo(information);
    }
    return response.success;
  }

  const updateLineProfile = async () => {
    const updatedProfile = {
      description: description.length > 0 ? description : null,
      address: address.length > 0 ? address : null,
      email: email.length > 0 ? email : null
    };
    const websites = [];
    if (website1.length > 0) {
      websites.push(website1);
    }
    if (website2.length > 0) {
      websites.push(website2);
    }
    updatedProfile.websites = websites.length > 0 ? websites : null;
    if (profile?.vertical !== vertical) {
      updatedProfile.vertical = vertical;
    }
    const response = await LinesService.updateLineProfile(props.line.id, updatedProfile);
    if (response.success) {
      setProfile({ ...profile, ...updatedProfile });
    }
    return response.success;
  }

  const updateLinePhoto = async () => {
    const response = await LinesService.updateLinePhoto(props.line.id, photoFile);
    if (response.success) {
      const url = window.URL.createObjectURL(photoFile);
      setPhotoUrl(url);
      setPhotoUrlBackup(url);
      setPhotoFile(undefined);
    }
    return response.success;
  }

  const resetForm = () => {
    setName(props.line.name);
    setInformation(info);
    setPhotoUrl(photoUrlBackup);
    setPhotoFile(undefined);
    resetProfileForm();
  }

  const resetProfileForm = (profilePayload) => {
    const payload = profilePayload ?? profile;
    setVertical(payload.vertical);
    setDescription(payload.description ?? '');
    setAddress(payload.address ?? '');
    setEmail(payload.email ?? '');
    setWebsite1(payload.websites ? payload.websites[0] ?? '' : '');
    setWebsite2(payload.websites ? payload.websites[1] ?? '' : '');
  }

  const handleInputChange = (event, setter) => {
    const value = event.value ?? event.target.value;
    setter(value);
  }

  const handlePhotoChange = (event) => {
    const url = window.URL.createObjectURL(event);
    setPhotoFile(event);
    setPhotoUrl(url);
  }

  const handleSaveButtonClick = async () => {
    setShowUpdatingDialog(true);
    const promises = [];
    if (photoFile !== undefined) {
      promises.push(updateLinePhoto());
    }
    if (isNameChange === true) {
      promises.push(updateLine());
    }
    if (isInfoChange === true) {
      promises.push(updateLineInfo());
    }
    if (isProfileChange === true) {
      promises.push(updateLineProfile());
    }
    const responses = await Promise.allSettled(promises);
    setShowUpdatingDialog(false);
    const success = responses.every((response) => response.status == 'fulfilled' && response.value === true);
    if (success === true) {
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se actualizó la información de la línea.'
      );
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo actualizar completamente la información de la línea, inténtalo de nuevo.'
      );
    }
  }

  return (
    <>
      <NeoColumn md="7" col="12" extra="p-mx-auto">
        <NeoCard>
          <NeoInnerTitle extra='primero'>Información general</NeoInnerTitle>
          <NeoInputText
            label='Nombre a usar dentro de Neoconecta'
            col="12"
            value={name}
            rightIcon={<InfoTooltip body='Este nombre no es visible para los contactos, sirve solo como identificador interno en la plataforma.' />}
            onChange={(event) => handleInputChange(event, setName)}
          />
        </NeoCard>
        <NeoCard>
          <NeoInnerTitle extra='primero'>Perfil de WhatsApp</NeoInnerTitle>
          {
            (asyncState.isLoading) &&
            <InternalSpinner />
          }
          {
            (!asyncState.isLoading) &&
            <>
              {
                (asyncState.isSuccessfully) &&
                <>
                  <LinePhoto
                    url={photoUrl}
                    onChange={handlePhotoChange}
                  />
                  <NeoInputText
                    col="12"
                    label='Número de teléfono'
                    value={props.line.phone}
                    disabled
                  />
                  <NeoInputText
                    col="12"
                    label='Información'
                    maxlength={139}
                    value={information}
                    onChange={(event) => handleInputChange(event, setInformation)}
                  />
                  <NeoDropdown
                    col="12"
                    label='Sector'
                    value={vertical}
                    options={verticalOptions}
                    onChange={(event) => handleInputChange(event, setVertical)}
                  />
                  <NeoInputText
                    col="12"
                    label='Descripción'
                    maxlength={256}
                    value={description}
                    onChange={(event) => handleInputChange(event, setDescription)}
                  />
                  <NeoInputText
                    label='Dirección'
                    maxlength={256}
                    value={address}
                    onChange={(event) => handleInputChange(event, setAddress)}
                  />
                  <NeoInputText
                    col="12"
                    label='Correo electrónico'
                    maxlength={128}
                    value={email}
                    onChange={(event) => handleInputChange(event, setEmail)}
                  />
                  <NeoInputText
                    col="12"
                    label='Sitio web 1'
                    maxlength={256}
                    value={website1}
                    onChange={(event) => handleInputChange(event, setWebsite1)}
                  />
                  <NeoInputText
                    col="12"
                    label='Sitio web 2'
                    maxlength={256}
                    value={website2}
                    onChange={(event) => handleInputChange(event, setWebsite2)}
                  />
                  {
                    <LoadingDialog
                      visible={showUpdatingDialog}
                      message='Actualizando línea'
                    />
                  }
                </>
              }
              {
                (!asyncState.isSuccessfully) &&
                <DataFetchError internal align='start' onRetry={reboot} />
              }
            </>
          }
        </NeoCard>
      </NeoColumn>
      {
        (!asyncState.isLoading) &&
        <NeoButtonSection align='right'>
          <NeoButtonOutlined
            label='Cancelar'
            onClick={resetForm}
          />
          <NeoButtonMain
            label='Guardar cambios'
            disabled={(!isNameChange && !isInfoChange && !isProfileChange && photoFile === undefined) || !isNameValid || !isInfoValid}
            onClick={handleSaveButtonClick}
          />
        </NeoButtonSection>
      }
    </>
  );
}