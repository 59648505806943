import { useState, useEffect } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useQueryParams from 'hooks/useQueryParams.hook';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoMultiSelect from 'design/design_components/neo/form/NeoMultiSelect.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import DataFetchError from 'components/DataFetchError.component';
import PeriodFilterDialog from 'views/dashboard/components/PeriodFilterDialog.component';
import LinesService from 'services/lines.service';
import dateTimeUtil from 'utils/dateTime.util';
import Icono from 'design/assets/img/cta/icons/icon-statistics.svg';

export default function DashboardFilters2(props) {
  const asyncState = useAsyncState();
  const [queryParams, setQueryParams] = useQueryParams();
  const [lines, setLines] = useState([]);
  const [selectedLines, setSelectedLines] = useState();
  const [selectedDateFilter, setSelectedDateFilter] = useState();
  const [showDateFilterDialog, setShowDateFilterDialog] = useState(false);

  useEffect(async () => await initialize(), [queryParams]);
  useEffect(() => handleFilterChange(), [selectedLines, selectedDateFilter]);

  const initialize = async () => {
    if (!asyncState.isInitialized && queryParams) {
      const allPromises = await asyncState.allPromises(
        [getLines()],
        { initialization: true }
      );
      if (allPromises.fulfilled && allPromises.successfully) {
        const lines = allPromises.results[0].payload;
        const selectedLines = getSelectedLines(lines);
        const selectedDateFilter = getSelectedDateFilter();
        setSelectedLines(selectedLines);
        setSelectedDateFilter(selectedDateFilter);
      }
    }
  }

  const handleFilterChange = () => {
    if (selectedLines && selectedDateFilter && selectedDateFilter.period) {
      const selectedLinesQuery = getSelectedLinesQueryParam();
      const selectedDateFilterQuery = getSelectedDateFilterQueryParam();
      setQueryParams({ lines: selectedLinesQuery, ...selectedDateFilterQuery });
      props.onChange({ lines: selectedLines, period: selectedDateFilter.period });
    }
  }

  const getSelectedLinesQueryParam = () => {
    return selectedLines.map((selectedLine) => selectedLine.id).join(',');
  }

  const getSelectedDateFilterQueryParam = () => {
    const payload = { periodType: selectedDateFilter.type.toLowerCase() };
    if (selectedDateFilter.type == 'MONTH') {
      const { month } = selectedDateFilter.payload;
      payload.month = `${month.getMonth() + 1}-${month.getFullYear()}`;
    }
    if (selectedDateFilter.type == 'YEAR') {
      const { year } = selectedDateFilter.payload;
      payload.year = year;
    }
    if (selectedDateFilter.type == 'CUSTOM') {
      const { from, to } = selectedDateFilter.payload;
      payload.from = `${from.getDate()}-${from.getMonth() + 1}-${from.getFullYear()}`;
      payload.to = `${to.getDate()}-${to.getMonth() + 1}-${to.getFullYear()}`;
    }
    return payload;
  }

  const getSelectedLines = (lines) => {
    const pattern = /^[0-9]+([,][0-9]+)*$/;
    if (queryParams?.lines && pattern.test(queryParams.lines)) {
      const linesId = queryParams.lines.split(',');
      const existingLinesId = lines.map((line) => line.id);
      const foundLines = [];
      for (const lineId of linesId) {
        const index = existingLinesId.indexOf(lineId);
        if (index >= 0) { foundLines.push(lines[index]); }
      }
      if (foundLines.length > 0) {
        return foundLines;
      }
    }
    return lines;
  }

  const getSelectedDateFilter = () => {
    if (queryParams?.periodType == 'month') {
      const pattern = /^[0-9]{1,2}-[0-9]{4}$/;
      if (queryParams?.month && pattern.test(queryParams.month)) {
        const [month, year] = queryParams.month.split('-').map((x) => Number.parseInt(x));
        if (year >= 2022 && year <= dateTimeUtil.getCurrentYear()) {
          return { type: 'MONTH', payload: { month: dateTimeUtil.getMonthDate(month, year) } };
        }
      }
    }
    if (queryParams?.periodType == 'year') {
      const pattern = /^[0-9]{4}$/;
      if (queryParams?.year && pattern.test(queryParams.year)) {
        const year = Number.parseInt(queryParams.year);
        if (year >= 2022 && year <= dateTimeUtil.getCurrentYear()) {
          return { type: 'YEAR', payload: { year } };
        }
      }
    }
    if (queryParams?.periodType == 'custom') {
      const pattern = /^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/;
      if (queryParams?.from && queryParams?.to && pattern.test(queryParams.from) && pattern.test(queryParams.to)) {
        const [fromDay, fromMonth, fromYear] = queryParams.from.split('-').map((x) => Number.parseInt(x));
        const [toDay, toMonth, toYear] = queryParams.to.split('-').map((x) => Number.parseInt(x));
        const currentYear = dateTimeUtil.getCurrentYear();
        if (fromYear >= 2022 && toYear >= 2022 && fromYear <= currentYear && toYear <= currentYear) {
          const from = new Date(`${fromYear}-${fromMonth}-${fromDay} 00:00`);
          const to = new Date(`${toYear}-${toMonth}-${toDay} 00:00`);
          if (from <= to) {
            return { type: 'CUSTOM', payload: { from, to } };
          }
        }
      }
    }
    return { type: 'MONTH', payload: { month: dateTimeUtil.getMonthDate() } };
  }

  const getLines = async () => {
    const response = await LinesService.getLines();
    if (response.success) {
      const payload = response.payload.map((line) => {
        return { id: line.id, name: line.name, phone: line.phone };
      });
      setLines(payload);
      return { success: true, payload };
    }
    return { success: false };
  }

  const handleLinesMultiSelectChange = async (event) => {
    const selectedLines = event.value;
    setSelectedLines(selectedLines);
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              <NeoGridContainer>
                <NeoTitleMain
                  icon={Icono}
                  title={props.title ?? 'Estadísticas'}
                />
                <NeoButtonSection align='right'>
                  <NeoMultiSelect
                    label='Líneas'
                    value={selectedLines}
                    maxSelectedLabels={1}
                    selectedItemsLabel='{0} líneas seleccionadas'
                    options={lines.map((line) => ({ value: line, label: `${line.name} | ${line.phone}` }))}
                    onChange={handleLinesMultiSelectChange}
                  />
                  <NeoInputText
                    label='Periodo'
                    value={selectedDateFilter?.label}
                    onClick={() => setShowDateFilterDialog(true)}
                  />
                </NeoButtonSection>
              </NeoGridContainer>
              {
                <PeriodFilterDialog
                  visible={showDateFilterDialog}
                  visibleSetter={setShowDateFilterDialog}
                  value={selectedDateFilter}
                  onChange={(event) => setSelectedDateFilter(event)}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize} />
          }
        </>
      }
    </>
  );
}