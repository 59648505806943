import {Switch, Route} from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication.hook';
import MENU from 'consts/menu.consts';
import RouterContainer from 'routers/components/RouterContainer.component';
import Login from 'views/authentication/Login.view';
import LoginSSO from 'views/authentication/LoginSSO.view';

export default function UnauthenticatedRouter() {
  const authentication = useAuthentication();

  const menu = [
    {
      label: MENU.UNAUTHENTICATED.LOGIN.ROOT.LABEL,
      redirect: MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH
    }
  ];

  return (
    <RouterContainer menu={menu}>
      <Switch>
        <Route exact path={MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH} component={Login}/>
        <Route exact path={MENU.UNAUTHENTICATED.LOGIN.SSO.PATH} component={LoginSSO}/>
      </Switch>
    </RouterContainer>
  );
}