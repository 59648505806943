import React, { useState } from 'react';
import NeoCalendar from '../form/NeoCalendar.base';

const NeoDateRangeFilter = (props, ref) => {

  const [dateSelected, setDateSelected] = useState(null);


  function onDateSelectedChanges( e ){
    setDateSelected( e.value )
    ref.current.filter( e.value, props.field, props.matchFilter )
  }
  
  return ( 
    <NeoCalendar value={dateSelected} showButtonBar 
      icon={true} onChange={onDateSelectedChanges} 
      selectionMode="range" placeholder={props.placeholder} 
      className="p-column-filter"
      dateFormat='dd/mm/yy'
    />

  )
}

export default React.forwardRef(NeoDateRangeFilter);
