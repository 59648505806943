import CAMPAIGNS from 'consts/campaigns.consts';

function getCampaignStatus(status) {
  return CAMPAIGNS.STATUSES[status] ?? 'Desconocido';
}

function getCampaignMessageErrorType(errorType) {
  return CAMPAIGNS.MESSAGE_ERRORS_TYPES[errorType] ?? 'Error interno genérico';
}

export default {
  getCampaignStatus,
  getCampaignMessageErrorType
};