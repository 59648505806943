import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import { Chip } from 'primereact/chip';
import NeoTooltip from 'design/design_components/neo/overlay/NeoTooltip.base';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import NeoDateRangeFilterBase from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import templatesUtil from 'utils/templates.util';
import TemplatesService from 'services/templates.service';
import TEMPLATES from 'consts/templates.consts';
import dateTimeUtil from 'utils/dateTime.util';

const categoriesOptions =
  Object.entries(TEMPLATES.CATEGORIES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({ label, value }));

export default function TemplatesListTable(props) {
  const tableRef = useRef();
  const history = useHistory();
  const asyncState = useAsyncState({ isInitialized: true });
  const [templates, setTemplates] = useState([]);

  useEffect(async () => await reboot(), [props.filters]);

  const reboot = async () => {
    await asyncState.allPromises(
      [getTemplates()]
    );
  }

  const getTemplates = async () => {
    const lineId = props.filters.line.id;
    const response = await TemplatesService.getTemplates(lineId);
    if (response.success) {
      const templatesNames = [...new Set(response.payload.map((template) => template.name))];
      const templates = templatesNames.map((templateName) => {
        const templatesByName = response.payload.filter((template) => template.name == templateName);
        const category = templatesByName[0].category;
        const [createdAt] = templatesByName.filter((template) => template.createdAt).map((template) => template.createdAt).sort();
        const translations = templatesByName.map(({ language, status }) => ({ language, status }));
        return { name: templateName, category, createdAt, translations };
      });
      setTemplates(templates);
      return { success: true };
    }
    return { success: false };
  }

  const handleTableRowSelect = (event) => {
    const {name} = event.data;
    const templateId = `${props.filters.line.id}-${name}`;
    history.push(`${MENU.ADMIN.TEMPLATES.TEMPLATE_DETAIL.BASE}/${templateId}`);
  };

  const createdAtSortFunction = (event) => {
    return templates.sort((template1, template2) => {
      const createdAt1 = template1.createdAt ?? new Date(0);
      const createdAt2 = template2.createdAt ?? new Date(0);
      return (createdAt1 - createdAt2) * event.order;
    });
  }

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre' />
    ),
    categoryFilterMultiSelect: (
      <NeoMultiSelectFilter ref={tableRef} options={categoriesOptions} field='category' placeholder='Todos' />
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilterBase ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    nameColumnBody: (data) => (
      <b>{data.name}</b>
    ),
    categoryColumnBody: (data) => (
      <>{templatesUtil.getTemplateCategory(data.category)}</>
    ),
    createdAtColumnBody: (data) => (
      <>
        {
          (!data.createdAt)
            ? '-'
            : dateTimeUtil.getDateString(data.createdAt, {dateStyle: 'medium', timeStyle: 'short'})
        }
      </>
    ),
    translationsColumnBody: (data) => (
      <div>
        {
          data.translations.map((translation, index) => (
            <>
              <Chip
                key={index}
                className={`fi fi-${translation.language} fis ${translation.status} ${data.name}-${translation.language}`}
                label={translation.language}
              />
              <NeoTooltip
                target={`.${data.name}-${translation.language}`}
                position='top'
                mouseTrack={true}
                mouseTrackTop={10}
              >
                <div><b>{TEMPLATES.STATUSES[translation.status]}</b></div>
                <div><i>{TEMPLATES.LANGUAGES[translation.language]}</i></div>
              </NeoTooltip>
            </>
          ))
        }
      </div>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner />
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <NeoTable
              ref={tableRef}
              value={templates}
              selectionMode='single'
              paginator
              removableSort
              rows={10}
              sortField='createdAt'
              sortOrder={-1}
              emptyMessage='No hay plantillas'
              onRowSelect={handleTableRowSelect}
            >
              <NeoTableColumn
                header='Nombre'
                field='name'
                filter
                sortable
                filterElement={elements.nameFilterInput}
                filterMatchMode='contains'
                body={elements.nameColumnBody}
              />
              <NeoTableColumn
                header='Categoría'
                field='category'
                filter
                sortable
                filterElement={elements.categoryFilterMultiSelect}
                filterMatchMode='contains'
                body={elements.categoryColumnBody}
              />
              <NeoTableColumn
                header='Fecha de creación'
                field='createdAt'
                filter
                sortable
                sortFunction={createdAtSortFunction}
                filterElement={elements.createdAtFilterRange}
                filterFunction={dateTimeUtil.filterDate}
                body={elements.createdAtColumnBody}
              />
              <NeoTableColumn
                header='Idiomas'
                field='translations'
                body={elements.translationsColumnBody}
              />
            </NeoTable>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError internal align='start' onRetry={reboot} />
            </NeoCard>
          }
        </>
      }
    </>
  );
};