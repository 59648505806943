import {useEffect, useState} from 'react';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';
import ActionDialog from 'components/ActionDialog.component';
import dateTimeUtil from 'utils/dateTime.util';
import CampaignsService from 'services/campaigns.service';

export default function CampaignSchedulingDialog(props) {
  const [scheduledAt, setScheduledAt]  = useState();
  const [scheduledAtError, setScheduledAtError] = useState('');
  const [isValidDateTime, setIsValidDateTime] = useState(false);

  useEffect(() => {
    if(props.visible === true) {
      validateSchedulingDateTime();
      const interval = setInterval(() => validateSchedulingDateTime(), 1000);
      return () => clearInterval(interval);
    }
  }, [props.visible, scheduledAt]);

  const validateSchedulingDateTime = () => {
    if(scheduledAt) {
      if(scheduledAt > new Date(Date.now() + 60000)) {
        setScheduledAtError('');
        return setIsValidDateTime(true);
      }
      setScheduledAtError('Ingresa una fecha y hora válida.');
    }
    return setIsValidDateTime(false);
  }

  const resetForm = () => {
    setScheduledAtError('');
    setScheduledAt(undefined);
  }

  const handleAccept = async () => {
    const {year, month, day, hour, minute} = getDateTimeProps(scheduledAt);
    const dateString = `${(day[1] ? '' : '0') + day}/${(month[1] ? '' : '0') + month}/${year}`;
    const timeString = `${hour}:${(minute[1] ? '' : '0') + minute}`;
    const scheduledAtString = `${dateString} ${timeString}`;
    const campaign = {...props.campaign, scheduledAt: scheduledAtString};
    const response = await CampaignsService.createCampaign(campaign);
    return response;
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    resetForm();
  }

  const getDateTimeProps = (date) => {
    const year = (date.getFullYear()).toString();
    const month = (date.getMonth() + 1).toString();
    const day = (date.getDate()).toString();
    const hour = (date.getHours()).toString();
    const minute = (date.getMinutes()).toString();
    return {year, month, day, hour, minute};
  }

  const handleScheduledAtCalendarChange = (event) => {
    const scheduledAt = event.value;
    const {year, month, day, hour, minute} = getDateTimeProps(scheduledAt);
    setScheduledAt(new Date(`${year}-${month}-${day} ${hour}:${minute}`));
  }

  return (
    <ActionDialog
      header='Programar campaña'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Programando campaña'
      acceptDisabled={!isValidDateTime}
      onAccept={handleAccept}
      onHide={handleHide}
    >
      <NeoCalendar
        showTime
        dateFormat='dd/mm/yy'
        label='Fecha y hora de envío'
        value={scheduledAt}
        error={scheduledAtError}
        onChange={handleScheduledAtCalendarChange}
      />
      {
        (isValidDateTime) &&
        <>
          {
            `Se programará el envío de la campaña para el 
            ${dateTimeUtil.getDateString(scheduledAt, {dateStyle: 'long'})}
            a las 
            ${dateTimeUtil.getDateString(scheduledAt, {timeStyle: 'short'})}.
            ¿Deseas proceder?`
          }
        </>
      }
    </ActionDialog>
  );
}