import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import DataFetchError from 'components/DataFetchError.component';
import ResourceNotFound from 'components/ResourceNotFound.component';
import LineProfileHeader from 'views/lines/components/LineDetailHeader.component';
import LineProfileForm from 'views/lines/components/LineProfileForm.component';
import LinesService from 'services/lines.service';

export default function LineProfileView() {
  const {id} = useParams();
  const asyncState = useAsyncState();
  const [line, setLine] = useState(null);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getLine()], 
      {initialization: true}
    );
  }

  const getLine = async () => {
    const response = await LinesService.getLine(id);
    if(response.success) {
      setLine(response.payload);
      return {success: true};
    }
    if(response.error.internal) {
      return {success: false};
    }
    return {success: true};
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/> 
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (line) &&
                <>
                  <LineProfileHeader line={line}/>
                  <LineProfileForm 
                    line={{id, ...line}}
                    onChange={(event) => setLine({...line, ...event})}
                  />
                </>
              }
              {
                (!line) &&
                <ResourceNotFound 
                  title='La línea no existe'
                  redirection={{label: 'Ir a líneas', path: MENU.ADMIN.LINES.ROOT.PATH}}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize}/>
          }
        </>
      }
    </>
  );
}