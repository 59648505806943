import { useState } from 'react';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import TOAST from 'consts/toast.consts';
import MESSAGES from 'consts/messages.consts';
import useMessages from 'hooks/useMessages.hook';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import CampaignsService from 'services/campaigns.service';
import dateTimeUtil from 'utils/dateTime.util';
import csvUtil from 'utils/csv.util';
import campaignsUtil from 'utils/campaigns.util';

export default function CampaignDetailHeader(props) {
  const messages = useMessages();
  const [campaign, setCampaign] = useState(props.campaign);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showStopDialog, setShowStopDialog] = useState(false);

  const cancelCampaign = async () => {
    const { lineId, name } = campaign;
    const response = await CampaignsService.updateCampaignStatus(lineId, name, 'CANCELLED');
    if (response.success) {
      const updatedCampaign = { ...campaign, status: 'CANCELLED', cancelledAt: new Date() };
      return { success: true, updatedCampaign }
    }
    return { success: false };
  }

  const stopCampaign = async () => {
    const { lineId, name } = campaign;
    const response = await CampaignsService.updateCampaignStatus(lineId, name, 'STOPPED');
    if (response.success) {
      const updatedCampaign = { ...campaign, status: 'STOPPED', stoppedAt: new Date() };
      return { success: true, updatedCampaign }
    }
    return { success: false };
  }

  const handleDownloadQuickRepliesButtonClick = async () => {
    const columns = [
      {header: 'Teléfono', key: 'phone'},
      {header: 'Respuesta', key: 'text'},
      {header: 'Fecha y hora de envío', key: 'sentAt'}
    ];
    const rows = props.results.quickRepliesDetail;
    const {lineId, name} = props.campaign;
    const filename = `${lineId}-${name}-respuestas`;
    await csvUtil.create(columns, rows, {filename});
  }

  const handleDownloadMessagesButtonClick = async () => {
    const columns = [
      {header: 'Teléfono', key: 'phone'},
      {header: 'Estatus', key: 'status'},
      {header: 'Fecha y hora de envío', key: 'sentAt'},
      {header: 'Fecha y hora de entrega', key: 'deliveredAt'},
      {header: 'Fecha y hora de lectura', key: 'readAt'}
    ];
    const messagesDetailRows = props.results.messagesDetail.map((messageDetail) => {
      const {message} = messageDetail;
      const phone = message.phone;
      const status = MESSAGES.STATUSES[message.status];
      const sentAt = dateTimeUtil.getDateString(message.sentAt, {dateStyle: 'short', timeStyle: 'medium'})?.replace(/,/g, '');
      const deliveredAt = dateTimeUtil.getDateString(message.deliveredAt, {dateStyle: 'short', timeStyle: 'medium'})?.replace(/,/g, '');
      const readAt = dateTimeUtil.getDateString(message.readAt, {dateStyle: 'short', timeStyle: 'medium'})?.replace(/,/g, '');
      return {phone, status, sentAt, deliveredAt, readAt};
    });
    const messagesErrorsRows = props.results.messagesErrors.map((messageError) => {
      const to = messageError?.rowValues?.to ?? messageError.contactId;
      const status = campaignsUtil.getCampaignMessageErrorType(messageError.type);
      return {phone: to, status};
    });
    const rows = [...messagesDetailRows, ...messagesErrorsRows];
    const {lineId, name} = props.campaign;
    const filename = `${lineId}-${name}-mensajes`;
    await csvUtil.create(columns, rows, {filename});
  }

  const handleCancelDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        setCampaign(payload.updatedCampaign);
        props.onChange(payload.updatedCampaign);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se canceló la campaña.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo cancelar la campaña, inténtalo de nuevo.');
      }
    }
  }

  const handleStopDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        setCampaign(payload.updatedCampaign);
        props.onChange(payload.updatedCampaign);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se detuvo la campaña.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo detener la campaña, inténtalo de nuevo.');
      }
    }
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          icon={Icono}
          title={campaign.name}
          subtitle='Detalle de campaña'
        />
        <NeoButtonSection align='right' >
          {
            (campaign.status == 'PENDING') &&
            <NeoButtonMain 
              label='Cancelar campaña' 
              onClick={() => setShowCancelDialog(true)} 
            />
          }
          {
            (campaign.status == 'SENDING') &&
            <NeoButtonMain 
              label='Detener campaña' 
              onClick={() => setShowStopDialog(true)}
            />
          }
          {
            (campaign.status == 'FINISHED' && props.results) &&
            <>
              {
                (props.results.quickRepliesDetail) &&
                <NeoButtonMain 
                  icon="pi pi-download" 
                  label='Descargar detalle de respuestas' 
                  onClick={handleDownloadQuickRepliesButtonClick}
                />
              }
              <NeoButtonMain 
                icon="pi pi-download" 
                label='Descargar detalle de mensajes' 
                onClick={handleDownloadMessagesButtonClick}
              />
            </>
          }
        </NeoButtonSection>
      </NeoGridContainer>
      {
        <ActionDialog
          header='Cancelar campaña'
          visible={showCancelDialog}
          visibleSetter={setShowCancelDialog}
          acceptMessage='Cancelando campaña'
          onAccept={cancelCampaign}
          onHide={handleCancelDialogHide}
        >
          Se cancelará la campaña. ¿Deseas proceder?
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Detener campaña'
          visible={showStopDialog}
          visibleSetter={setShowStopDialog}
          acceptMessage='Deteniendo campaña'
          onAccept={stopCampaign}
          onHide={handleStopDialogHide}
        >
          Se detendrá la campaña. ¿Deseas proceder?
        </ActionDialog>
      }
    </>
  );
}