import { useState, useEffect } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

export default function ContactExtraNumberFieldsForm(props) {
  const [numbers, setNumbers] = useState(Array(CONTACTS.DATA.EXTRA_NUMBER_FIELDS).fill('').map((_, index) => {
    const field = `number${index + 1}`;
    return props.contact ? (props.contact[field] ?? '') : '';
  }));

  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [numbers]);

  const handleChange = () => {
    const event = { valid: true, payload: {} };
    const changes = numbers.map((number, index) => {
      const field = `number${index + 1}`;
      if (number.length > 0 && Number.isNaN(Number.parseFloat(number))) {
        event.valid = false;
      }
      event.payload[field] = number;
      return props.contact && number !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleNumberInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const [numberString] = value.match(/^[-]?([0-9]+)?[.]?([0-9]+)?/g) ?? [''];
    const updatedNumbers = [...numbers];
    updatedNumbers[index] = numberString;
    setNumbers(updatedNumbers);
  }

  const handleNumberInputBlur = (index) => {
    const value = numbers[index];
    const number = Number.parseFloat(value);
    const updatedNumbers = [...numbers];
    updatedNumbers[index] = !Number.isNaN(number) ? number.toString() : '';
    setNumbers(updatedNumbers);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos numéricos</NeoInnerTitle>
      {
        numbers.map((number, index) => (
          <NeoInputText
            key={index}
            md='4'
            col="12"
            label={`Número ${index + 1}`}
            value={number}
            keyfilter={/^[-0-9.]+$/}
            onChange={(event) => handleNumberInputChange(index, event)}
            onBlur={() => handleNumberInputBlur(index)}
          />
        ))
      }
    </NeoCard>
  );
}