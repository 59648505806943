import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import CampaignFilesTable from 'views/campaigns/components/CampaignFilesTable.component';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';

export default function CampaignCsvFilesUpload() {
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [state, setState] = useStateParams();

  useEffect(() => initialize(), []);

  const initialize = () => {
    if(!state.template) {
      history.push(MENU.ADMIN.CAMPAIGNS.ROOT.PATH);
    }
  }

  const handleNextButtonClick = () => {
    let allExamples = [];
    const uploadedFiles = files.map(({examples, ...file}) => {
      allExamples = [...allExamples, ...examples];
      return file;
    });
    history.push({
      pathname: MENU.ADMIN.CAMPAIGNS.CAMPAIGN_PREVIEW_AND_TESTING.PATH,
      state: {...state, type: 'FILES', files: uploadedFiles, examples: allExamples}
    })
  }

  return (
    <>
      {
        (state.template) &&
        <>
          <NeoGridContainer>
            <NeoTitleSecond title='Crear campaña' subtitle='Carga de archivos' icon={Icono} />
          </NeoGridContainer>
          <CampaignFilesTable
            template={state.template}
            onChange={(event) => setFiles(event)}
          />
          <NeoFlexContainer jc='end'>
            <NeoButtonMain
              label='Siguiente'
              disabled={files.length == 0 || files.every((file) => file.records.full == 0)}
              onClick={handleNextButtonClick}
            />
          </NeoFlexContainer>
        </>
      }
    </>
  )
}