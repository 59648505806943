import React from "react";

const BaseButtonSection = (props) => {
  return (<div className={`
    ${props.custom ? props.custom : ""}
    ${props.align ? props.align : ""} 
    ${props.extra ? props.extra : ""} 
    ${props.jc ? `p-jc-${props.jc}` : ""} 
    ${props.ai ? `p-ai-${props.ai}` : "p-ai-center"}
    `}>
    {props.children}
  </div>);
};

export default BaseButtonSection;

