import React from "react";
import { Button } from 'primereact/button';


const BaseButtonMain = (props) => {
    return (
        <Button
            className={`
            ${props.custom ? props.custom : ""}
            ${props.extra ? props.extra : ""}
            ${props.alternative
                    ? `p-button-${props.alternative}`
                    : "p-button-primary"}`}
            {...props} />
    );
};

export default BaseButtonMain;