import {useState, useEffect, useRef} from 'react';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import LoadingDialog from 'components/LoadingDialog.component';
import ActionDialog from 'components/ActionDialog.component';
import MultimediaService from 'services/multimedia.service';
import dateTimeUtil from 'utils/dateTime.util';
import fileUtil from 'utils/file.util';

export default function MultimediaList() {
  const tableRef = useRef();
  const messages = useMessages();
  const asyncState = useAsyncState({isInitialized: true});
  const [multimedia, setMultimedia] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showOpeningDialog, setShowOpeningDialog] = useState(false);
  const [selectedMultimediaId, setSelectedMultimediaId] = useState();
  let deleteMultimediaButtonClick = false;

  useEffect(async () => await reboot(), []);

  const reboot = async () => {
    await asyncState.allPromises(
      [getMultimedia()]
    );
  }

  const getMultimedia = async () => {
    const response = await MultimediaService.getMultimedia();
    if(response.success) {
      setMultimedia(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  const deleteMultimedia = async () => {
    const response = await MultimediaService.deleteMultimedia(selectedMultimediaId);
    return {success: response.success};
  }

  const handleTableRowSelect = async (event) => {
    const multimediaId = event.data.id;
    setSelectedMultimediaId(multimediaId);
    if(deleteMultimediaButtonClick) {
      setShowDeleteDialog(true);
      deleteMultimediaButtonClick = false;
    }
    else {
      setShowOpeningDialog(true);
      const response = await MultimediaService.getMultimediaUrl(event.data.id);
      setShowOpeningDialog(false);
      if(response.success) {
        window.open(response.payload, '_blank');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', `No se pudo abrir ${multimediaId}, inténtalo de nuevo.`);
      }
    }
  }

  const handleDeleteDialogHide = async (event) => {
    if(event.action == 'accept') {
      const payload = event.payload;
      if(payload?.success === true) {
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', `Se eliminó ${selectedMultimediaId}`);
        await reboot();
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', `No se pudo eliminar ${selectedMultimediaId}, inténtalo de nuevo`);
      }
    }
  }

  const elements = {
    idFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='id' placeholder='Buscar por nombre' />
    ),
    mimeTypeFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='mimeType' placeholder='Buscar por tipo' />
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom'/>
    ),
    idColumnBody: (data) => (
      <b>{data.id}</b>
    ),
    sizeColumnBody: (data) => (
      <>{fileUtil.getSizeString(data.size)}</>
    ),
    createdAtColumnBody: (data) => (
      <>{dateTimeUtil.getDateString(data.createdAt, {dateStyle: 'medium', timeStyle: 'short'})}</>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner/>
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                ref={tableRef}
                selectionMode='single'
                value={multimedia}
                paginator
                rows={10}
                removableSort
                sortField='createdAt'
                sortOrder={-1}
                emptyMessage='No hay archivos multimedia'
                onRowSelect={handleTableRowSelect}
              >
                <NeoTableColumn
                  field='id'
                  header='Nombre de multimedia'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.idFilterInput}
                  body={elements.idColumnBody}
                />
                <NeoTableColumn
                  field='size'
                  header='Tamaño'
                  sortable
                  body={elements.sizeColumnBody}
                />
                <NeoTableColumn
                  field='mimeType'
                  header='Tipo'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.mimeTypeFilterInput}
                />
                <NeoTableColumn
                  field='createdAt'
                  header='Fecha de creación'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.createdAtFilterRange}
                  filterFunction={dateTimeUtil.filterDate}
                  body={elements.createdAtColumnBody}
                />
                <NeoTableColumn
                  body={() => (
                    <NeoButtonOutlined 
                      label='Eliminar'
                      onClick={() => deleteMultimediaButtonClick = true}
                    />
                  )}
                />
              </NeoTable>
              {
                <LoadingDialog
                  visible={showOpeningDialog}
                  message={`Abriendo ${selectedMultimediaId}`}
                />
              }
              {
                <ActionDialog
                  header='Eliminar multimedia'
                  visible={showDeleteDialog}
                  visibleSetter={setShowDeleteDialog}
                  acceptMessage={`Eliminando ${selectedMultimediaId}`}
                  onAccept={deleteMultimedia}
                  onHide={handleDeleteDialogHide}
                >
                  Se eliminará <b>{selectedMultimediaId}</b>. ¿Deseas proceder?
                </ActionDialog>
              }
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError internal align='start' onRetry={reboot} />
            </NeoCard>
          }
        </>
      }
    </>
  );
}