import {useState, useEffect} from 'react';
import useAuthentication from 'hooks/useAuthentication.hook';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import AdminRouter from 'routers/Admin.router';
import UnknownRouter from 'routers/Unknown.router';
import UnauthenticatedRouter from 'routers/Unauthenticated.router';
import AdminBradesRouter from 'routers/AdminBrades.router';

export default function MainRouter() {
  const authentication = useAuthentication();
  const [isAuthenticated, setIsAuthenticated] = useState();

  useEffect(async () => reboot(), [authentication.isAuthenticated()]);

  const reboot = async () => {
    const updatedIsAuthenticated = authentication.isAuthenticated();
    setIsAuthenticated(updatedIsAuthenticated);
  }

  if(isAuthenticated === true) {
    if(authentication.userRole === 'ADMIN') {
      return (<AdminRouter/>);
    }
    if(authentication.userRole === 'ADMIN_BRADES') {
      return (<AdminBradesRouter/>);
    }
    return (<UnknownRouter/>);
  }
  if(isAuthenticated === false) {
    return (<UnauthenticatedRouter/>);
  }
  return (<NeoSpinner/>);
}