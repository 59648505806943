import NeoDialog from 'design/design_components/neo/overlay/NeoDialog.base';
import InternalSpinner from 'components/InternalSpinner.component';

export default function LoadingDialog(props) {
  return (
    <NeoDialog
      visible={props.visible}
      closable={false}
    >
      <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
        <InternalSpinner size={40}/>
        <span style={{marginLeft: '1em'}}>
          {props.message ?? 'Por favor espere'}
        </span>
      </div>
    </NeoDialog>
  );
}