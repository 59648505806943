import NeoTooltip from 'design/design_components/neo/overlay/NeoTooltip.base';

export default function InfoTooltip(props) {
  return (
    <>
      {
        (props.label) &&
        <span style={{ marginRight: '0.3em' }}>{props.label}</span>
      }
      <i className={`pi pi-question-circle tooltip-icon ${props.id}-info`} style={{paddingLeft: props.pl ?? '0'}}/>
      <NeoTooltip  {...props} target={`.${props.id}-info`} position={props.position ? props.position : 'bottom'}>
        {props.body ?? props.children}
      </NeoTooltip>
    </>
  );
}