import {useState, useEffect} from 'react';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import ActionDialog from 'components/ActionDialog.component';
import ApiKeysService from 'services/apiKeys.service';

export default function RenameApiKeyDialog(props) {
  const [name, setName] = useState(props.apiKey.name);
  const [nameError, setNameError] = useState('');

  useEffect(() => resetFrom(), [props.apiKey]);

  const trim = (value) => {
    return value.trim().replace(/[ ]+/g, ' ');
  }

  const resetFrom = () => {
    setName(props.apiKey.name);
    setNameError('');
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    if(event.action == 'cancel' || !event.payload.success) { resetFrom(); }
  }

  const updateApiKey = async () => {
    setNameError('');
    setName(trim(name));
    const response = await ApiKeysService.updateApiKey(props.apiKey.id, {name});
    if(response.success) {
      return {success: true, payload: name};
    }
    else if(response.error.code === 'ALREADY_EXIST') {
      setNameError('El nombre de la API Key ya existe.');
      return {success: false, internal: false};
    }
    return {success: false, internal: true};
  }

  const handleNameInputChange = (event) => {
    setName(event.value ?? event.target.value);
  }

  return (
    <ActionDialog
      header='Renombrar API Key'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Renombrando API Key'
      acceptDisabled={name.length == 0 || trim(name) == trim(props.apiKey.name)}
      onAccept={updateApiKey}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      <NeoInputText 
        label='Nombre' 
        value={name} 
        error={nameError} 
        onChange={handleNameInputChange}
      />
    </ActionDialog>
  );
}