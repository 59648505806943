import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';

export default function TextFormatInfo() {
  return (
    <NeoCard extra="p-mb-0" >
      <NeoInnerTitle extra='primero'>Dale estilo a tu mensaje</NeoInnerTitle>
      <NeoColumn col='12'>
        <p>Puedes cambiar el formato del texto de tus mensajes, ten en cuenta que estos formatos harán parte de la plantilla por lo que no se podrán modificar posteriormente.</p>



        <NeoFlexContainer extra="px-0">
          <NeoFlexContainer col='4' ai='center' extra="p-py-0 " jc="end">
            <span className='fs-2 extrabold p-pr-2'>Negrita</span>
          </NeoFlexContainer>
          <NeoFlexContainer col='8' extra='p-flex-column p-0'>
            <p className='p-mt-0 p-mb-1'>Coloca un asterisco antes y después del texto</p>
            <p className=' p-my-0'>Ejemplo: *texto*</p>
          </NeoFlexContainer>
        </NeoFlexContainer>
        <NeoFlexContainer extra="px-0">
          <NeoFlexContainer col='4' ai='center' extra="p-py-0 " jc="end">
            <span className='fs-2 italic p-pr-2'>Cursiva</span>
          </NeoFlexContainer>
          <NeoFlexContainer col='8' extra='p-flex-column p-0'>
            <p className='p-mt-0 p-mb-1'>Coloca un guión bajo antes y después del texto</p>
            <p className=' p-my-0'>Ejemplo: _texto_</p>
          </NeoFlexContainer>
        </NeoFlexContainer>

        <NeoFlexContainer extra="px-0">
          <NeoFlexContainer col='4' ai='center' extra="p-py-0 " jc="end">
            <span className='fs-2 tachado p-pr-2'>Tachado</span>
          </NeoFlexContainer>
          <NeoFlexContainer col='8' extra='p-flex-column p-0'>
            <p className='p-mt-0 p-mb-1'>Coloca una tilde bajo antes y después del texto</p>
            <p className=' p-my-0'>Ejemplo: ~texto~</p>
          </NeoFlexContainer>
        </NeoFlexContainer>


      </NeoColumn>


    </NeoCard>
  );
}