import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import CAMPAIGNS from 'consts/campaigns.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import CampaignsService from 'services/campaigns.service';
import dateTimeUtil from 'utils/dateTime.util';
import campaignsUtil from 'utils/campaigns.util';
import NeoCellDataStatus from 'design/design_components/neo/data/NeoCellDataStatus.base';

const statusOptions =
  Object.entries(CAMPAIGNS.STATUSES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({ label, value }));

export default function ListCampaigns(props) {
  const tableRef = useRef();
  const history = useHistory();
  const asyncState = useAsyncState({ isInitialized: true });
  const [campaigns, setCampaigns] = useState([]);

  useEffect(async () => await reboot(), [props.filters]);

  const reboot = async () => {
    await asyncState.allPromises(
      [getCampaigns()]
    );
  }

  const getCampaigns = async () => {
    const lineId = props.filters.line.id;
    const response = await CampaignsService.getCampaigns(lineId);
    if (response.success) {
      setCampaigns(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const handleTableRowSelect = (event) => {
    const { lineId, name } = event.data;
    const campaignId = `${lineId}-${name}`;
    history.push(`${MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.BASE}/${campaignId}`);
  };

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre' />
    ),
    startedAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='startedAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    sentTemplatesFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='sentTemplates' placeholder='Buscar por cantidad' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        options={statusOptions}
        field='status'
        placeholder='Todos'
        selectedItemsLabel='{0} estatus seleccionados'
      />
    ),
    nameColumnBody: (data) => (
      <b>{data.name}</b>
    ),
    startedAtColumnBody: (data) => (
      <>
        {
          (!data.startedAt)
            ? '-'
            : dateTimeUtil.getDateString(data.startedAt, { dateStyle: 'medium', timeStyle: 'short' })
        }
      </>
    ),
    statusColumnBody: (data) => (
      <>

        <NeoCellDataStatus extra="no-circle" status={campaignsUtil.getCampaignStatus(data.status)} label={campaignsUtil.getCampaignStatus(data.status)} />

      </>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner/>
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <NeoTable
              ref={tableRef}
              selectionMode='single'
              value={campaigns}
              paginator
              rows={10}
              removableSort
              sortField='startedAt'
              sortOrder={-1}
              emptyMessage='No hay campañas'
              onRowSelect={handleTableRowSelect}
            >
              <NeoTableColumn
                field='name'
                header='Nombre'
                filter
                sortable
                filterMatchMode='contains'
                filterElement={elements.nameFilterInput}
                body={elements.nameColumnBody}
              />
              <NeoTableColumn
                header='Fecha de envío'
                field='startedAt'
                filter
                sortable
                filterMatchMode='contains'
                filterElement={elements.startedAtFilterRange}
                filterFunction={dateTimeUtil.filterDate}
                body={elements.startedAtColumnBody}
              />
              <NeoTableColumn
                field='sentTemplates'
                header='Plantillas enviadas'
                filter
                sortable
                filterMatchMode='contains'
                filterElement={elements.sentTemplatesFilterInput}
              />
              <NeoTableColumn
                field='status'
                header='Estatus'
                filter
                sortable
                filterMatchMode='custom'
                filterElement={elements.statusFilterMultiSelect}
                body={elements.statusColumnBody}
              />
            </NeoTable>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError internal align='start' onRetry={reboot}/>
            </NeoCard>
          }
        </>
      }
    </>
  );
}