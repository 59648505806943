import React from 'react';
import BaseDialog from '../../sisu/overlay/BaseDialog.sisu';

const NeoDialogo = (props) => {

  return (
    <BaseDialog {...props} draggable={false} />
  )
};


export default NeoDialogo;