import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import GeneralCampaignDataForm from 'views/campaigns/components/GeneralCampaignDataForm.component';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import CampaignsService from 'services/campaigns.service';

export default function CreateCampaign() {
  const history = useHistory();
  const messages = useMessages();
  const asyncState = useAsyncState({isInitialized: true});
  const [generalData, setGeneralData] = useState(null);

  const isExistingCampaign = async (options = {}) => {
    const lineId = generalData.payload.line.id;
    const name = generalData.payload.name;
    const response = await CampaignsService.isExistingCampaign(lineId, name);
    if (response.success) {
      if (response.payload == false) {
        history.push({
          pathname: options.contacts === true 
            ? MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS_SELECTION.PATH 
            : MENU.ADMIN.CAMPAIGNS.CAMPAIGN_FILES_UPLOAD.PATH,
          state: generalData.payload
        });
        return { success: true };
      }
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Nombre de campaña incorrecto',
        `La campaña ${name} ya existe en la línea ${generalData.payload.line.name}.`,
        { life: 5000 }
      );
      return { success: true };
    }
    messages.showToast(
      TOAST.SEVERITY.ERROR,
      'Algo salío mal',
      'No se pudo validar la campaña, inténtalo de nuevo.'
    );
    return { success: false };
  }

  const handleNextButtonClick = async (options = {}) => {
    asyncState.allPromises(
      [isExistingCampaign(options)]
    );
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (generalData) &&
        <NeoGridContainer>
          <NeoTitleSecond icon={Icono} title='Crear campaña' subtitle='Configuración general' />
        </NeoGridContainer>
      }
      <GeneralCampaignDataForm onChange={(event) => setGeneralData(event)} />
      {
        (generalData) &&
        <NeoButtonSection align='right'>
          <NeoButtonMain
            label='Seleccionar contactos'
            disabled={generalData.valid === false}
            onClick={() => handleNextButtonClick({contacts: true})}
          />
          <NeoButtonMain
            label='Subir archivos .csv'
            disabled={generalData.valid === false}
            onClick={() => handleNextButtonClick({contacts: false})}
          />
        </NeoButtonSection>
      }
    </>
  )
}