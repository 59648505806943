import { useRef, useState, useEffect } from 'react';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import TOAST from 'consts/toast.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import CreateTestContactDialog from 'views/campaigns/components/CreateTestContactDialog.component';
import TestContactsService from 'services/testContacts.service';

export default function CampaignTestSending(props) {
  const tableRef = useRef();
  const messages = useMessages();
  const asyncState = useAsyncState({ isInitialized: true });
  const [testContacts, setTestContacts] = useState([]);
  const [selectedTestContacts, setSelectedTestContacts] = useState([]);
  const [parameters, setParameters] = useState(null);
  const [showCreateTestContactDialog, setShowCreateTestContactDialog] = useState(false);
  const [showTestSendDialog, setShowTestSendDialog] = useState(false);

  useEffect(() => reboot(), []);
  useEffect(() => exampleDataToParameters(), [props.exampleData]);

  const reboot = async () => {
    await asyncState.allPromises(
      [getTestContacts()]
    );
  }

  const exampleDataToParameters = () => {
    if (props.exampleData) {
      const parameters = { header: {}, body: {}, buttons: {} };
      Object.entries(props.exampleData).forEach(([paramNotation, paramValue]) => {
        const paramParts = paramNotation.split('.');
        const section = paramParts[0];
        if (section == 'header') {
          const headerType = paramParts[1];
          const paramName = paramParts[2];
          const headerTypeParams = parameters.header[headerType] ?? {};
          parameters.header = { type: headerType.toUpperCase(), [headerType]: { ...headerTypeParams, [paramName]: paramValue } };
        }
        if (section == 'body') {
          const paramName = paramParts[1];
          parameters.body[paramName] = { type: 'TEXT', text: paramValue };
        }
        if (section == 'buttons') {
          const buttonNumber = paramParts[1];
          const buttonType = paramParts[2];
          const paramName = paramParts[3];
          if (buttonType == 'url') {
            parameters.buttons[buttonNumber] = { type: 'URL', url: { [paramName]: paramValue } }
          }
        }
      });
      if (Object.keys(parameters.header).length == 0) { delete parameters.header; }
      if (Object.keys(parameters.body).length == 0) { delete parameters.body; }
      if (Object.keys(parameters.buttons).length == 0) { delete parameters.buttons; }
      setParameters(parameters);
    }
  }

  const getTestContacts = async () => {
    const response = await TestContactsService.getTestContacts();
    if (response.success) {
      setTestContacts(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const sendCampaignTest = async () => {
    const lineId = props.line.id;
    const template = { name: props.template.name, language: props.template.language, parameters };
    const phones = selectedTestContacts.map((testContact) => testContact.phone);
    const response = await TestContactsService.sendCampaignTest(lineId, phones, template);
    return { success: response.success };
  }

  const handleCreateTestContactDialogHide = (event) => {
    if (event.success == true) {
      const { payload } = event;
      const testContact = { ...payload, phone: `+${payload.id}`};
      const updatedTestContacts = [...testContacts, testContact];
      const updatedSelectedTestContacts = [...selectedTestContacts, testContact];
      setTestContacts(updatedTestContacts);
      setSelectedTestContacts(updatedSelectedTestContacts);
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se agregó el contacto de prueba.'
      );
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo registrar el contacto de prueba, inténtalo de nuevo.'
      );
    }
  }

  const handleTestSendDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        messages.showToast(
          TOAST.SEVERITY.SUCCESS,
          'Operación exitosa',
          'Se envió la prueba de campaña.'
        );
      }
      else {
        messages.showToast(
          TOAST.SEVERITY.ERROR,
          'Algo salió mal',
          'No se pudo enviar la prueba de campaña, inténtalo de nuevo.'
        );
      }
    }
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Contactos de prueba</NeoInnerTitle>
      {
        (asyncState.isLoading) &&
        <InternalSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                ref={tableRef}
                value={testContacts}
                selection={selectedTestContacts}
                extra='no-filters with-footer p-mb-3'
                removableSort
                emptyMessage='No hay contactos de prueba'
                onSelectionChange={(event) => setSelectedTestContacts(event.value)}
                footer={`${selectedTestContacts.length} contacto${selectedTestContacts.length != 1 ? 's' : ''} de prueba seleccionado${selectedTestContacts.length != 1 ? 's' : ''}`}
              >
                <NeoTableColumn
                  selectionMode='multiple'
                  style={{width: '3em'}}
                />
                <NeoTableColumn
                  header='Nombre'
                  field='name'
                />
                <NeoTableColumn
                  header='Teléfono'
                  field='phone'
                />
              </NeoTable>
              <NeoButtonSection align='center'>
                <NeoButtonMain
                  label='Agregar contacto'
                  icon='pi pi-plus'
                  onClick={() => setShowCreateTestContactDialog(true)}
                />
                <NeoButtonMain
                  label='Enviar prueba a seleccionados'
                  icon='pi pi-fw pi-send'
                  disabled={selectedTestContacts.length === 0}
                  onClick={() => setShowTestSendDialog(true)}
                />
              </NeoButtonSection>
              {
                <CreateTestContactDialog
                  visible={showCreateTestContactDialog}
                  visibleSetter={setShowCreateTestContactDialog}
                  onHide={handleCreateTestContactDialogHide}
                />
              }
              {
                <ActionDialog
                  header='Enviar prueba de campaña'
                  visible={showTestSendDialog}
                  visibleSetter={setShowTestSendDialog}
                  acceptMessage='Enviando prueba de campaña'
                  onAccept={sendCampaignTest}
                  onHide={handleTestSendDialogHide}
                >
                  Se enviará una prueba de campaña a los contactos seleccionados. El mensaje a enviar se genera con datos aleatorios y será el mismo para todos. ¿Deseas proceder?
                </ActionDialog>
              }
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <DataFetchError internal align='start' onRetry={reboot}/>
          }
        </>
      }
    </NeoCard>
  );
}