import {useContext} from 'react';
import {auth} from 'config/firebase.config';
import AuthenticationContext from 'contexts/authentication.context';

export default function useAuthentication() {
  const {user, userRole} = useContext(AuthenticationContext);

  const isAuthenticated = () => {
    return user !== undefined ? user !== null : undefined;
  }

  const signInWithEmailAndPassword = async (email, password) => {
    await auth.signInWithEmailAndPassword(email, password);
  }

  const signInWithCustomToken = async (token) => {
    await auth.signInWithCustomToken(token);
  }

  const signOut = async () => {
    await auth.signOut();
  }

  return {
    user, 
    userRole,
    signOut,
    isAuthenticated,
    signInWithCustomToken,
    signInWithEmailAndPassword
  };
}