import { useState, useEffect } from 'react';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import templatesUtil from 'utils/templates.util';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoButtonWhatsapp from 'design/design_components/neo/button/NeoButtonWhatsapp.base';

export default function TemplatePreview(props) {
  const [name, setName] = useState(props.template.name);
  const [language, setLanguage] = useState(props.template.language);
  const [structure, setStructure] = useState(props.template.structure);

  useEffect(() => {
    setName(props.template.name);
    setLanguage(props.template.language);
    setStructure(props.template.structure);
  }, [props.template]);

  useEffect(() => {
    if (props.realTimeDataTemplate) {
      setStructure(props.realTimeDataTemplate);
    }
  }, [props.realTimeDataTemplate])

  return (
    <>

      <NeoGridContainer col="12">
        <NeoInnerTitle extra="primero">
          {props.title ?? 'Previsualización de plantilla usada'}
        </NeoInnerTitle>

        <NeoColumn col="12">
          {
            (name && language) &&
            <NeoInnerSubtitle>
              {name} ({language})</NeoInnerSubtitle>
          }
          <div className="wa-container p-mb-3" >
            <div className="wa-back"> </div>

            <div className="wa-balloon">
              <span className='wa-svg-balloon-container'>
                <svg viewBox="0 0 8 13" width="8" height="13" class=""><path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path><path fill="#FFFFFF" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path></svg>
              </span>
              {
                (structure.header && structure.header.type) &&
                <p >
                  {
                    (structure.header.type == 'TEXT') &&
                    <span className='wa-header-title'>{structure.header.text}</span>
                  }
                  {
                    (structure.header.type != 'TEXT') &&
                    <div>
                      {
                        (structure.header.type == 'IMAGE') &&
                        <>
                          {
                            (structure.header.image?.url) &&
                            <img src={structure.header.image?.url} style={{ width: '100%', height: '100%' }} />
                          }
                          {
                            (!structure.header.image?.url) &&
                            <>{templatesUtil.getTemplateHeaderType(structure.header.type)}</>
                          }
                        </>
                      }
                      {
                        (structure.header.type != 'IMAGE') &&
                        <>{structure.header.document?.filename ?? templatesUtil.getTemplateHeaderType(structure.header.type)}</>
                      }
                    </div>
                  }
                </p>
              }
              {
                structure.body?.split('\n').map((text, index) => (
                  text.length == 0
                    ? <br />
                    : <p key={index} >{text}</p>
                ))
              }
              {
                (structure.footer) &&
                <span className='wa-footer'>{structure.footer}</span>
              }

              {
                (structure.buttons?.length > 0) &&
                <div className='wa-buttons'>
                  {
                    (structure.buttons?.length > 0) &&
                    structure.buttons.map((button, index) => (

                      <NeoButtonWhatsapp key={index} label={button.text} />
                    ))
                  }
                </div>
              }
            </div>



          </div>
        </NeoColumn>
      </NeoGridContainer>
    </>
  )
}