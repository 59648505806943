import {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import LinesService from 'services/lines.service';

export default function LinesList() {
  const tableRef = useRef();
  const history = useHistory();
  const asyncState = useAsyncState();
  const [lines, setLines] = useState([]);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getLines()]
    );
  }

  const getLines = async () => {
    const response = await LinesService.getLines();
    if(response.success) {
      setLines(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  const handleTableRowSelect = (event) => {
    const {id} = event.data;
    history.push(`${MENU.ADMIN.LINES.LINE_PROFILE.BASE}/${id}`);
  }

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre'/>
    ),
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='phone' placeholder='Buscar por teléfono'/>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner/>
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                ref={tableRef}
                selectionMode='single'
                value={lines}
                paginator
                rows={10}
                removableSort
                sortField='name'
                sortOrder={1}
                emptyMessage='No hay líneas'
                onRowSelect={handleTableRowSelect}
              >
                <NeoTableColumn
                  field='name'
                  header='Nombre'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.nameFilterInput}
                />
                <NeoTableColumn
                  field='phone'
                  header='Teléfono'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.phoneFilterInput}
                />
              </NeoTable>
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError internal align='start' onRetry={initialize} />
            </NeoCard>
          }
        </>
      }
    </>
  );
}