import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import useAsyncState from 'hooks/useAsyncState.hook';
import ResourceNotFound from 'components/ResourceNotFound.component';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import BasicApiKeyInfo from 'views/apiSettings/components/BasicApiKeyInfo.component';
import DataFetchError from 'components/DataFetchError.component';
import ApiKeyDetailHeader from 'views/apiSettings/components/ApiKeyDetailHeader.component';
import ApiKeysService from 'services/apiKeys.service';

export default function ApiKeyDetail() {
  const {id} = useParams();
  const asyncState = useAsyncState();
  const [apiKey, setApiKey] = useState(null);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getApiKey()], 
      {initialization: true}
    );
  }

  const getApiKey = async () => {
    const response = await ApiKeysService.getApiKey(id);
    if(response.success) {
      setApiKey({id, ...response.payload});
      return {success: true};
    }
    if(response.error.internal) {
      return {success: false};
    }
    return {success: true};
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/> 
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (apiKey) &&
                <>
                  <ApiKeyDetailHeader apiKey={apiKey} onChange={(value) => setApiKey(value)}/>
                  <BasicApiKeyInfo apiKey={apiKey}/>
                </>
              }
              {
                (!apiKey) &&
                <ResourceNotFound 
                  title='La API Key no existe'
                  redirection={{label: 'Ir a configuración de API', path: MENU.ADMIN.API.ROOT.PATH}}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize}/>
          }
        </>
      }
    </>
  );
}