import MESSAGES from 'consts/messages.consts';

function getMessageType(type) {
  return MESSAGES.TYPES[type] ?? 'Desconocido';
}

function getMessageStatus(status) {
  return MESSAGES.STATUSES[status] ?? 'Desconocido';
}

export default {
  getMessageType,
  getMessageStatus
};