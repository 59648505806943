import {Switch} from 'react-router-dom';
import AUTHENTICATION from 'consts/authentication.consts';
import useAuthentication from 'hooks/useAuthentication.hook';
import RouterContainer from 'routers/components/RouterContainer.component';

export default function UnknownRouter() {
  const authentication = useAuthentication();

  const menu = [
    {
      label: authentication.user.email,
      className: 'signout',
      command: async () => {
        await authentication.signOut();
        window.location = AUTHENTICATION.LOGOUT_URL;
      }
    }
  ];

  return (
    <RouterContainer menu={menu}>
      <Switch>
      </Switch>
    </RouterContainer>
  );
}