import 'App.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';


import { BrowserRouter } from 'react-router-dom';

import MainRouter from 'routers/Main.router';
import { AuthenticationContextProvider } from 'contexts/authentication.context';
import { MessagesContextProvider } from 'contexts/messages.context';

function App() {
  return (
    <BrowserRouter>
      <AuthenticationContextProvider>
        <MessagesContextProvider>
          <MainRouter />
        </MessagesContextProvider>
      </AuthenticationContextProvider>
    </BrowserRouter>
  );
}

export default App;