import {useState} from 'react';
import DashboardFilters from 'views/dashboard/components/DashboardFilters.component';
import GeneralStatistics from 'views/dashboard/components/GeneralStatistics.component';

export default function GeneralDashboard() {
  const [filters, setFilters] = useState(null);

  const handleFiltersChange = (event) => {
    setFilters(event);
  }

  return (
    <>
      <DashboardFilters 
        title='Estadísticas generales'
        onChange={handleFiltersChange}
      />
      {
        (filters) &&
        <GeneralStatistics filters={filters}/>
      }
    </>
  );
}