import backendRequestUtil from 'utils/backendRequest.util';

export default class AuthenticationService {

  static async getUserAccount() {
    const {success, error} = await backendRequestUtil.get('/rest/userAccount');
    return success ? {success} : {success, error};
  }

  static async getUserRole() {
    const {success, body, error} = await backendRequestUtil.get('/rest/userRole');
    return success ? {success, payload: body.role} : {success, error};
  }

}