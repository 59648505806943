import Icono from 'design/assets/img/cta/icons/icon-lines.svg';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';

export default function LineProfileHeader(props) {
  return (
    <NeoGridContainer>
      <NeoTitleSecond
        icon={Icono}
        title={`${props.line.name} | ${props.line.phone}`}
        subtitle='Detalle de línea'
      />
    </NeoGridContainer>
  );
}