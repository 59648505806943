import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import TemplatePreviewWithVariablesForm from 'views/templates/components/TemplatePreviewWithVariablesForm.component';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import ActionDialog from 'components/ActionDialog.component';
import Icono from 'design/assets/img/cta/icons/icon-template.svg';
import TemplatesService from 'services/templates.service';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';

export default function TemplatePreview() {
  const history = useHistory();
  const messages = useMessages();
  const [state] = useStateParams();
  const [template, setTemplate] = useState(null);
  const [showCreateTemplateDialog, setShowCreateTemplateDialog] = useState(false);

  useEffect(() => initialize(), []);

  const initialize = () => {
    if (state.line && state.template) {
      setTemplate(state.template);
    }
    else {
      history.push(MENU.ADMIN.TEMPLATES.ROOT.PATH);
    }
  }

  const handleCreateTemplateDialogAccept = async () => {
    const lineId = state.line.id;
    const response = await TemplatesService.createTemplate(lineId, template);
    return { success: response.success };
  }

  const handleCreateTemplateDialogHide = (event) => {
    if (event.action == 'accept') {
      if (event.payload.success) {
        history.push(`${MENU.ADMIN.TEMPLATES.ROOT.PATH}?line=${state.line.id}`);
        messages.showToast(
          TOAST.SEVERITY.SUCCESS,
          'Operación exitosa',
          'Se creó la plantilla.'
        );
      }
      else {
        messages.showToast(
          TOAST.SEVERITY.ERROR,
          'Algo salió mal',
          'No se pudo crear la plantilla, inténtalo de nuevo.'
        );
      }
    }
  }

  return (
    <>
      {
        (template) &&
        <>
          <NeoTitleSecond
            icon={Icono}
            title={`${template.name} (${template.language})`}
            subtitle={`${state.line.name} | ${state.line.phone}`}
          />
          <NeoGridContainer>
            <TemplatePreviewWithVariablesForm template={template} />
          </NeoGridContainer>
          <NeoButtonSection align='right' >
            <NeoButtonOutlined
              label='Cancelar'
              onClick={() => history.goBack()}
            />
            <NeoButtonMain
              label='Crear plantilla'
              onClick={() => setShowCreateTemplateDialog(true)}
            />
          </NeoButtonSection>
          {
            <ActionDialog
              header='Crear plantilla'
              visible={showCreateTemplateDialog}
              visibleSetter={setShowCreateTemplateDialog}
              acceptMessage='Creando plantilla'
              onAccept={handleCreateTemplateDialogAccept}
              onHide={handleCreateTemplateDialogHide}
            >
              Se creará la plantilla. ¿Deseas proceder?
            </ActionDialog>
          }
        </>
      }
    </>
  );
}