import React from "react";

const BaseFlexContainer = (props) => {
  return (<div className={`
    ${props.custom ? props.custom : ""}
    ${props.extra ? props.extra : ""} 
    ${props.col ? `p-col-${props.col}` : "p-col-12"} 
    ${props.md ? `p-md-${props.md}` : ""} 
    ${props.sm ? `p-sm-${props.sm}` : ""} 
    ${props.jc ? `p-jc-${props.jc}` : ""} 
    ${props.ai ? `p-ai-${props.ai}` : ""}`}>
    {props.children}
  </div>);
};

export default BaseFlexContainer;