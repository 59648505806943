import { useState, useEffect } from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InfoTooltip from 'components/InfoTooltip.component';
import TEMPLATES from 'consts/templates.consts';

const categoriesOptions =
  Object.entries(TEMPLATES.CATEGORIES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({ label, value }));

const commonsLanguagesOptions = [
  { label: TEMPLATES.LANGUAGES.es, value: 'es' },
  { label: TEMPLATES.LANGUAGES.es_MX, value: 'es_MX' },
  { label: TEMPLATES.LANGUAGES.en, value: 'en' },
  { label: TEMPLATES.LANGUAGES.en_US, value: 'en_US' },
  { label: TEMPLATES.LANGUAGES.en_GB, value: 'en_GB' }
];

const allLanguagesOptions =
  Object.entries(TEMPLATES.LANGUAGES)
    .map(([value, label]) => [label, value])
    .sort(Intl.Collator().compare)
    .map(([label, value]) => ({ label, value }));

const languagesOptions = [
  { group: 'Más usados', items: commonsLanguagesOptions },
  { group: 'Todos', items: allLanguagesOptions }
];

export default function GeneralTemplateDataForm(props) {
  const [templates, setTemplates] = useState({});
  const [state] = useStateParams();
  const [name, setName] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [languagesDropdownOptions, setLanguagesDropdownOptions] = useState(languagesOptions);
  const [isDisableNameInput, setIsDisableNameInput] = useState(false);
  const [isDisabledCategoryDropdown, setIsDisabledCategoryDropdown] = useState(false);

  useEffect(() => rebootTemplates(), [props.templates]);
  useEffect(() => handleChangeGeneralData(), [name, selectedLanguage, selectedCategory]);

  const rebootTemplates = () => {
    const name = state.generalData?.name ?? '';
    setName(name);
    setSelectedLanguage(state.generalData?.language);
    setSelectedCategory(state.generalData?.category);
    const templatesEntries = props.templates.map(({ name, ...template }) => [name, template]);
    const templates = Object.fromEntries(templatesEntries);
    setTemplates(templates);
    if (props.name) {
      const template = templates[props.name];
      if (template !== undefined) {
        setName(props.name);
        setSelectedCategory(template.category);
        setIsDisableNameInput(true);
        setIsDisabledCategoryDropdown(true);
        const commonsanguagesDropdownOptions = commonsLanguagesOptions.filter((languageOption) => !template.translations.includes(languageOption.value));
        const allLanguagesDropdownOptions = allLanguagesOptions.filter((languageOption) => !template.translations.includes(languageOption.value));
        setLanguagesDropdownOptions([
          { group: 'Más usados', items: commonsanguagesDropdownOptions },
          { group: 'Todos', items: allLanguagesDropdownOptions }
        ]);
      }
    }
    else {
      handleNameInputBlur({ templates, name });
    }
  }

  const handleChangeGeneralData = () => {
    const event = {
      valid: (name.length > 0 && selectedLanguage !== undefined && selectedCategory !== undefined),
      generalData: {
        name,
        language: selectedLanguage,
        category: selectedCategory
      }
    };
    props.onChange(event);
  }

  const handleNameInputChange = (event) => {
    let name = event.value ?? event.target.value;
    if (name[0] != '_' && Number.isNaN(Number.parseInt(name[0]))) {
      name = name.trim();
      name = name.replace(/[^_a-zA-Z0-9]+/g, '_');
      name = name.toLowerCase();
      setName(name);
    }
  }

  const handleNameInputBlur = (options = {}) => {
    const templatesArray = options.templates ?? templates;
    const template = templatesArray[options.name ?? name];
    if (template !== undefined) {
      const commonsanguagesDropdownOptions = commonsLanguagesOptions.filter((languageOption) => !template.translations.includes(languageOption.value));
      const allLanguagesDropdownOptions = allLanguagesOptions.filter((languageOption) => !template.translations.includes(languageOption.value));
      setIsDisabledCategoryDropdown(true);
      setSelectedCategory(template.category);
      setLanguagesDropdownOptions([
        { group: 'Más usados', items: commonsanguagesDropdownOptions },
        { group: 'Todos', items: allLanguagesDropdownOptions }
      ]);
      if (selectedLanguage && template.translations.includes(selectedLanguage)) {
        setSelectedLanguage(undefined);
      }
    }
    else {
      setIsDisabledCategoryDropdown(false);
      setLanguagesDropdownOptions(languagesOptions);
    }
  }

  const handleLanguageDropdownChange = (event) => {
    const selectedLanguage = event.value;
    setSelectedLanguage(selectedLanguage);
  }

  const handleCategoryDropdownChange = (event) => {
    const selectedCategory = event.value;
    setSelectedCategory(selectedCategory);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Datos generales</NeoInnerTitle>
      <NeoInputText md='4'
        col="12"
        label='Nombre de la plantilla'
        rightIcon={<InfoTooltip id='name' body='El nombre de la plantilla no puede contener mayúsculas, espacios ni caracteres especiales.' />}
        value={name}
        maxLength={32}
        keyfilter={/^[_a-zA-Z0-9]+$/}
        disabled={isDisableNameInput}
        onChange={handleNameInputChange}
        onBlur={handleNameInputBlur}
      />
      <NeoDropdown md='4'
        col="12"
        label='Idioma'
        value={selectedLanguage}
        options={languagesDropdownOptions}
        optionValue='value'
        optionLabel='label'
        optionGroupLabel='group'
        optionGroupChildren='items'
        onChange={handleLanguageDropdownChange}
      />
      <NeoDropdown md='4'
        col="12"
        label='Categoría'
        value={selectedCategory}
        options={categoriesOptions}
        disabled={isDisabledCategoryDropdown}
        onChange={handleCategoryDropdownChange}
      />
    </NeoCard>
  );
}