import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import CONTACTS from 'consts/contacts.consts';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import CampaignContactsTable from 'views/campaigns/components/CampaignContactsTable.component';
import VariablesMappingDialog from 'views/campaigns/components/VariablesMappingDialog.component';

const basicFieldsOptions = Object.entries(CONTACTS.DATA.BASIC).map(([_, { LABEL, FIELD }]) => ({ label: LABEL, value: FIELD }));

const extraFieldsOptions = Object.entries(CONTACTS.DATA.EXTRA).map(([_, { LABEL, FIELD }]) => ({ label: LABEL, value: FIELD }));

const extraTextFieldsOptions = Array(CONTACTS.DATA.EXTRA_TEXT_FIELDS).fill('').map((_, index) => {
  const label = `Texto ${index + 1}`;
  const field = `text${index + 1}`;
  return { label, value: field };
});

const extraNumberFieldsOptions = Array(CONTACTS.DATA.EXTRA_NUMBER_FIELDS).fill('').map((_, index) => {
  const label = `Número ${index + 1}`;
  const field = `number${index + 1}`;
  return { label, value: field };
});

const extraDateFieldsOptions = Array(CONTACTS.DATA.EXTRA_DATE_FIELDS).fill('').map((_, index) => {
  const label = `Fecha ${index + 1}`;
  const field = `date${index + 1}`;
  return { label, value: field };
});

const extraTokenFieldsOptions = Array(CONTACTS.DATA.EXTRA_TOKEN_FIELDS).fill('').map((_, index) => {
  const label = `Token ${index + 1}`;
  const field = `token${index + 1}`;
  return { label, value: field };
});

const extraFileNameFieldsOptions = Array(CONTACTS.DATA.EXTRA_FILENAME_FIELDS).fill('').map((_, index) => {
  const label = `Nombre de archivo ${index + 1}`;
  const field = `fileName${index + 1}`;
  return { label, value: field };
});

const fieldsOptions = [
  ...basicFieldsOptions,
  ...extraFieldsOptions,
  ...extraTextFieldsOptions,
  ...extraNumberFieldsOptions,
  ...extraDateFieldsOptions,
  ...extraTokenFieldsOptions,
  ...extraFileNameFieldsOptions
];

export default function CampaignContactsSelection() {
  const history = useHistory();
  const [state, setState] = useStateParams();
  const [showMappingDialog, setShowMappingDialog] = useState(false);
  const [requiredFields, setRequiredFields] = useState([...new Set(Object.values(state.mapping ?? {}))]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isSelectedContactsLoaded, setIsSelectedContactsLoaded] = useState(false);
  const [isInitializedMapping, setIsInitializedMapping] = useState(false);

  useEffect(() => initialize(), []);

  const initialize = () => {
    if (!state.template) {
      history.push(MENU.ADMIN.CAMPAIGNS.ROOT.PATH);
    }
    if (!state.mapping) {
      setShowMappingDialog(true);
    }
    else {
      setIsInitializedMapping(true);
    }
  }

  const handleMappingDialogAccept = (event) => {
    const requiredFields = [...new Set(Object.values(event))];
    setIsInitializedMapping(true);
    setRequiredFields(requiredFields);
    setState({ ...state, mapping: event });
  }

  const handleMappingDialogCancel = () => {
    history.goBack();
  }

  const handleCampaignContactsChange = (event) => {
    setSelectedContacts(event);
    setState({ ...state, contacts: event });
  }

  const handleNextButtonClick = () => {
    const examples = [];
    const mappingEntries = Object.entries(state.mapping);
    const contacts = selectedContacts.map((contact) => {
      if (examples.length < 100) {
        const example = Object.fromEntries(mappingEntries.map(([param, field]) => [param, contact[field]]));
        examples.push(example);
      }
      return contact.id;
    });
    history.push({
      pathname: MENU.ADMIN.CAMPAIGNS.CAMPAIGN_PREVIEW_AND_TESTING.PATH,
      state: { ...state, type: 'CONTACTS', contacts, examples }
    })
  }

  return (
    <>
      {
        (state.template) &&
        <>
          <NeoGridContainer>
            <NeoTitleSecond
              icon={Icono}
              title='Crear campaña'
              subtitle='Selección de contactos'
            />
          </NeoGridContainer>
          <CampaignContactsTable

            requiredFields={requiredFields}
            onChange={handleCampaignContactsChange}
            onSelectedContactsLoaded={() => setIsSelectedContactsLoaded(true)}
          />
          <NeoButtonSection align='right'>
            <NeoButtonMain
              label='Volver a mapear variables'
              disabled={!isSelectedContactsLoaded}
              onClick={() => setShowMappingDialog(true)}
            />
            <NeoButtonMain
              label='Siguiente'
              disabled={selectedContacts.length == 0}
              onClick={handleNextButtonClick}
            />
          </NeoButtonSection>
          {
            <VariablesMappingDialog
              type='contacts'
              autoClear={false}
              cancel={!isInitializedMapping}
              mapping={state?.mapping}
              visible={showMappingDialog}
              visibleSetter={setShowMappingDialog}
              templateStructure={state.template.structure}
              options={fieldsOptions}
              onAccept={handleMappingDialogAccept}
              onCancel={handleMappingDialogCancel}
            />
          }
        </>
      }
    </>
  );
}