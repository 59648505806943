import React from "react";

const BaseColumn = (props) => {
  return (<div className={`
  ${props.custom ? props.custom : ""}  
  ${props.extra ? props.extra : ""}  
  ${props.col ? `p-col-${props.col}` : ""}  
  ${props.sm ? `p-sm-${props.sm}` : ""}
  ${props.md ? `p-md-${props.md}` : ""}  
  ${props.lg ? `p-lg-${props.lg}` : ""}  
  ${props.jc ? `p-jc-${props.jc}` : ""}`}>
    {props.children}
  </div>);
};

export default BaseColumn;