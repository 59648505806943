import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import dateTimeUtil from 'utils/dateTime.util';

export default function BasicApiKeyInfo(props) {
	const [apiKey, setApiKey] = useState(props.apiKey);

	useEffect(() => setApiKey(props.apiKey), [props.apiKey]);

	return (
		<NeoCard>
			<NeoColumn md='8' col="12" extra='apidev-container'>
				<NeoDataSecond label='API Key' fact={apiKey.key} />
			</NeoColumn>
			<NeoColumn md='3' col="12">
				<NeoDataSecond label='Fecha de creación' fact={dateTimeUtil.getDateString(apiKey.createdAt, { dateStyle: 'medium' })} />
			</NeoColumn>
			<NeoColumn md='1' col="12">
				<NeoDataSecond label='Estatus' fact={apiKey.enabled ? 'Activa' : 'Inactiva'} />
			</NeoColumn>
		</NeoCard>
	);
}