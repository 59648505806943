import { useState } from 'react';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import ActionDialog from 'components/ActionDialog.component';
import ApiKeysService from 'services/apiKeys.service';

export default function CreateApiKeyDialog(props) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const resetForm = () => {
    setName('');
    setNameError('');
  }

  const handleAccept = async () => {
    setNameError('');
    const response = await ApiKeysService.createApiKey({ name });
    if (response.success) {
      return { success: true, payload: { name } };
    }
    else if (response.error.code === 'ALREADY_EXIST') {
      setNameError('El nombre de la API Key ya existe.');
      return { success: false, internal: false };
    }
    return { success: false, internal: true };
  }

  const handleHide = (event) => {
    if (event.action == 'accept') { props.onHide(event.payload); }
    resetForm();
  }

  const handleNameInputChange = (event) => {
    setName(event.value ?? event.target.value);
  }

  return (
    <ActionDialog
      header='Crear nueva API Key'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Creando API Key'
      acceptDisabled={name.length == 0}
      onAccept={handleAccept}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      <NeoInputText
        label='Nombre'
        value={name}
        error={nameError}
        onChange={handleNameInputChange}
      />
    </ActionDialog>
  );
}