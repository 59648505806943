import { useState, useRef, useEffect } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDataMain from 'design/design_components/neo/data/NeoDataMain.base';
import NeoPieChartCustom from 'design/design_components/neo/chart/NeoPieChartCustom.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import CampaignsService from 'services/campaigns.service';
import dateTimeUtil from 'utils/dateTime.util';
import messagesUtil from 'utils/messages.util';


const messagesStatusValues = {
  SENT: 1,
  DELIVERED: 2,
  READ: 3
};

const messagesStatusOptions = [
  { label: 'Enviado', value: 'SENT' },
  { label: 'Recibido', value: 'DELIVERED' },
  { label: 'Leído', value: 'READ' }
];

const colors = {
  byName: ['green', 'blue', 'yellow', 'gray'],
  byCode: ['#49ad48', '#45ABEC', '#FDE432', '#BAC3C3']
};

export default function CampaignMessagesStatusAndDetail(props) {
  const tableRef = useRef();
  const asyncState = useAsyncState({ isInitialized: true });
  const [messagesStatus, setMessagesStatus] = useState();
  const [messagesDetail, setMessagesDetail] = useState();
  const [messagesTotal, setMessagesTotal] = useState(0);

  useEffect(async () => await reboot(), [props.campaign]);

  const reboot = async () => {
    await asyncState.allPromises(
      [getCampaignStatistics()]
    );
  }

  const getCampaignStatistics = async () => {
    const lineId = props.campaign.lineId;
    const name = props.campaign.name;
    const response = await CampaignsService.getCampaignStatistics(lineId, name);
    if (response.success) {
      const { pending, sent, delivered, read } = response.payload.messagesStatus;
      const messagesDetail = response.payload.messagesDetail.sort((message1, message2) => {
        message1 = message1.message;
        message2 = message2.message;
        const message1At = `${message1.status.toLowerCase()}At`;
        const message2At = `${message2.status.toLowerCase()}At`;
        return (message2[message2At] - message1[message1At]);
      });
      setMessagesStatus([
        { name: 'Leídos', messages: read, messagestatus: 'read' },
        { name: 'Enviados y recibidos', messages: delivered, messagestatus: 'delivered' },
        { name: 'Enviados y no recibidos', messages: sent, messagestatus: 'sent' },
        { name: 'Por enviar', messages: pending, messagestatus: 'pending' }
      ]);
      props.onResultsChange({
        messagesDetail: response.payload.messagesDetail, 
        messagesErrors: response.payload.messagesErrors,
        quickRepliesDetail: response.payload.quickRepliesDetail
      });
      setMessagesDetail(messagesDetail);
      setMessagesTotal(pending + sent + delivered + read);
      return { success: true };
    }
    return { success: false };
  }

  const statusSortFunction = (event) => {
    const { order } = event;
    return messagesDetail.sort((message1, message2) => {
      message1 = message1.message;
      message2 = message2.message;
      return (messagesStatusValues[message1.status] - messagesStatusValues[message2.status]) * order;
    });
  }

  const statusAtFilterFunction = (value, filter) => {
    const at = `${value.status.toLowerCase()}At`;
    const statusAt = value[at];
    const statusAtMillis = statusAt.getTime();
    if (!filter[0] && !filter[1]) {
      return false;
    }
    filter[0].setHours(0);
    filter[0].setMinutes(0);
    filter[0].setSeconds(0);
    const fromMillis = filter[0].getTime();
    if (!filter[1]) {
      return statusAtMillis >= fromMillis;
    }
    filter[1].setHours(23);
    filter[1].setMinutes(59);
    filter[1].setSeconds(59);
    const toMillis = filter[1].getTime();
    return statusAtMillis >= fromMillis && statusAtMillis <= toMillis;
  }

  const statusAtSortFunction = (event) => {
    const { order } = event;
    return messagesDetail.sort((message1, message2) => {
      message1 = message1.message;
      message2 = message2.message;
      const message1At = `${message1.status.toLowerCase()}At`;
      const message2At = `${message2.status.toLowerCase()}At`;
      return (message1[message1At] - message2[message2At]) * order;
    });
  }

  const statusMultiSelectItemTemplate = (option) => (
    <>
      <span className={`chart-legend-icon icon-${option.value.toLowerCase()}`} /> {option.label}
    </>
  )

  const elements = {
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='message.phone' placeholder='Buscar por teléfono' filterMatch='contains' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        field='message.status'
        placeholder='Todos'
        options={messagesStatusOptions}
        itemTemplate={statusMultiSelectItemTemplate}
      />
    ),
    statusAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='message' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    phoneColumnBody: (data) => (
      <>{data.message.phone}</>
    ),
    statusColumnBody: (data) => (
      <><span className={`chart-legend-icon icon-${data.message.status.toLowerCase()}`} /> <span>{messagesUtil.getMessageStatus(data.message.status)}</span></>
    ),
    statusAtColumnBody: (data) => {
      const { message } = data;
      const at = `${message.status.toLowerCase()}At`;
      return (
        <>{dateTimeUtil.getDateString(message[at], { dateStyle: 'medium', timeStyle: 'short' })}</>
      );
    }
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner />
        </NeoCard>
      }
      {
        (!asyncState.isLoading && !asyncState.isSuccessfully) &&
        <NeoCard>
          <DataFetchError internal align='start' onRetry={reboot} />
        </NeoCard>
      }
      {
        (!asyncState.isLoading && asyncState.isSuccessfully) &&
        <NeoGridContainer>
          <NeoColumn col="12" md='6'>
            <NeoTitleMain title='Estatus de envío a contactos' />
            <NeoCard>
              <NeoGridContainer>
                {
                  (messagesTotal > 0) &&
                  <NeoPieChartCustom
                    data={messagesStatus}
                    colors={colors}
                    dataKey='messages'
                    dataName='name'
                    dataStatus='messagestatus'
                    colorsCharByKey='byCode'
                    colorsLabelByKey='byName'
                    formatNumber={true}
                    contractNumber={true}
                    noChartTitle={true}
                  />
                }
                <NeoColumn col='12'>
                  <NeoDataMain
                    label='Total de mensajes'
                    fact={`${messagesTotal}`}
                    extra="p-text-center"
                  />
                </NeoColumn>
              </NeoGridContainer>
            </NeoCard>
          </NeoColumn>
          <NeoColumn extra='campaign-tabla-status-envio' col="12" md='6'>
            <NeoTable
              ref={tableRef}
              value={messagesDetail}
              paginator
              rows={10}
              removableSort
              sortField='message'
              sortOrder={-1}
              emptyMessage='No hay datos'
            >
              <NeoTableColumn
                header='Teléfono'
                field='message.phone'
                filter
                sortable
                filterElement={elements.phoneFilterInput}
                filterMatchMode='contains'
                body={elements.phoneColumnBody}
              />
              <NeoTableColumn
                field='message.status'
                header='Último estatus'
                filter
                sortable
                sortFunction={statusSortFunction}
                filterElement={elements.statusFilterMultiSelect}
                body={elements.statusColumnBody}
              />
              <NeoTableColumn
                field='message'
                header='Fecha de estatus'
                filter
                sortable
                sortFunction={statusAtSortFunction}
                filterElement={elements.statusAtFilterRange}
                filterFunction={statusAtFilterFunction}
                filterMatchMode='custom'
                body={elements.statusAtColumnBody}
              />
            </NeoTable>
          </NeoColumn>
        </NeoGridContainer>
      }
    </>
  );
};