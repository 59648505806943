import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ListBox } from 'primereact/listbox';
import MENU from 'consts/menu.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import dateTimeUtil from 'utils/dateTime.util';

export default function CampaignsIncluded(props) {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(async () => await reboot(), [props.data]);

  const reboot = async () => {
    if (props.data) {
      setCampaigns(props.data);
    }
  }

  const handleListItemChange = (event) => {
    history.push(`${MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.BASE}/${event.value.id}`);
  };

  const elements = {
    campaignItem: (options) => (
      <NeoFlexContainer extra="p-0">
        <NeoFlexContainer col='7' extra='p-flex-column p-0'>
          <span>{dateTimeUtil.getDateString(options.startedAt, { dateStyle: 'medium' })}</span>
          <b className='text-overflow'>{options.name}</b>
        </NeoFlexContainer>
        <NeoFlexContainer col='4' jc='end' ai='center' extra="p-py-0">
          <span>{options.sentTemplates} enviados</span>
        </NeoFlexContainer>
        <NeoFlexContainer col='1' jc='center' ai='center' extra="p-0">
          <i className='pi pi-chevron-right' />
        </NeoFlexContainer>
      </NeoFlexContainer>
    )
  };

  return (
    <NeoCard extra='p-mb-0'>
      <NeoInnerTitle extra='primero'>Campañas incluidas</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner />
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>
              {
                (campaigns?.length > 0) &&
                <ListBox
                  className='lista-campaigns'
                  options={campaigns}
                  itemTemplate={elements.campaignItem}
                  onChange={handleListItemChange}
                />
              }
              {
                (campaigns?.length == 0) &&
                <NeoFlexContainer>No existen campañas</NeoFlexContainer>
              }
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  );
}