import {useState} from 'react';
import CampaignsList from 'views/campaigns/components/CampaignsList.component';
import CampaignsListHeader from 'views/campaigns/components/CampaignsListHeader.component';

export default function Campaigns() {
  const [filters, setFilters] = useState(null);

  return (
    <>
      <CampaignsListHeader onChange={(event) => setFilters(event)}/>
      {
        (filters && filters.line) &&
        <CampaignsList filters={filters}/>
      }
    </>
  )
}