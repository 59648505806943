import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import LinePhotoExplorer from 'views/lines/components/LinePhotoExplorer.component';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import InfoTooltip from 'components/InfoTooltip.component';

export default function LinePhoto(props) {
  const handleExplorerChange = (event) => {
    props.onChange(event);
  }

  const Elements = {
    Image: ({ src }) => (
      <img className='profile-photo-img' src={src} style={{ width: '100px', height: '100px' }} />
    )
  };

  return (
    <>
      <NeoFlexContainer ai='center' jc='center'>
        <div>
          <div className="profile-photo" >
            <Elements.Image src={props.url} />
            <LinePhotoExplorer onChange={handleExplorerChange} />
          </div>
        </div>
        <InfoTooltip id='linePhoto' pl='1em' body='La foto de perfil debe ser cuadrada, con un tamaño máximo de 5 MB, un borde mínimo de 192px y máximo de 640px.' />
      </NeoFlexContainer>
    </>
  );
}