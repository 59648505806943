import React from "react";


const NeoCellDataStatus = (props) => {
    return (
        <span className={`cell-status 
        ${props.status ? `cell-status-${props.status}` : ""}
        ${props.extra ? props.extra : ""} `}>
            {
                props.icon
                    ? <i className={`pi pi-${props.icon}`} />
                    : null
            }

            {
                props.label
                    ? <span>{props.label}</span>
                    : null
            }
        </span>
    );
};

export default NeoCellDataStatus;