import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import RenameApiKeyDialog from 'views/apiSettings/components/RenameApiKeyDialog.component';
import ApiKeysService from 'services/apiKeys.service';
import Icono from 'design/assets/img/cta/icons/icon-apis.svg';

export default function ApiKeyDetailHeader(props) {
  const history = useHistory();
  const messages = useMessages();
  const [apiKey, setApiKey] = useState(props.apiKey);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showRenameApiKeyDialog, setShowRenameApiKeyDialog] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);

  const updateApiKey = async () => {
    const response = await ApiKeysService.updateApiKey(apiKey.id, { enabled: !apiKey.enabled });
    if (response.success) {
      const updatedApiKey = { ...apiKey, enabled: !apiKey.enabled };
      return { success: true, updatedApiKey };
    }
    return { success: false };
  }

  const deleteApiKey = async () => {
    const response = await ApiKeysService.deleteApiKey(apiKey.id);
    return { success: response.success };
  }

  const handleChangeStatusDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        setApiKey(payload.updatedApiKey);
        props.onChange(payload.updatedApiKey);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se actualizó el estatus de la API Key.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo actualizar el estatus de la API Key, inténtalo de nuevo.');
      }
    }
  }

  const handleDeleteDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        history.push(MENU.ADMIN.API.ROOT.PATH);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se eliminó la API Key.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo eliminar la API Key, inténtalo de nuevo.');
      }
    }
  }

  const handleRenameApiKeyDialogHide = (event) => {
    if (event.success) {
      const name = event.payload;
      setApiKey({ ...apiKey, name });
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se renombró la API Key.');
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo actualizar el nombre de la API Key, inténtalo de nuevo.');
    }
  }

  const elements = {
    apiKeyDisableHeader: 'Desactivar API Key',
    apiKeyEnableHeader: 'Activar API Key',
    apiKeyDeleteHeader: 'Eliminar API Key',
    apiKeyDisableMessage: 'Se desactivará la API Key. ¿Deseas proceder?',
    apiKeyEnableMessage: 'Se activará la API Key. ¿Deseas proceder?',
    apiKeyDeleteMessage: 'Se eliminará la API Key. ¿Deseas proceder?'
  };

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond title={apiKey.name} subtitle='Detalle de API Key' icon={Icono} />
        <NeoButtonSection align='right' >
          <NeoButtonMain
            label='Renombrar'
            icon="pi pi-pencil"
            onClick={() => setShowRenameApiKeyDialog(true)}
          />
          <NeoButtonMain
            icon="pi pi-power-off"
            label={apiKey.enabled ? 'Desactivar' : 'Activar'}
            onClick={() => setShowChangeStatusDialog(true)}
          />
          {
            (!apiKey.enabled) &&
            <NeoButtonMain
              icon="pi pi-trash"
              label='Eliminar'
              onClick={() => setShowDeleteDialog(true)}
            />
          }
        </NeoButtonSection>
      </NeoGridContainer>
      {
        <RenameApiKeyDialog
          apiKey={apiKey}
          visible={showRenameApiKeyDialog}
          visibleSetter={setShowRenameApiKeyDialog}
          onHide={handleRenameApiKeyDialogHide}
        />
      }
      {
        <ActionDialog
          header={apiKey.enabled ? elements.apiKeyDisableHeader : elements.apiKeyEnableHeader}
          visible={showChangeStatusDialog}
          visibleSetter={setShowChangeStatusDialog}
          acceptMessage='Actualizando estatus de API Key'
          onAccept={updateApiKey}
          onHide={handleChangeStatusDialogHide}
        >
          <>{apiKey.enabled ? elements.apiKeyDisableMessage : elements.apiKeyEnableMessage}</>
        </ActionDialog>
      }
      {
        <ActionDialog
          header={elements.apiKeyDeleteHeader}
          visible={showDeleteDialog}
          visibleSetter={setShowDeleteDialog}
          acceptMessage='Eliminando API Key'
          onAccept={deleteApiKey}
          onHide={handleDeleteDialogHide}
        >
          <>{elements.apiKeyDeleteMessage}</>
        </ActionDialog>
      }
    </>
  );
}