import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import Icono from 'design/assets/img/cta/icons/icon-contacts.svg';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import BasicContactDataForm from 'views/contacts/components/BasicContactDataForm.component';
import ExtraContactDataForm from 'views/contacts/components/ExtraContactDataForm.component';
import ContactExtraTextFieldsForm from 'views/contacts/components/ContactExtraTextFieldsForm.component';
import ContactExtraNumberFieldsForm from 'views/contacts/components/ContactExtraNumberFieldsForm.component';
import ContactExtraDateFieldsForm from 'views/contacts/components/ContactExtraDateFieldsForm.component';
import ContactExtraTokenFieldsForm from 'views/contacts/components/ContactExtraTokenFieldsForm.component';
import ContactExtraFileNameFieldsForm from 'views/contacts/components/ContactExtraFileNameFieldsForm.component';
import LoadingDialog from 'components/LoadingDialog.component';
import ContactsService from 'services/contacts.service';

export default function CreateContactView() {
  const history = useHistory();
  const messages = useMessages();
  const [basicData, setBasicData] = useState(null);
  const [extraData, setExtraData] = useState(null);
  const [extraTextFields, setExtraTextFields] = useState(null);
  const [extraNumberFields, setExtraNumberFields] = useState(null);
  const [extraDateFields, setExtraDateFields] = useState(null);
  const [extraFileNameFields, setExtraFileNameFields] = useState(null);
  const [extraTokenFields, setExtraTokenFields] = useState(null);
  const [phoneErrorCode, setPhoneErrorCode] = useState(null);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  const handleRegisterButtonClick = async () => {
    setPhoneErrorCode(null);
    setShowRegisterDialog(true);
    const {phone, firstName, lastName} = basicData.payload;
    const name = firstName + (lastName.length > 0 ? ` ${lastName}` : '');
    const extraFields = {
      firstName, lastName, 
      ...extraData.payload, 
      ...extraTextFields.payload, 
      ...extraNumberFields.payload, 
      ...extraDateFields.payload,
      ...extraTokenFields.payload,
      ...extraFileNameFields.payload
    };
    const response = await ContactsService.createContact(phone, name, extraFields);
    setShowRegisterDialog(false);
    if(response.success) {
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se registró el contacto.'
      );
      history.replace(MENU.ADMIN.CONTACTS.ROOT.PATH);
    }
    else if(response.error.code === 'INVALID_PHONE_NUMBER') {
      setPhoneErrorCode('INVALID_PHONE_NUMBER');
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo registrar el contacto. El número de teléfono es inválido.'
      );
    }
    else if(response.error.code === 'ALREADY_EXIST') {
      setPhoneErrorCode('ALREADY_EXIST');
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo registrar el contacto. El número de teléfono ya se encuentra asociado a un contacto.'
      );
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo registrar el contacto, inténtalo de nuevo.'
      );
    }
  }

  return (
    <>
      <NeoTitleSecond 
        icon={Icono} 
        title='Registro de contacto'
      />
      <BasicContactDataForm 
        phoneErrorCode={phoneErrorCode}
        onChange={(event) => setBasicData(event)}
      />
      <ExtraContactDataForm
        onChange={(event) => setExtraData(event)}
      />
      <ContactExtraTextFieldsForm
        onChange={(event) => setExtraTextFields(event)}
      />
      <ContactExtraNumberFieldsForm
        onChange={(event) => setExtraNumberFields(event)}
      />
      <ContactExtraDateFieldsForm
        onChange={(event) => setExtraDateFields(event)}
      />
      <ContactExtraTokenFieldsForm
        onChange={(event) => setExtraTokenFields(event)}
      />
      <ContactExtraFileNameFieldsForm
        onChange={(event) => setExtraFileNameFields(event)}
      />
      <NeoButtonSection align='right'>
        <NeoButtonOutlined
          label='Cancelar'
          onClick={() => history.replace(MENU.ADMIN.CONTACTS.ROOT.PATH)}
        />
        <NeoButtonMain 
          label='Registrar contacto'
          disabled={!basicData || !extraNumberFields || basicData.valid === false || extraNumberFields.valid === false}
          onClick={handleRegisterButtonClick}
        />
      </NeoButtonSection>
      {
        <LoadingDialog
          visible={showRegisterDialog}
          message='Registrando contacto'
        />
      }
    </>
  );
}