import {createContext, useState, useEffect} from 'react';
import {auth} from 'config/firebase.config';
import AuthenticationService from 'services/authentication.service';

const AuthenticationContext = createContext();
export default AuthenticationContext;

export function AuthenticationContextProvider(props) {
  const [user, setUser] = useState();
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if(user !== undefined && user !== null) {
        let success = false;
        while(!success) {
          const response = await AuthenticationService.getUserRole();
          if(response.success) {
            success = true;
            setUserRole(response.payload);
          }
        }
      }
      else {
        setUserRole(null);
      }
      setUser(user);
    });
  }, []);

  return (
    <AuthenticationContext.Provider value={{user, userRole}}>
      {props.children}
    </AuthenticationContext.Provider>
  );
}