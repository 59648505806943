import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import useQueryParams from 'hooks/useQueryParams.hook';
import MENU from 'consts/menu.consts';
import DataFetchError from 'components/DataFetchError.component';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import Icono from 'design/assets/img/cta/icons/icon-template.svg';
import LinesService from 'services/lines.service';

export default function TemplatesListHeader(props) {
  const history = useHistory()
  const asyncState = useAsyncState();
  const [queryParams, setQueryParams] = useQueryParams();
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();

  useEffect(async () => await initialize(), [queryParams]);

  const initialize = async () => {
    if (!asyncState.isInitialized && queryParams) {
      const allPromises = await asyncState.allPromises(
        [getLines()],
        { initialization: true }
      );
      if (allPromises.fulfilled && allPromises.successfully) {
        const lines = allPromises.results[0].payload;
        const selectedLine = getSelectedLine(lines);
        handleFilterChange(selectedLine);
      }
    }
  }

  const handleFilterChange = (selectedLine) => {
    setSelectedLine(selectedLine);
    const selectedLineQuery = selectedLine.id;
    setQueryParams({ line: selectedLineQuery });
    props.onChange({ line: selectedLine });
  }

  const getSelectedLine = (lines) => {
    const pattern = /^[0-9]+$/;
    if (queryParams?.line && pattern.test(queryParams.line)) {
      const lineId = queryParams.line;
      const existingLinesId = lines.map((line) => line.id);
      const index = existingLinesId.indexOf(lineId);
      if (index >= 0) {
        return lines[index];
      }
    }
    return lines[0];
  }

  const getLines = async () => {
    const response = await LinesService.getLines();
    if (response.success) {
      const lines = response.payload.map((line) => {
        return { id: line.id, name: line.name, phone: line.phone };
      });
      setLines(lines);
      return { success: true, payload: lines };
    }
    return { success: false };
  }

  const handleLineDropdownChange = async (event) => {
    const line = event.value;
    handleFilterChange(line);
  }

  const handleCreateButtonClick = () => {
    history.push({
      pathname: MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH,
      state: { lineId: selectedLine.id }
    });
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <NeoGridContainer>
              <NeoTitleMain title='Plantillas' icon={Icono} />
              <NeoButtonSection align='right' col='12' md='6'>
                <NeoDropdown
                  col='6'
                  label='Línea'
                  value={selectedLine}
                  options={lines.map((line) => ({ value: line, label: `${line.name} | ${line.phone}` }))}
                  onChange={handleLineDropdownChange}
                />
                <NeoButtonMain
                  icon="pi pi-plus"
                  label='Crear nueva plantilla'
                  onClick={handleCreateButtonClick}
                />
              </NeoButtonSection>
            </NeoGridContainer>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize} />
          }
        </>
      }
    </>
  );
};