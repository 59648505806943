import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoDateRangeFilterBase from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import dateTimeUtil from 'utils/dateTime.util';
import NeoCellDataStatus from 'design/design_components/neo/data/NeoCellDataStatus.base';

const statusOptions = [
  { label: 'Activa', value: true },
  { label: 'Inactiva', value: false }
];

export default function ApiKeysList(props) {
  const history = useHistory();
  const tableRef = useRef();

  const handleTableRowSelect = (event) => {
    history.push(`${MENU.ADMIN.API.API_KEY_DETAIL.BASE}/${event.data.id}`);
  };

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre' filterMatch='contains' />
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilterBase ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter ref={tableRef} options={statusOptions} field='enabled' placeholder='Todos' />
    ),
    createdAtColumnBody: (data) => (
      <>{dateTimeUtil.getDateString(data.createdAt, { dateStyle: 'medium', timeStyle: 'short' })}</>
    ),
    statusColumnBody: (data) => (
      <>

        {data.enabled ? <NeoCellDataStatus status="on" label="Activa" /> : <NeoCellDataStatus status="off" label="Inactiva" />}</>
    )
  };

  return (
    <>
      <NeoTable
        ref={tableRef}
        selectionMode='single'
        value={props.apiKeys}
        paginator
        rows={10}
        removableSort
        sortField='createdAt'
        sortOrder={-1}
        onRowSelect={handleTableRowSelect}
        emptyMessage='No hay API Keys'
      >
        <NeoTableColumn
          field='name'
          header='Nombre'
          filter
          sortable
          filterElement={elements.nameFilterInput}
          filterMatchMode='contains'
        />
        <NeoTableColumn
          field='createdAt'
          header='Fecha de creación'
          filter
          sortable
          filterElement={elements.createdAtFilterRange}
          filterFunction={dateTimeUtil.filterDate}
          filterMatchMode='custom'
          body={elements.createdAtColumnBody}
        />
        <NeoTableColumn
          field='enabled'
          header='Estatus'
          filter
          sortable
          filterElement={elements.statusFilterMultiSelect}
          filterMatchMode='contains'
          body={elements.statusColumnBody}
        />
      </NeoTable>
    </>
  );
}