import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import TemplatePreview from 'components/TemplatePreview.component';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';

export default function AutoReplySettingsBody(props) {
  const [autoReply, setAutoReply] = useState(props.autoReply);
  const [autoReplyError, setAutoReplyError] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => reboot(), [props.autoReply]);
  useEffect(() => handleChange(), [autoReply, isFocus]);

  const reboot = () => {
    if (props.autoReply !== autoReply) {
      setAutoReply(props.autoReply);
      setIsFocus(false);
    }
  }

  const handleChange = () => {
    const event = { valid: autoReply?.length > 0, autoReply };
    setAutoReplyError(isFocus && autoReply?.length == 0 ? 'El mensaje no puede ser vacío.' : '')
    props.onChange(event);
  }

  const handleAutoReplyChange = (event) => {
    const value = event.value ?? event.target.value;
    setAutoReply(value);
  }

  return (
    <>
      {
        (autoReply === null) &&
        <NeoCard>
          <p>
            La respuesta automática está desactivada, por lo tanto tus contactos no recibirán una respuesta a los mensajes que envíen.
            Se recomienda activar la respuesta automática ya que en esta versión no es posible responder de manera manual a los mensajes entrantes.
          </p>
        </NeoCard>
      }
      {
        (autoReply !== null) &&
        <>
          <NeoGridContainer>
            <NeoColumn>
              <NeoCard>
                <NeoInnerTitle extra='primero'>Contenido del mensaje</NeoInnerTitle>
                <NeoInputTextarea
                  rightIcon={<InfoTooltip id='message' body='La respuesta automática se enviará solo una vez cada 24 horas cuando el contacto envíe un mensaje.' />}
                  maxLength={1024}
                  value={autoReply}
                  error={autoReplyError}
                  onChange={handleAutoReplyChange}
                  onFocus={() => setIsFocus(true)}
                />
                <NeoFlexContainer jc="end">
                  <NeoDataThird extra="p-mt-0 text-align-right" fact={autoReply.length + "/1024"} label="Caracteres" />
                </NeoFlexContainer>
              </NeoCard>
            </NeoColumn>
            <NeoColumn>
              <NeoCard>
                <TemplatePreview
                  title='Previsualización del mensaje'
                  template={{ structure: { body: autoReply } }}
                />
              </NeoCard>
            </NeoColumn>
          </NeoGridContainer>
        </>
      }
    </>
  );
}