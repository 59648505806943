import {useHistory} from 'react-router-dom';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';

export default function ResourceNotFound(props) {
  const history = useHistory();

  const handleRedirectButtonClick = () => {
    history.push(props.redirection.path);
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <NeoTitleMain title={props.title ?? 'Recurso no econtrado'}/>
      {
        (props.redirection?.label && props.redirection?.path) &&
        <NeoButtonOutlined 
          label={props.redirection.label} 
          onClick={handleRedirectButtonClick}
        />
      }
    </div>
  );
}