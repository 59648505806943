import React from "react";

const BaseGridContainer = (props) => {
  return (<div className={`
    ${props.custom ? props.custom : ""}
    ${props.extra ? props.extra : ""} 
    ${props.col ? `p-col-${props.col}`: ""} 
    ${props.md ? `p-md-${props.md}`: ""} 
    ${props.sm ? `p-sm-${props.sm}` : ""} 
    ${props.lg ? `p-lg-${props.lg}` : ""}`}>

    <div className={`
    ${props.subCustom ? props.subCustom: ""} 
    ${props.subExtra ? props.subExtra : ""}`}>

      {props.children}

    </div>

  </div>);
};

export default BaseGridContainer;