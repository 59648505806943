import { useState, useEffect } from 'react';
import TemplatePreview from 'components/TemplatePreview.component';
import TemplateStructureVariablesForm from 'views/templates/components/TemplateStructureVariablesForm.component'
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';

export default function TemplatePreviewWithVariablesForm(props) {
  const [template, setTemplate] = useState(props.template);
  const [realTimeStructure, setRealTimeStructure] = useState(props.template.structure);

  useEffect(() => reboot(), [props.template]);

  const reboot = () => {
    setTemplate(props.template);
    setRealTimeStructure(props.template.structure);
  }

  const handleVariablesFormChange = (event) => {
    const updatedRealTimeStructure = { ...realTimeStructure, header: { ...realTimeStructure.header } };
    if (event.header) {
      const headerParam = event.header;
      const headerStructure = { ...template.structure.header };
      if (headerStructure.type == 'TEXT') {
        updatedRealTimeStructure.header.text = headerStructure.text;
        if (headerParam.value.length > 0) {
          updatedRealTimeStructure.header.text = updatedRealTimeStructure.header.text.replace(headerParam.name, headerParam.value);
        }
      }
      else {
        const headerType = headerStructure.type.toLowerCase();
        const headerTypeParams = updatedRealTimeStructure.header[headerType] ?? {};
        headerTypeParams.url = headerParam.value.length > 0 ? headerParam.value : undefined;
        updatedRealTimeStructure.header[headerType] = headerTypeParams;
      }
    }
    if (event.body) {
      updatedRealTimeStructure.body = template.structure.body;
      for (const bodyParam of event.body) {
        if (bodyParam.value.length > 0) {
          updatedRealTimeStructure.body = updatedRealTimeStructure.body.replace(bodyParam.name, bodyParam.value);
        }
      }
    }
    setRealTimeStructure(updatedRealTimeStructure);
  }

  return (
    <>
      <NeoColumn md="6" col="12">
        <TemplateStructureVariablesForm
          structure={template.structure}
          onChange={handleVariablesFormChange}
        />
      </NeoColumn>
      <NeoColumn md="6" col="12">
        <NeoCard>
          <TemplatePreview
            template={{ status: template.status, structure: realTimeStructure }}
          />
        </NeoCard>
      </NeoColumn>
    </>
  );
}