import { useRef, useState, useEffect } from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import CsvFileExplorer from 'views/campaigns/components/CsvFileExplorer.component';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import VariablesMappingDialog from 'views/campaigns/components/VariablesMappingDialog.component';

export default function CampaignFilesTable(props) {
  const tableRef = useRef();
  const [state, setState] = useStateParams();
  const [files, setFiles] = useState([]);
  const [lastAddedFile, setLastAddedFile] = useState();
  const [showMappingDialog, setShowMappingDialog] = useState(false);

  useEffect(() => initialize(), []);

  const initialize = () => {
    handleFilesChange(state.files ?? []);
  }

  const handleFilesChange = (updatedFiles) => {
    setState({ ...state, files: updatedFiles });
    setFiles(updatedFiles);
    props.onChange(updatedFiles);
  }

  const handleCsvFileExplorerChange = (event) => {
    setLastAddedFile(event);
    setShowMappingDialog(true);
  }

  const getRecordsCount = (numberMapping) => {
    const uniqueRowsStrings = {};
    const examples = [];
    const records = { total: 0, empty: 0, full: 0, duplicate: 0 };
    const requiredColumns = [...new Set(Object.values(numberMapping))];
    lastAddedFile.worksheet.rows.forEach((row) => {
      const rowColums = Object.keys(row).filter((rowColum) => row[rowColum]?.length > 0);
      const containsAllColumns = requiredColumns.every((requiredColumn) => rowColums.includes(requiredColumn));
      if (containsAllColumns) {
        const rowString = JSON.stringify(row);
        if (uniqueRowsStrings[rowString]) { records.duplicate += 1; }
        else {
          if (examples.length < 20) { examples.push(row); }
          uniqueRowsStrings[rowString] = true;
        }
        records.full += 1;
      }
      else { records.empty += 1; }
      records.total += 1;
    });
    return [records, examples];
  }

  const getColumnNameMapping = (numberMapping, numberExamples) => {
    const mappingEntries = Object.entries(numberMapping).map(([param, column]) => [param, lastAddedFile.worksheet.headers[column]]);
    const columnNameExampleRows = numberExamples.map((row) => {
      const rowEntries = Object.entries(numberMapping).map(([param, column]) => [param, row[column]]);
      return Object.fromEntries(rowEntries);
    });
    const columnNameMapping = Object.fromEntries(mappingEntries);
    return [columnNameMapping, columnNameExampleRows];
  }

  const handleMappingAccept = (event) => {
    const [records, examples] = getRecordsCount(event);
    const [columnNameMapping, columnNameExamples] = getColumnNameMapping(event, examples);
    const file = { file: lastAddedFile.file, records, mapping: columnNameMapping, examples: columnNameExamples };
    const updatedFiles = [...files, file];
    handleFilesChange(updatedFiles);
  }

  const handleDeleteButtonClick = (event) => {
    const updatedFiles = [...files];
    updatedFiles.splice(event.rowIndex, 1);
    handleFilesChange(updatedFiles);
  }
  const footer = <><CsvFileExplorer onChange={handleCsvFileExplorerChange} /></>

  return (
    <>
      <NeoTable
        ref={tableRef}
        value={files}
        removableSort
        footer={footer}
        extra='no-filters with-footer p-mb-3'
        emptyMessage='Aún no has cargado ningún archivo con los contactos que recibirán esta campaña'
      >
        <NeoTableColumn
          header='Nombre de archivo'
          field='file.name'
        />
        <NeoTableColumn
          header='Registros totales'
          field='records.total'
        />
        <NeoTableColumn
          header='Registros vacíos'
          field='records.empty'
        />
        <NeoTableColumn
          header='Registros duplicados'
          field='records.duplicate'
        />
        <NeoTableColumn
          header='Registros completos'
          field='records.full'
        />
        <NeoTableColumn
          header=''
          body={(rowData, event) => (
            <NeoButtonOutlined
              label='Eliminar'
              onClick={() => handleDeleteButtonClick(event)}
            />
          )}
        />
      </NeoTable>
      {
        <VariablesMappingDialog
          type='files'
          visible={showMappingDialog}
          visibleSetter={setShowMappingDialog}
          templateStructure={props.template.structure}
          options={Object.entries(lastAddedFile?.worksheet.headers ?? []).map(([value, label]) => ({ label, value }))}
          onAccept={handleMappingAccept}
          onCancel={() => { }}
        />
      }
    </>
  );
}