import TEMPLATES from 'consts/templates.consts';

function getTemplateHeaderType(type, options = {}) {
  const templateHeaderType = TEMPLATES.HEADER.TYPES[type] ?? 'Desconocido';
  return options.lowerCase === true ? templateHeaderType.toLowerCase() : templateHeaderType;
}

function getTemplateStatus(status) {
  return TEMPLATES.STATUSES[status] ?? 'Desconocido';
}

function getTemplateCategory(category) {
  return TEMPLATES.CATEGORIES[category] ?? 'Desconocida';
}

function getTemplateRequiredParams(structure) {
  const requiredParams = {};
  const headerType = structure?.header?.type;
  if(headerType == 'TEXT') {
    const [headerTextParam] = structure.header.text.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
    if(headerTextParam) {
      requiredParams.header = {type: headerType, param: headerTextParam};
    }
  }
  else if(headerType) {
    requiredParams.header = {type: headerType, param: 'url'};
  }
  requiredParams.body = structure?.body?.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
  if(structure.buttons) {
    const buttonsParams = [];
    structure.buttons
      .filter((button) => button.type == 'URL')
      .forEach((button, index) => {
        const [buttonUrlParam] = button.url.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
        if(buttonUrlParam) {
          buttonsParams.push({index, type: button.type, param: buttonUrlParam});
        }
      });
    if(buttonsParams.length > 0) {
      requiredParams.buttons = buttonsParams;
    }
  }
  return requiredParams;
}

export default {
  getTemplateHeaderType,
  getTemplateStatus,
  getTemplateCategory,
  getTemplateRequiredParams
};