import { useState, useEffect } from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import stringUtil from 'utils/string.util';

export default function TemplateBodyStructureForm(props) {
  const [state] = useStateParams();
  const [bodyText, setBodyText] = useState('');
  const [bodyTextError, setBodyTextError] = useState('');
  const [variableCount, setVariableCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => initialize(), []);
  useEffect(() => handleChangeBody(), [bodyText]);

  const initialize = () => {
    setBodyText(state.body ?? '');
  }

  const handleChangeBody = () => {
    const event = { valid: true, body: bodyText };
    const params = bodyText.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
    const textWithoutParams = bodyText.replace(/\{\{[_0-9a-zA-Z]+\}\}/g, '');
    const textLength = textWithoutParams.length + params.length * 40;
    const uniqueParams = [...new Set(params)];
    event.valid = (textLength > 0 && textLength <= 1024 && params.length == uniqueParams.length);
    setVariableCount(params.length);
    setCharacterCount(textLength);
    setBodyTextError(
      params.length != uniqueParams.length ? 'El texto del cuerpo no puede contener variables repetidas.'
        : textLength > 1024 ? 'El texto del cuerpo solo puede contener 1024 caracteres.'
          : ''
    );
    props.onChange(event);
  }

  const handleBodyTextInputChange = (event) => {
    let bodyText = event.value ?? event.target.value;
    bodyText = bodyText.trimStart().replace(/[\n]{3,}/g, '\n\n');
    setBodyText(bodyText);
  }

  return (
    <NeoCard>
      <NeoInnerSubtitle>Cuerpo</NeoInnerSubtitle>
      <NeoInputTextarea
        label='Texto del cuerpo'
        rightIcon={<InfoTooltip id='body' body='El cuerpo puede contener variables ilimitadas.' />}
        maxlength={1024}
        value={bodyText}
        error={bodyTextError}
        onChange={handleBodyTextInputChange}
      />
      <NeoFlexContainer jc="end">
        <div className="p-pt-0 p-mr-3">
          <NeoDataThird extra="p-mt-0 text-align-right" fact={stringUtil.emojisCount(bodyText).toString() + "/∞"} label="Emojis" />
        </div>
        <div className="p-pt-0 p-mr-3">
          <NeoDataThird extra="p-mt-0 text-align-right" fact={variableCount + "/∞"} label="Variables" />
        </div>
        <div className="p-pt-0" col="12">
          <NeoDataThird extra="p-mt-0 text-align-right" fact={characterCount + "/1024"} label="Caracteres" />
        </div>
      </NeoFlexContainer>
    </NeoCard >
  );
}